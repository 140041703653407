@import '../../styles/globalStyles.scss';

.manage-users {
  background-color: $whisper !important;


  .grid-error{
      margin: 10px 0;
      color: $thunderbird;
      font-family: $eyInterstateBold;
  }

  .manage-header {
    display: flex;
    justify-content: space-between;
    padding: 0 50px;
    align-items: flex-end;
    background: $white;

    .title-wrapper {
      font-family: $eyInterstateLight;
      margin: 18px 0 0px 0;
    }
    .tab-btn {
      color: $tuna;
      font-family: $eyInterstateLight;
      font-size: $fontSize14;
      font-weight: 300;
      padding: 13px 0px 8px;
      border-bottom: 4px solid $white;
    }
    .active-tab {
      border-bottom: 4px solid $tuna;
      color: $tuna;
      font-family: $eyInterstateBold;
      font-weight: bold;
    }
  }
  .content {
    flex: 1;
    padding: 24px 48px;
    font-family: $eyInterstateLight;
    font-weight: 300;
    color: $mine_shaft;
    background: $white;

    @include search-wrapper();
    .search-wrapper .form-control {
      width: 500px;
      &:hover {
        background: $disabled_text_color;
      }
      &:focus {
        background: $white;
        border: 2px solid $motif-searchbox-outline;
      }
    }
    
    .grid-title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 30px;
      .title {
        font-size: $fontSize20;
        font-weight: 400;
        font-family: $eyInterstateRegular;
        color:$breadcrumb-color-active;
      }
    }
    .search-wrapper{
      justify-content: space-between;
      align-items: center;
      .add-delete-wrapper{
        display: flex;
        align-items: center;
        & > button{
          margin-right: 20px;
        }
      }
      .rbt-aux{
        height: 44px;
      }
    }
  }
  .footer {
    display: flex;
    background: $white;
    padding: 20px 50px;
    justify-content: flex-end;
    box-shadow: 0 -3px 12px 0 rgba(35, 35, 47, 0.08);
    z-index: 999;

    button {
      font-family: $eyInterstateBold;
      font-size: $fontSize14;
      font-weight: bold;
      min-height: 45px;
      min-width: 96px;
    }
    .update-btn {
      margin-right: 20px;
    }
  }
  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
  .async-dropdown {
    background: $white;
    padding: 5px 8px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
