@import '../../styles/globalStyles.scss';

.unauthorized {
	background: linear-gradient(0deg, rgba(246, 246, 250, 0.3), rgba(246, 246, 250, 0.3)), url('../../assets/images/banner_new1.jpg');
	background-repeat: no-repeat;
  	background-size: 100% auto;
	background-position-y: -175px;
	background-position: fixed;
	
	.head-section {
		padding: 165px 50px 90px;
	}
	
    .unauthorized-message {
		text-align: center;
		padding-top: 130px;
		.unauthorized-title {
			font-size: $fontSize22;
			font-weight: 700;
			color: $red;
			padding-bottom: 1rem;
		}
	}

}
