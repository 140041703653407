@import "../../styles/globalStyles.scss";

.select-wrapper {
  position: relative;

  .asterisk {
    color: $motif-error-red;
  }
  .end-tooltip {
    position: absolute;
    right: 7px;
    top: 72%;
    transform: translateY(-50%);
    &.in-valid {
      color: $thunderbird;
    }
    .app-tooltip {
      &.app-tooltip-container {
        width: 16px;
      }
    }
  }
  .input-label {
    font-family: $eyInterstateLight;
    font-size: $fontSize14;
    color: $ey-phoenix-light-gray;
  }
  .motif-select {
    width: 100%;

    .dropdown-icon{
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%),
    }

    .MuiOutlinedInput-adornedStart {
      background: $white;
      &:hover {
        background-color: $disabled_text_color;
      }

      .MuiInputAdornment-positionStart,
      .MuiInputAdornment-positionEnd {
        height: auto;
        margin: 0;
        color: $breadcrumb-color;
      }
    }

    .MuiSelect-select {
      font-family: $eyInterstateLight;
      background-color: $white;
      color: $ey-phoenix-light-gray !important;
      font-size: $fontSize15;
      padding: 10px 10px;

      &:hover,
      &:focus {
        background-color: $disabled_text_color;
        border: none;
      }
    }

    .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: $ey-phoenix-light-gray-2;
        border-width: 1px;
      }

      &:hover fieldset {
        border-color: $ey-phoenix-light-gray-2;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: $ey-phoenix-light-gray-2; // Removes the default border color
    }

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: $ey-phoenix-light-gray-2; // Apply your hover border color
    }

    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: $ey-phoenix-light-gray-2; // Apply your focus border color
      border-width: 1px; // Ensure it's consistent with other states
    }

    .Mui-focused {
      &.MuiOutlinedInput-adornedStart,
      .MuiInputAdornment-positionEnd {
        background: $disabled_text_color;
      }
    }

    .Mui-disabled {
      background-color: $disabled_text_color;
      cursor: not-allowed;

      .MuiSelect-select {
        color: $breadcrumb-color !important;
      }

      fieldset {
        border-width: 0px;
      }

      &.MuiOutlinedInput-adornedStart,
      .MuiInputAdornment-positionEnd {
        svg {
          color: $breadcrumb-color;
        }
      }
    }
    &.in-valid .MuiOutlinedInput-notchedOutline {
      border-color: $thunderbird; // Red border for invalid state
      border-width: 1px; // Increase border width if needed
    }
    &.show-warning .MuiOutlinedInput-notchedOutline {
      border-color: $motif-warning-orange-light; // Red border for invalid state
      border-width: 1px; // Increase border width if needed
    }
    .MuiInputBase-root.MuiOutlinedInput-root {
      &:hover {
        border: none !important;
      }
    }

    &.Mui-disabled { 
      .dropdown-icon { 
        cursor: not-allowed !important;
      }
    }
  }
}
