@import '../../styles/globalStyles.scss';

.app-date-picker {
  display: inline-block;
  max-width: 200px;

  #app-date-picker {
    padding: 10px;
  }

  .MuiFormControl-root {
    width: 100%;

    .MuiInputBase-input {
      padding: 10px;
      color: $ey-phoenix-light-gray !important;
      font-size: $fontSize14;
      font-family: $eyInterstateLight;
      font-weight: 100;
    }

    .MuiOutlinedInput-root.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: $ey-phoenix-light-gray-2;
        border-width: 1px; 
      }
    }
  
  }
}

.MuiPickersDay-current {
  color: $selected_color !important;
}

.MuiPickersDay-daySelected {
  background-color: $selected_color !important;
  color: $white !important
}