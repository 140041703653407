@import "../../styles/globalStyles.scss";
.bottom-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 10px 24px 20px;
    overflow: hidden;
    
    img {
      object-fit: cover;
      width: 100%;
      height: auto;
    }

    .banner-text {
      position: absolute;
      left: 0;
      padding-left: 50px;
      width: calc(100% - 50px);
      display: flex;
      justify-content: space-between;
      align-items: center;

      .primary-text {
        margin: 0;
        font-size: $fontSize30;
        font-family: $eyInterstateRegularNew;
      }

      .secondary-text {
        margin: 0;
        margin-top: 10px;
        font-size: $fontSize18;
        font-family: $eyInterstateRegularNew;
        color: $ey-phoenix-light-gray;
      }

      .sat-lauch-button-icon {
        @include primary-button-icon;
        margin-left: 5px;
      }

      @media screen and (max-width: 820px) {
        font-size: $fontSize20;

        .primary-text {
          margin: 0;
          font-size: $fontSize22;
        }

        .secondary-text {
          margin: 0;
          font-size: $fontSize16;
        }
      }
      
      @media screen and (max-width: 480px) {
        font-size: $fontSize20;

        .primary-text {
          margin: 0;
          line-height: 14px;
          font-size: $fontSize12;
        }

        .secondary-text {
          margin: 0;
          line-height: 14px;
          font-size: $fontSize10;
        }

        #sat-lauch-button {
          padding: 2px 8px;
          .MuiButton-label {
            font-size: $fontSize10;
          }
        }
      }
    }
  }