@import '../../../styles/variables.scss';

.customize-bundle-details {
  padding: 34px 12px 24px 38px;
  color: $tuna;
  font-family: $eyInterstateLight;
  margin-left: -12px;
  margin-right: -12px;
  .preview-btn {
    a{
      text-decoration: none;
    }
  }
  
  .margin-zero {
    margin: 0;
  }
  .cust-category {
    margin-bottom: 18px;
    margin-top: 24px;
  }
  .cust-details-view-title {
    margin-bottom: 24px;
    margin-top: 40px;
  }
  .float-right {
    float: right;
  }
  .cust-bundle-detail-header {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    .cust-app-card-icon {
      margin-right: 24px;
      .icon {
        height: 46px;
        width: 46px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
      }
    }
    .cust-app-card-title {
      padding: 9px 0;
      font-size: $fontSize20;
      font-weight: 600;
    }
  }
  
}
