@import '../../styles/globalStyles.scss';

.admin-projects-is-customized-modal{
  z-index: 1004 !important;

  .MuiPaper-root {
    min-width: 75%;
    max-width: 1000px;
    height: 550px;
  }

  .list {
    .app-table .ag-body-viewport:after {
      display: none;
    }

    .ag-body-viewport {
      min-height: 300px;
    }

    .ag-center-cols-viewport {
      min-height: 300px !important;
    }
  }

  .ag-body-horizontal-scroll-viewport {
    overflow-x: hidden;
  }
  .ag-react-container .select-cell-editor {
    width: 100px;
  }

  @include search-wrapper();
  .search-wrapper .dropdown-menu {
    max-height: 100px !important;
  }

  .MuiButton-text {
    color: $denim
  }

  .async-dropdown-modal {
    background: $white;
    padding: 5px 8px;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    }
}
