@import '../../../styles/variables.scss';
 .MuiInputBase-root.Mui-disabled {
    background-color: $dropdown_form_control_bg;
  }


.pill-editor-title{
      margin-block-start: 0;
}

.pill-editor{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: $white;
    border: 1px solid $grey;
    align-content: flex-start;
    align-self: unset;
    min-height: var(--pill-editor-min-height);
    height: 100%;
    width: var(--pill-editor-width);
    max-width: var(--pill-editor-max-width);

    .pill {
      background-color: $athens_gray;
      border-radius: 20px;
      padding: var(--pill-editor-pill-padding);
      height: 33px;
      font-size: $fontSize13;
      margin: 5px;
      vertical-align: middle;
      max-width: var(--pill-editor-pill-width);

      .avatar {
        background-color: $link_blue;
        color: $white;
        border-radius: 100%;
        padding: 8px;
        margin-right: 5px;
      }
      svg {
        margin-left: 10px;
        margin-bottom: -3px;
      }
    }

    .search-wrapper svg {
      display: none;
    }

    .rbt-input-main {
      border: 0;
      outline: 0;
    }


    .search-wrapper {
      width: var(--pill-editor-search-wrapper-width);
      display: flex;
      align-self: center;
      margin: 5px;

      .rbt{
        width: 100%;
      }
    }

  }