
@import '../../styles/variables.scss';
@import '../../styles/globalStyles.scss';

.bundle-card {
    width: 100%;
    min-width: 200px;
    min-height: 172px;
    background: $white;
    border: 1px solid $athens_gray_light;
    box-shadow: 0 1px 2px 0 rgba($black, 0.05);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px;
    margin: 0 20px 0 0;

    &:hover {
        opacity: 1;
    }

    .title {
        color: $charade_dark;
        font-size: $fontSize15;
        font-family: $eyInterstateLight;
        font-weight: bold;
        max-width: 150px;
        @include multiline-ellipsis(2);
    }

    .description {
        max-width: 150px;
        color: $jumbo;
        font-size: $fontSize12;
        font-family: $eyInterstateLight;
        font-weight: 300;
        @include multiline-ellipsis(2);
    }

    .bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-self: flex-start;
        width: 100%;

        .customize {
            cursor: pointer !important;

            .app-button {
                min-height: auto;
                padding: 8px 12px;
                min-width: auto;
                max-width: 100%;
            }
        }

        .radio-btn {
            border: 1px solid $frenchy_gray;
            height: 21px;
            width: 21px;
            border-radius: 50%;
            cursor: pointer !important;

            .circle {
                height: 15px;
                width: 15px;
                border-radius: 50%;
                display: inline-block;
                position: relative;
                bottom: 1px;
                left: 2px;
                background-color: $black;
            }

            .tick {
                position: absolute;
                display: inline-block;
                width: 8px;
                height: 4px;
                top: 4px;
                left: 3px;
                border-bottom: 1px solid $white;
                border-left: 1px solid $white;
                transform: rotate(-45deg);
            }
        }
    }
}

.disabled-div {
    background-color: $athens_gray1;
    opacity: 0.4;
    cursor: pointer;
}

.selected-bundle {
    border-style: solid;
    border-top-color: $turbo;
    border-top-width: 3px;
}

.no-events {
    pointer-events: none;
}

.cursor-point {
    cursor: pointer;
}
