@import "../../styles/globalStyles.scss";
.policy-text {
  b {
    font-family: EYInterstate-Bold;
  }
}
.client-engagement-policy-wrapper {
  display: flex;
  flex-direction: column;
  line-height: 2.5;
  .client-engagement-policy {
    color: $breadcrumb-color-active;
    font-size: $fontSize14;
    font-family: $eyInterstateRegularNew;
    font-weight: 400;
    .pricing-information-link {
      text-decoration: none;
      cursor: pointer;
      color: $breadcrumb-color-active;
    }
  }

  .client-engagement-policy-helper {
    color: $breadcrumb-color-active;
    font-size: $fontSize14;
    font-family: $eyInterstateLightNew;
    line-height: 1.5;
    margin-top: 2rem;
    font-weight: 300;
  }
}

.pricing-information {
  display: flex;
  flex-direction: column;

  .pricing-guidance {
    text-align: end;
    margin-bottom: 10px;
  }
  .table {
    .ag-body-horizontal-scroll {
      display: none;
    }
    .ag-body-viewport {
      min-height: unset;
      height: 100%;
      overflow: hidden;
    }
  }
}

.price-field {
  display: inline-flex;
  margin: 0 !important;
  .available {
    width: 300px;
    .MuiInputBase-root.MuiOutlinedInput-root.motif-select.MuiInputBase-formControl {
      width: 324px;
    }
  }
  .amount {
    width: 253px;
    padding-left: 10px;
    .MuiInputBase-root {
      &::before {
        border-bottom: 1px solid $jumbo !important;
      }
      &:hover,
      &:focus {
        &::before {
          border-bottom: 1px solid $jumbo !important;
        }
      }
    }
  }
}

.project-form-new {
  display: flex;
  width: 100%;
  overflow-x: auto;
  background-color: $whisper;
  color: $mine_shaft;
  font-family: $eyInterstateLight;
  min-height: 650px;
  padding: 0 10px;
  flex: 1;
  max-width: 1920px;
  margin: 0 auto;

  .details-wrapper {
    height: max-content;
    min-height: 390px;
    width: 285px;
    padding-right: 36px;
    box-shadow: 30px 0 25px -30px rgba($gray, 0.08);
    border-right: 1px solid $temo;
    position: relative;
    min-width: 280px;

    .arrow {
      width: 20px;
      height: 20px;
      background-color: $whisper;
      box-shadow: 30px 0 25px -20px rgba($gray, 0.08);
      display: inline-block;
      transform: rotate(-45deg);
      position: absolute;
      right: -10px;
      top: calc(50% - 20px);
      border-right: 1px solid $temo;
      border-bottom: 1px solid $temo;
    }
  }
  .form-wrapper {
    min-width: 100%;
    flex: 1 1 0px; // 0px deliberately written

    .pace-id-info-box {
      border: 1px solid $motif-toast-orange;
      padding: 10px;
      border-radius: 8px;
      margin: 0 0 15px 0;
      display: flex;
      min-width: 1180px;
      position: relative;
      background-color: $motif-toast-orange-light;
      color: $motif-toast-orange-medium;

      h5 {
        margin: 0 10px;
        font-size: 14px;
        margin-bottom: 5px;
      }

      .info-box-icon {
        color: $neon_carrot;
      }

      .info-description {
        padding: 0 20px 0 10px;
        font-size: $fontSize14;
        font-family: $eyInterstateLightNew;
        font-weight: 400;

        .info_desc_line_2 {
          margin-top: 5px;
          font-size: $fontSize14;
          font-family: $eyInterstateLightNew;
          font-weight: 400;
        }

        .info-box-link {
          font-weight: bold;
          cursor: pointer;
          margin-top: 5px;
          display: inline-block;
          font-size: $fontSize14;
          text-decoration: none;
          color: $secondary_button_grey;
          display: flex;
          flex-direction: row;
          align-items: center;
          p {
            margin: 0 10px 0 0;
          }
          .info-box-arrow {
            height: 16px;
          }
        }
      }

      .pace-id-info-box-close {
        position: absolute;
        right: 10px;
        color: $ey-phoenix-light-gray;

        .pace-info-close-btn {
          font-size: 1rem;
        }
      }
    }

    .paceid-warning-icon {
      width: 25px;
      cursor: pointer;
      .in-valid {
        color: $thunderbird;
      }
    }

    .enter-details {
      font-size: $fontSize18;
      margin-bottom: 20px;
    }
    .warning-container {
      color: $motif-warning-orange;
    }

    .app-select {
      .MuiFormLabel-root {
        color: $gray !important;
        font-family: $eyInterstateLight;
        font-size: $fontSize14;
      }
      .MuiSelect-root {
        padding: 21px 10px 6px 10px;
        background-color: $white;
        color: $gray !important;
        font-size: $fontSize14;
      }
      .Mui-disabled {
        background-color: lightgrey !important;
      }
      .MuiInputLabel-filled.MuiInputLabel-shrink {
        transform: translate(10px, 6px) scale(0.82);
      }
      .MuiFilledInput-underline {
        &::before {
          border-bottom: 1px solid $emerald !important;
        }
        &::after {
          border-bottom: 1px solid $emerald !important;
        }
      }
    }
    .app-select.in-valid {
      .MuiFilledInput-underline {
        &::before {
          border-bottom: 1px solid $thunderbird !important;
        }
        &::after {
          border-bottom: 1px solid $thunderbird !important;
        }
      }
    }

    .input-row {
      display: flex;

      .MuiFormHelperText-root {
        font-family: $notoSans;
        font-size: $fontSize12;
      }

      .middle {
        align-items: center;
      }

      .text-area-wrapper {
        flex: 1;
        margin-right: 8px;

        .app-text-area {
          .MuiInputBase-input {
            background-color: $white;
            padding: 0;
            color: $gray !important;
            font-size: $fontSize15;

            &:hover,
            &:focus {
              border-bottom-width: 1px;
            }
          }
          .MuiFormLabel-root,
          .MuiInputLabel-shrink,
          .Mui-focused {
            color: $gray !important;
            font-size: $fontSize14;
          }
          .MuiInputBase-root {
            background-color: $white;
            border-radius: 0;

            &:hover,
            &:focus {
              background-color: $white;

              &::before {
                border-bottom: 1px solid $jumbo;
              }
            }
          }
        }
        // TODO- hardcoding width need to be removed, for now stay with what it is, since refactor is involves whole form!

        .width-100-percent {
          width: 100%;
        }
      }
      .text-area-field {
        min-width: 100% !important;
        max-width: 100% !important;
        position: relative;
        margin-bottom: 16px;

        .mfa-tool-tip {
          z-index: 1;
          display: flex;
          width: 16px;
          color: $ey-phoenix-light-gray;
          cursor: pointer;
        }
      }
    }
    .project-form-field {
      position: relative;
      margin-bottom: 16px;
      flex: 1 1 0px; // 0px deliberately written
      // max-width: 440px;
      min-width: 31%;
      margin-right: 3%;
      .input-label {
        font-family: $eyInterstateLight;
        font-size: $fontSize14;
        color: $ey-phoenix-light-gray;
      }
      .serviceline-warning {
        color: $motif-toast-orange-medium;
      }
      .MuiFormControl-root.MuiTextField-root.motif-input {
        .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled.Mui-disabled.MuiInputBase-formControl {
          border: 1px solid $ey-phoenix-light-gray-2;
          border-radius: 4px;
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }

    .app-input {
      .MuiInputBase-input {
        background-color: $white;
        padding: 20px 12px 7px;
        color: $gray !important;
        font-size: $fontSize15;

        &:hover,
        &:focus {
          border-bottom-width: 1px;
        }
      }
      .MuiFormLabel-root,
      .MuiInputLabel-shrink,
      .Mui-focused {
        color: $gray !important;
        font-size: $fontSize14;
      }
    }
    .required,
    .in-valid {
      .MuiInputBase-root {
        &::before {
          border-bottom: 1px solid $thunderbird;
        }

        &:hover,
        &:focus {
          &::before {
            border-bottom: 1px solid $thunderbird;
          }
        }
      }
    }
    .data-location-tooltip {
      padding: 0 0 0 19px;
      z-index: 1;
      min-width: 37px;
      &.search-tooltip {
        right: 7px;
        bottom: 10px;
        display: flex;
        position: absolute;
        color: $ey-phoenix-light-gray;
      }
      .app-tooltip {
        width: 16px;
        color: $ey-phoenix-light-gray;
      }
    }
  }
  .mfa-check-box {
    .checbox-mfa-div {
      text-align: left;
      display: flex;
      align-items: center;
    }

    .mfa-tool-tip {
      z-index: 1;
      display: flex;
      width: 16px;
      color: $ey-phoenix-light-gray;
    }
  }
  &::-webkit-scrollbar {
    width: 6px;
    height: 7px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: $scarpa_flow;
    border-radius: 5px;
  }
  .margin-left-client-field {
    margin-left: -20px;
  }
  .choose-icon-wrapper {
    background: $athens_gray;
    height: 147px;
    min-width: 350px;
    max-width: 350px;
    padding: 15px;
    .project-icon-message {
      color: $icon-message-color;
      font-size: $fontSize14;
      font-family: $eyInterstateLight;
      font-weight: 300;
      line-height: 16px;
      margin-bottom: 20px;
    }
    .icons-container {
      display: flex;
      justify-content: center;
      align-items: center;
      .project-icon {
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        &.highlight {
          margin-right: 25px;
          height: 62px;
          width: 62px;
          svg {
            fill: $white;
          }
        }
      }
      .icon-list {
        display: flex;
        flex-wrap: wrap;
        .project-icon {
          width: 24px;
          height: 24px;
          margin-right: 20px;
          margin-top: 7px;
          background-color: $temo;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &.select {
            border: 1px solid $frenchy_gray;
          }
          svg {
            height: 19px !important;
            width: 16px !important;
          }
        }
      }
    }
  }
  .policy-text-wrapper {
    min-width: 100%;
    max-width: 100%;

    .policy-text {
      font-size: $fontSize14;
      font-family: $eyInterstateLightNew;
      h3 {
        margin: 0 0 14px;
      }
    }
  }

  @include search-wrapper();

  .search-wrapper {
    flex-direction: column;
    .dropdown-menu {
      background-color: $white;
      border: 1px solid $black;
      border-radius: 0;
      border-top: none;
      max-height: 203px !important;

      .dropdown-item {
        border: none;
      }
    }
    .async-dropdown {
      padding: 8px;
      color: $gray;
      font-family: $eyInterstateLight;
      font-size: $fontSize14;
      font-weight: 300;

      &:hover {
        background-color: $whisper;
      }
    }
    .render-search {
      .input-search {
        color: $gray;
        background: $white !important;
        font-family: $eyInterstateLight;
        font-size: $fontSize14;
        font-weight: 300;
        height: 40px;
        border: 1px solid $ey-phoenix-light-gray-2;
        border-radius: 5px;
        padding: 2px 5px 2px 35px;
        width: 100%;
        margin-bottom: 0px;
        &.invalid-input {
          border-color: $thunderbird;
          border-width: 1px;
        }
      }
      .rbt-aux {
        margin-right: 30px;
        z-index: 9;
      }
      .app-icon {
        opacity: 0.5;
        left: 0;
        top: 2.1rem;
        height: 25px;
        span {
          svg {
            width: 24px;
          }
        }
      }
      .disable-input {
        cursor: not-allowed;
      }
      .disable-clear {
        display: none;
      }
    }
    .disable-search {
      cursor: not-allowed;

      .input-search {
        padding-left: 12px;
      }
    }
    .project-form-field {
      width: 100%;
    }
  }
  .data-location-tooltip-note {
    padding-top: 20px;
  }
  .MuiPaper-root.MuiAccordion-root.Mui-expanded.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
    box-shadow: none;
    border: 1px solid $light-border;
  }
  .MuiPaper-root.MuiAccordion-root.MuiAccordion-rounded.MuiPaper-elevation1.MuiPaper-rounded {
    box-shadow: none;
    border: 1px solid $light-border;
  }
}
