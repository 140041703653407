@import "../../styles/globalStyles.scss";

.multi-app-select {
  .MuiList-root,
  .MuiMenu-list,
  .MuiList-padding {
    background: $white;
    padding: 5px 8px;
    width: 98%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: $fontSize14;
    position: relative;
  }
  .search-wrapper .dropdown-menu .dropdown-item {
    background: $white;
    padding: 5px 8px;
    width: 98%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: $ey-phoenix-light-gray !important;
  }
  .MuiMenu-list > li {
    height: 40px;
  }
  .MuiListItemText-root {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
  .MuiFormControlLabel-root {
    margin-right: 8px !important;
    font-family: $eyInterstateLight !important;
    font-size: $fontSize16 !important;
    font-weight: 300px !important;
    color: $header_admin_menu_active !important;
  }
  .rbt-aux {
    margin-bottom: 5px !important;
  }
  .MuiListItem-gutters {
    font-family: $eyInterstateLight !important;
    font-size: $fontSize16 !important;
    font-weight: 300px !important;
    color: $header_admin_menu_active !important;
    padding-left: 10px !important;
  }
  .MuiListItem-root.Mui-selected {
    background-color: transparent !important;
  }
  .select-wrapper .motif-select .MuiSelect-select {
    color: transparent;
  }
  .MuiCheckbox-colorSecondary.Mui-checked:hover {
    border: transparent !important;
  }
  .select-wrapper {
    position: relative;
    .shift-caret {
      .MuiSelect-icon {
        right: 25px;
      }
    }
    .asterisk {
      color: $motif-error-red;
    }
    .end-tooltip {
      position: absolute;
      right: 7px;
      top: 72%;
      transform: translateY(-50%);
      .app-tooltip {
        &.app-tooltip-container {
          width: 16px;
        }
      }
    }
    .input-label {
      font-family: $eyInterstateLight;
      font-size: $fontSize14;
      color: $ey-phoenix-light-gray;
    }
    .motif-select {
      width: 100%;

      .MuiOutlinedInput-adornedStart {
        background: $white;
        &:hover {
          background-color: $disabled_text_color;
        }

        .MuiInputAdornment-positionStart,
        .MuiInputAdornment-positionEnd {
          height: auto;
          margin: 0;
          color: $breadcrumb-color;
        }
      }

      .MuiSelect-select {
        font-family: $eyInterstateLight;
        background-color: $white;
        color: $ey-phoenix-light-gray !important;
        font-size: $fontSize15;
        padding: 10px 10px;

        &:hover,
        &:focus {
          background-color: $disabled_text_color;
          border: none;
        }
      }

      .MuiOutlinedInput-root {
        &.Mui-focused fieldset {
          border-color: $ey-phoenix-light-gray-2;
          border-width: 1px;
        }

        &:hover fieldset {
          border-color: $ey-phoenix-light-gray-2;
        }
      }

      .MuiOutlinedInput-notchedOutline {
        border-color: $ey-phoenix-light-gray-2; // Removes the default border color
      }

      &:hover .MuiOutlinedInput-notchedOutline {
        border-color: $ey-phoenix-light-gray-2; // Apply your hover border color
      }

      &.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: $ey-phoenix-light-gray-2; // Apply your focus border color
        border-width: 1px; // Ensure it's consistent with other states
      }

      .Mui-focused {
        &.MuiOutlinedInput-adornedStart,
        .MuiInputAdornment-positionEnd {
          background: $disabled_text_color;
        }
      }

      .Mui-disabled {
        background-color: $disabled_text_color;
        cursor: not-allowed;

        .MuiSelect-select {
          color: $breadcrumb-color !important;
        }

        fieldset {
          border-width: 0px;
        }

        &.MuiOutlinedInput-adornedStart,
        .MuiInputAdornment-positionEnd {
          svg {
            color: $breadcrumb-color;
          }
        }
      }
      &.in-valid .MuiOutlinedInput-notchedOutline {
        border-color: $thunderbird; // Red border for invalid state
        border-width: 1px; // Increase border width if needed
      }
      &.show-warning .MuiOutlinedInput-notchedOutline {
        border-color: $motif-warning-orange-light; // Red border for invalid state
        border-width: 1px; // Increase border width if needed
      }
      .MuiInputBase-root.MuiOutlinedInput-root {
        &:hover {
          border: none !important;
        }
      }
    }
  }
}
