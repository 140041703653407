@import '../../styles/globalStyles.scss';

.admin-projects-logs-modal {
  z-index: 1004 !important;

  .ag-theme-material {
    .ag-layout-auto-height .ag-center-cols-clipper, 
    .ag-layout-auto-height .ag-center-cols-container, 
    .ag-layout-print .ag-center-cols-clipper, 
    .ag-layout-print .ag-center-cols-container {
      min-height: auto;
    }
  }
  
  .ag-body-viewport {
    min-height: auto;
  }
  .MuiPaper-root {
    min-width: 90%;
  }
  .async-dropdown {
    display: flex;
    .async-dropdown-name {
      border-right: 1px solid $search_dropdown_border;
    }
    div {
      flex: 1;
      align-self: flex-start;
      padding: .6rem 1.5rem;
    }
  }

  .project-log-table {
    &.log-sticky-scroll {
      position: relative;
      .ag-body-horizontal-scroll {
        position: sticky;
        bottom: 0;
        z-index: 999;
        width: 100%;
      }
      .ag-center-cols-viewport {
        overflow-x: hidden;
      }
      .ag-body-horizontal-scroll-viewport {
        overflow-x: auto;
        scroll-behavior: smooth;
      }
      .ag-horizontal-right-spacer {
        display: none;
      }
    }
  }
  .async-dropdown {
		display: flex;
		.async-dropdown-name {
			border-right: 1px solid $search_dropdown_border;
		}
		div {
			flex: 1;
			align-self: flex-start;
			padding: .6rem 1.5rem;
		}
	}
  @include search-wrapper();
}
