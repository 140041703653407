@import "../../styles/variables.scss";

$TagColor: $trout;
:export {
  releaseTagColor: $notification-light-blue;
  releaseTagBackGroundColor: $notification-mid-blue;
  generalTagColor: $placeholder_color;
  generalTagBackGroundColor: $notification-light-grey;
  surveyTagColor: $parrot;
  surveyTagBackGroundColor: $notification-light-green;
  adhocNewsTagColor: $neon_carrot;
  adhocNewsTagBackGroundColor: $notification-light-orange;
}

.notification-bell {
  p {
    margin: 0px;
  }
}
