@import '../../styles/variables.scss';
@import '../../styles/globalStyles.scss';

.admin-types-modal-content {
  min-height: 220px;

  .MuiInputBase-root.Mui-disabled {
    background-color: $dropdown_form_control_bg;
  }
  .admin-types-modal-textarea {
    margin: 15px 0;
  }

  textarea {
    min-height: 100px;
  }
}