// color variables
$white: #ffffff;
$black: #000000;
$transparent_white: rgba(255, 255, 255, 0.7);
$scarpa_flow: rgb(81, 81, 95);
$spun_earl: rgb(170, 170, 177);
$frenchy_gray: rgb(196, 196, 205);
$scarpa_flow_dark: rgb(88, 88, 96);
$trout: rgb(72, 72, 85);
$tuna: rgb(46, 46, 56);
$tuna5: rgba(46, 46, 56, 0.5);
$scorpion: rgb(187, 187, 187);
$mischka: rgb(225, 225, 230);
$charade: rgb(32, 32, 43);
$grey: grey;
$emerald: rgb(52, 199, 103);
$turbo: #ffe600;
$mid_gray: rgb(93, 93, 105);
$charade_dark: rgb(46, 46, 60);
$jumbo: rgb(101, 101, 121);
$silver: rgb(187, 187, 187);
$athens_gray: rgb(237, 237, 242);
$athens_gray_light: rgb(234, 234, 242);
$athens_gray_dark: rgb(228, 228, 238);
$tuna_light: rgb(58, 58, 71);
$tuna_dark: rgb(46, 46, 56);
$gun_powder: rgb(66, 66, 83);
$thunderbird: rgb(185, 37, 28);
$secondary_button_grey: #2e2e38;
$whisper: rgb(250, 250, 252);
$mine_shaft: rgb(51, 51, 51);
$gray: rgb(128, 128, 128);
$temo: rgb(246, 246, 250);
$manate: rgb(150, 150, 155);
$athens_gray1: rgb(231, 231, 234);
$temo_blue: rgb(229, 229, 236);
$shadow-grey: rgba(232, 232, 236, 1);
$light_blue: #35a4e8;
$neon_carrot: #ff9831;
$placeholder_color: #747480;
$selected_color: #2e2e38;
$grid_drop_down_color: #c4c4cd;
$disabled_background: #eaeaf2;
$disabled_motif_background: #7f7f91;
$disabled_text_color: #f3f3f5;
$grid_table_border_color: #e2e2e2;
$grid_disabled_row_color: #e8e8e8;
$search_input_color: #efeff6;
$search_dropdown_border: #9e9e9e4d;
$unpublished_bg_color: #585860;
$denim: #155cb4;
$red: rgb(255, 0, 0);
$header_admin_menu: #23232f;
$header_admin_menu_active: #1a1a24;
$box_shadow: #ccc;
$link_blue: #155cb4;
$search_dropdown_color: #212529;
$dropdown_form_control_color: #495057;
$dropdown_form_control_border: #ced4da;
$selec-react-hover-bg-color: #e1e1e6;
$aqua: rgb(36, 166, 154);
$dropdown_form_control_bg: #f6f6fa;
$alto: rgb(216, 216, 216);
$special-green: #2c973e;
$special-red: #f04c3e;
$icon-message-color: rgb(46, 46, 56);
$error-background-color: rgba(46, 46, 56, 0.8);
$parrot: #34c768;
$bottle-green: #189d3e;
$notification-light-blue: #87d3f2;
$notification-mid-blue: #188ce5;
$notification-light-grey: #e7e7ea;
$notification-light-green: #8ce8ad;
$notification-light-orange: #ffb46a;
$ey-phoenix-gray: #f2f2f6;
$ey-off-white: #fafafc;
$ey-tooltip-grey: #bdbdc5;
$ey-border-grey: #d7d7dc;
$ey-link-blue: #0f69ae;
$ey-phoenix-light-gray: #656579;
$light-red-0001: #fcf5f3;
$light-border: #e6e6e9;
$ey-phoenix-light-gray-2: #c3c3cb;
$ey-dull-background: #f9f9fa;
$motif-primary-button-icon-color: #afaeba;
$breadcrumb-color: #9897a6;
$breadcrumb-color-active: #2e2e38;
$ey-phoenix-light-gray-3: #7f7f91;
$ey-phoenix-red-1: #c70117;
$ey-light-blue-1: #f4f7fc;
$ey-phoenix-light-gray-4: #9897a6;
$ag-grid-selected-bg: #eeeeee;

$ey-light-border: #e8e8ec;
$ey-alert-red: #a40011;
$ey-alert-circle-icon: #ff4336;
$toggle-bg-green: #26a04b;
$motif-error-red: #ea011d;
$motif-toast-orange: #ee8300;
$motif-toast-orange-light: #fcf5f0;
$motif-toast-orange-medium: #975100;
$motif-warning-orange: #d07200;
$motif-warning-orange-light: #b26100;
$ey-error-red: #ff735e;
$ey-error-icon-red: #ff4136;
$phoenix-dark-black: #3a3a4a;
$ey-green-check: #2db757;
$toast-success-bg: #E7FCE8;
$toast-success-text: #197335;
$toast-info-bg: #e4f1fc;
$ey-error-red: #FF735E;
$ey-error-icon-red: #FF4136;
$light-red-0002: #FCD7CF;
$light-red-0003: #FCD9BC;
$motif-searchbox-outline: #0F69AE;
$motif-error-red:#A40011;
// Font size variables
$fontSize8: 0.5rem;
$fontSize10: 0.625rem;
$fontSize11: 0.688rem;
$fontSize12: 0.75rem;
$fontSize13: 0.813rem;
$fontSize14: 0.875rem;
$fontSize15: 0.938rem;
$fontSize16: 1rem;
$fontSize17: 1.063rem;
$fontSize18: 1.125rem;
$fontSize19: 1.188rem;
$fontSize20: 1.25rem;
$fontSize22: 1.375rem;
$fontSize24: 1.5rem;
$fontSize25: 1.563rem;
$fontSize28: 1.75rem;
$fontSize30: 1.875rem;
$fontSize31: 1.8rem;
$fontSize32: 2rem;
$adminMenuFontSize: 1.14286rem;
$fontSize16px: 16px;

// Font-family variables
$eyInterstateRegular: "EYInterstate-Regular", "sans-serif";
$eyInterstateLight: "EYInterstate-Light", "sans-serif";
$eyInterstateBold: "EYInterstate-Bold", "sans-serif";
$notoSans: "Noto Sans", "sans-serif";
$eyInterstateLightNew: "EYInterstate-Light";
$eyInterstateRegularNew: "EYInterstate-Regular";
$eyInterstateBoldNew: "EYInterstate-Bold";
