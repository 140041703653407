@import '../../styles/globalStyles.scss';

.admin-apps {
	padding: 30px 50px;

	.apps-count {
		text-align: right;
		margin-bottom: 10px;
	}

	.apps-copy-button {
		padding: 0 15px;
	}
	.add-apps {
		display: flex;
		justify-content: space-between;
		margin-bottom: 12px;
	}

	.app-table {		
		.custom-cellrenderer {
			padding: 10px 30px;
		}
		.ag-theme-material .ag-ltr .ag-cell:not(.ag-cell-last-left-pinned) {
			overflow: hidden !important;
		}
		.ag-horizontal-left-spacer, .ag-horizontal-right-spacer {
			display: none;
		}
		.app-table-cell-renderer {
			.app-button {
				&:hover, &:focus {
					border: none !important;
					box-shadow: none !important;
				}
			}
		}
		&.sticky-scroll {
			position: relative;
			.ag-body-horizontal-scroll {
				position: fixed;
				bottom: 0;
				padding: 0 50px;
				left: 0;
				z-index: 999;
				width: 100%;
			}
		}
	}
	.category-color {
		display: inline-block;
		min-width: 10px;
		height: 10px;
		border-radius: 100%;
		margin-right: 5px;
	}

	.category-title {
		max-width: 70%;
	}

	.owner-count,.bundle-count {
        background-color: $light_blue;
        font-size: $fontSize10;
        letter-spacing: 0;
        text-align: center;
        color: $white;
        margin-left: 5px;
        border-radius: 10px;
        width: 30px;
		max-width: 30px;
        height: 22px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.owner-count-tooltip {
		width: auto;
	}
	.apps-owner-button {
		text-decoration: none;
		color: $link_blue;
		max-width: 220px;
		display: inline-block;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.apps-copy-button, .apps-play-button {
		color: $link_blue !important;
		text-decoration: none !important;
		
		svg {
			fill: $link_blue !important;
		}
		&:hover {
			text-decoration: none;
			border: 0
		}
		&:focus {
			border: 0;
			box-shadow: none;
		}
	}
	.link-copied-notification {
		position: fixed;
		left: 50%;
		bottom: 30px;
		padding: 5px 15px;
		background-color: $white;
		color: $emerald;
		border-radius: 15px;
		box-shadow: 0 0 15px 0 rgba($black, 0.08);
		transform: translate(-50%, -50%);
	}

	.deleteModal {
		.red-highlighted {
			text-transform: uppercase;
		}	
		.app-modal-delete-body{
			font-weight: normal;
		}
	}

	.async-dropdown {
		padding: 5px 8px;
		width: 98%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		.async-dropdown-name {
			border-right: 1px solid $search_dropdown_border;
		}
		div {
			flex: 1;
			align-self: flex-start;
			padding: .6rem 1.5rem;
		}
		.async-description {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	@include search-wrapper();
	.search-wrapper .form-control {
        width: 500px;
      } 
}
.accept-file {
	.app-button {
		padding-left: 0;
		justify-content: flex-start;

		svg {
			margin-right: 5px;
		}
	}
}
.thumbnail-preview-dialog {
	.thumbnail-preview {
		min-height: 480px;
	}
}
