@import '../../styles/variables.scss';

.admin-add-event-modal {
    .local-time-warning {
		color: $red;
		text-align: center;
		padding: 10px 0
	}
    .schedule-duration {
		display: flex;
		flex-direction: row;

		.seprator {
			margin-top: 10px;
			margin-left: 8px;
		}
		.app-number-picker {
			margin: -25px 0 0 20px;
		}
	}
}