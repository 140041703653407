@import "../../../styles/variables.scss";
@import "../editor/_pillEditor.scss";
.list {
    width: 100%;
    .ag-theme-material {
        color: $selected_color;
        font-family: $eyInterstateLight;
        font-size: $fontSize14;
        line-height: normal;

        .ag-filter-toolpanel-header,
        .ag-filter-toolpanel-search,
        .ag-header-row,
        .ag-panel-title-bar-title,
        .ag-side-button-button,
        .ag-status-bar {
            font-size: $fontSize14;
            font-weight: 400;
            color: $selected_color;
        }
        .ag-header-row{
            color: $selected_color;
            font-size: $fontSize12;
            font-family: $eyInterstateRegular;
        }
        .ag-cell-value{
            font-size: $fontSize14;
            color: $selected_color;
            font-family: $eyInterstateLight;
            svg{
                color: $selected_color;
            }
        }
    }

    .app-table {
        .ag-cell-wrap-text {
            word-break: break-word;
        }
        .ag-cell,.ag-header-cell-label {
            line-height: 1.42857rem;
            
            .ag-sort-order {
                display: none;
            }
        }
        .ag-row {
            transform: none!important;
            position: relative!important;
            border-bottom: 1px solid $light-border;

            &.ag-row-focus {
                z-index: 1!important;
            }
        }
        .ag-row-selected{
            background-color: $ag-grid-selected-bg;
        }
        .ag-row-selected::before {
            background: none;
          }
        .ag-pinned-left-cols-container .ag-cell, .ag-pinned-left-cols-container .ag-header-cell, .ag-pinned-left-header .ag-cell, .ag-pinned-left-header .ag-header-cell {
            padding: 0 8px;
        }
        .ag-react-container {
            height: 100%;
            width: 100%;
            display: flex;
        }
        .ag-cell {
            overflow: visible !important;
            border: 0 !important;
            border-left: 1px solid $light-border !important;
            .ag-cell-wrapper{
                height: 100%;
            }
        }
        .ag-header-cell{
            border-left: 1px solid $light-border;
            border-right: 0 !important;
            border-top: 1px solid $light-border;
        }
        .ag-pinned-left-header{
            border-right: 0 !important;
        }
        .ag-pinned-left-cols-container, .ag-pinned-left-header {
            z-index: 1;
        }
        .ag-header {
            overflow: visible;
            border-right: none !important;
            z-index: 1;
            background-color: $disabled_text_color;
            .ag-icon-menu {
                visibility: hidden;
            }
        }
        .ag-header-active {
            .ag-icon-menu {
                visibility: visible;
            }
        }

        .app-tooltip.app-tooltip-container {
            width: auto;
            .app-icon {
                font-size: $fontSize14;
              }
        }
        .ag-pinned-right-cols-container{
            border-right: 1px solid $light-border;
        
        }
    }
    .app-table-cell-renderer {
        display: flex;
        max-width: 100%;
    }
    .ag-body-viewport {
        min-height: 300px;
    }
    .ag-theme-material {
        button:focus {
            box-shadow: none;
        }
    }
    .ag-center-cols-viewport {
        overflow-x: hidden;
        min-height: 120px;
    }

    .ag-center-cols-clipper, 
    .ag-header-container, 
    .ag-header-row, 
    .ag-root-wrapper {
        overflow: visible;
    }

    .ag-root-wrapper-body {
        overflow: visible;
        height: 100%;
    }

    .ag-center-cols-clipper {
        transform: none;
    }

    &.sticky-scroll {
        position: relative;
        .ag-body-horizontal-scroll {
            position: fixed;
            bottom: 0;
            padding: 0 50px;
            left: 0;
            z-index: 9;
            width: 100%;
        }
    }
    .custom-filter {
        padding: 20px;
        margin: 0;
        background-color: $white;
        h3 {
            margin: 0;
            margin-bottom: 10px;
            border-bottom: 1px solid $gray;
            font-size: $fontSize14;
            padding-bottom: 8px;
        }
        ul {
            margin: 0;
            padding: 0;
            li {
                list-style: none;
                cursor: pointer;
                margin-bottom: 10px;
                .app-icon {
                    vertical-align: middle;
                    margin-right: 10px;
                }

                &.disable-option {
                    opacity: 0.4;
                    pointer-events: none;
                }
            }
            .filter-radio-title {
                margin: 0;
                padding-bottom: 10px;
            }

            .filter-radio-option {
               padding-right: 10px;
            }
            .search-input {
                padding: 5px 10px;
            }
            .search-error {
                color: $red;
                padding: 5px;
            }
            .list-date-filter {
                padding: 5px 10px;
                font-family: inherit;
                font-size: $fontSize14;
            }
        }
    }
    div[col-id="expirationDate"].ag-cell-inline-editing {
        height: 145px;
        padding: 15px;
        width: 220px !important;
    }
    .list-date-container {
        h3{
            margin-top: 0;
        }
        padding: 10px;
        .list-date-editor {
            padding: 5px 10px;
            font-family: inherit;
            font-size: $fontSize14;
        }
    }
    .list-date-buttons {
        display: flex;
        flex-direction: row;
        .app-button {
            min-width: 50px;
            min-height: 30px;
            padding: 5px;
            display: inline-block;
            margin-right: 15px;
            margin-top: 10px
        }
    }
}

.ag-header-cell .ag-icon-filter {
    font-family: var(--ag-icon-font-family-filter, var(--ag-icon-font-family));
    font-weight: var(--ag-icon-font-weight-filter, var(--ag-icon-font-weight));
    color: var(--ag-icon-font-color-filter, var(--ag-icon-font-color));
    visibility: hidden; /* Initially hide the icon */
  }
  
  .ag-header-cell:hover .ag-icon-filter {
    visibility: visible; /* Show the icon on hover of the header */
  }
  
  .ag-icon-filter::before {
    content: var(--ag-icon-font-code-filter, "\f115");
    display: var(--ag-icon-font-display-filter, var(--ag-icon-font-display));
  }
  
  .ag-icon-filter::after {
    background-image: var(--ag-icon-image-filter, var(--ag-icon-image));
    display: var(--ag-icon-image-display-filter, var(--ag-icon-image-display));
    opacity: var(--ag-icon-image-opacity-filter, var(--ag-icon-image-opacity, 0.9));
  }
  
.list-pagination {
    display: flex;
    background-color: $white;
    justify-content: flex-end;
    margin-bottom: 10px;
    width: 100%;
    .pagination-page-number {
        align-self: flex-start;
        padding: 15px;
        font-weight: bold;
    }
}
