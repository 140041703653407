@import '../../styles/variables.scss';

.customize-bundle-container {
  padding-top: 4px;
  .customize-bundle-div{
    height: 100%;
  }
  p {
    color: $mine_shaft;
    font-family: $eyInterstateLight;
  }
  .cust-bundle-header-title {
    font-size: $fontSize19;
  }
  .cust-bundle-bg {
    background: $temo;
  }
  .cust-app-bg {
    background: $temo_blue;
  }
  .margin-right-zero {
    margin-right: 0;
  }
  .cust-details {
    border-left: 1px solid $frenchy_gray;
  }
  .app-card{
    margin: 0 10px 10px 0;
    .app-card-body{
      width: 100%;
    }
  }
}
