@import "../../styles/globalStyles.scss";
@import "../../styles/variables.scss";
.app-selection {
    padding: 0px 0px 48px 0px;
    .title-section {
        padding: 0px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 44px;
        margin-top: 24px;
        margin-bottom: 24px;
        .most-popular-apps-label {
            font-family: $eyInterstateRegularNew;
            font-size: $fontSize18;
            font-weight: 400;
            line-height: 28px;
            text-align: left;
        }
        .filter-search {
            display: flex;
            .filter-icon {
                .app-icon-filter {
                    width: 38px;
                    height: 38px;
                    border-radius: 4px;
                    border: 1px solid $ey-phoenix-light-gray-2;
                    padding: 11px;
                    margin-right: 12px;
                    .app-icon {
                        color: $ey-phoenix-light-gray;
                    }
                    &:hover {
                        background-color: $light-border;
                    }
                }
                
            }
            .search-box {
                .MuiInputBase-root {
                    width: 385px;
                    height: 38px;
                }
            }

        }
        
    }
    .app-container {
        min-height: 218px;
        .app-container-wrapper{
            grid-gap: 18px;
            display: grid;
            justify-content: center;
            padding: 10px 0px 20px;
            grid-template-columns: repeat(auto-fill, 326px);
        }
        .new-app-wrapper{
            grid-template-columns: repeat(auto-fill, 292px);
            @media only screen and (min-width: 1686px) and (max-width: 1865px)  {
                grid-template-columns: repeat(auto-fill, 310px);
            }
            @media only screen and (min-width: 1440px) and (max-width: 1590px)  {
                grid-template-columns: repeat(auto-fill, 315px);
            }
            @media only screen and (min-width: 1366px) and (max-width: 1439px)  {
                grid-template-columns: repeat(auto-fill, 300px);
            }
            @media only screen and (min-width: 1200px) and (max-width: 1365px)  {
                grid-template-columns: repeat(auto-fill, 282px);
            }
        }
        .no-apps-found {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            height: 100%;
            min-height: 218px;
            .no-apps-label {
                font-size: $fontSize13;
                margin: auto;
            }
        }
        @media (min-width: 1200px) {
            min-height: 355px;
        }
    }
    .show-more-less {
        display: flex;
        justify-content: center;
        padding: 10px 0 0px;
        .load-button-icon {
            @include primary-button-icon;
            margin-left: 5px;
        }
    }
    .divider-line {
        border-bottom: 1px solid $light-border;
        margin: 24px 0;
    }
    .selected-apps-chip {
        padding-left: 0px;
        padding-right: 0px;
        margin-bottom: 20px;
    }
}
