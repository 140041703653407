@import '../../styles/globalStyles.scss';

.confirm-create-project {
  display: flex;
  flex-direction: column;
  background-color: $temo;
  padding: 45px 50px;
  height: 100%;
  font-family: $eyInterstateLight;
  font-weight: 300;
  color: $charade_dark;

  .title {
    font-family: $eyInterstateLight;
    font-size: $fontSize20;
    font-weight: 300;
    display: flex;
    align-items: flex-end;
    margin-bottom: 60px;

    .circle {
      height: 29px;
      width: 29px;
      border-radius: 50%;
      display: inline-block;
      margin-right: 12px;
      position: relative;
      background-color: $emerald;
    }
    .tick {
      position: absolute;
      display: inline-block;
      width: 13px;
      height: 8px;
      top: 8px;
      left: 8px;
      border-bottom: 3px solid $white;
      border-left: 3px solid $white;
      transform: rotate(-45deg);
    }
  }
  .content {
    display: flex;

    .project-details {
      font-size: $fontSize24;
      letter-spacing: -0.75px;
      flex: 1 1 0;
      word-break: break-all;
      margin-right: 15px;
      
      .eng-type-title {
        font-family: $eyInterstateRegular;
        font-size: $fontSize18;
        font-weight: normal;
        line-height: 36px;
        margin-top: 25px;
      }
      .eng-type {
        font-size: $fontSize18;
      }
    }
    .bundle-details {
      display: flex;
      flex: 1 1 0;
      font-size: $fontSize18;

      .bundle-card {
        background: $white;
        border: 1px solid $whisper;
        box-shadow: 0 1px 15px 0 rgba(0, 0, 15, 0.1);
        border-top: 3px solid $turbo;
        justify-content: flex-start;
        margin: 20px 0 0 0;
        height: 154px;
        min-width: 244px;

        .bundle-title {
          color: $charade_dark;
          font-size: $fontSize15;
          font-family: $eyInterstateLight;
          font-weight: bold;
          word-break: break-all;
          max-width: 220px;
          @include multiline-ellipsis(2);
        }
        .bundle-desc {
          margin-top: 12px;
          max-width: 150px;
          color: $jumbo;
          font-size: $fontSize12;
          font-family: $eyInterstateLight;
          font-weight: 300;
          @include multiline-ellipsis(2);
        }
      }
    }
    .divider {
      height: calc(100% - 30px);
      margin: 15px 50px 15px 15px;
      border-right: 1px solid $mischka;
    }
    .app-details {
      flex: 2 1 0;
      font-size: $fontSize18;

      .app-titles {
        padding-top: 20px;
        display: flex;
        max-width: 660px;
        flex-wrap: wrap;

        .app-tile {
          display: flex;
          font-size: $fontSize14;
          background: $white;
          min-height: 120px;
          width: 300px;
          padding: 24px 16px 10px;
          margin: 0 29px 26px 0;
          justify-content: space-between;
          box-shadow: 0 1px 15px 0 rgba(0, 0, 15, 0.1);
    
          .icon-n-title {
            display: flex;
    
            .app-icon {
              min-width: 46px;
              min-height: 46px;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              width: 46px;
              height: 46px;
            }
            .app-title {
              margin-left: 5px;
              font-family: $eyInterstateRegular;
              font-weight: 100;
              color: $scarpa_flow_dark;
              font-size: $fontSize20;
            }
            .app-op {
              margin-top: 10px;
              color: $silver;
              font-family: $eyInterstateLight;
              font-size: $fontSize12;
              font-weight: 300;
              line-height: 18px;
            }
          }
        }
      }
    }
  }
}
