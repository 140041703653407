@import '../../styles/variables.scss';

.customize-bundle-header {
  z-index: 1;
  display: flex;
	background: $white;
  box-shadow: 0 0 15px 0 rgba($black, 0.08);
  padding: 0 48px 0;
  color: $tuna;
  font-family: $eyInterstateLight;
  font-weight: 300;
  justify-content: space-between;

  .title-wrapper {
    display: flex;
    padding: 25px 0 25px 0;
    fill: currentColor;
    .title {
      font-size: $fontSize24;
      letter-spacing: -0.56px;
    }
  }

  .app-btn-wrapper {
    display: flex;
    padding: 25px 0;

    .app-btn {
      font-size: inherit;
      font-family: inherit;
      text-decoration: none !important;

      &:hover, &:focus {
        text-decoration: none;
        border: none;
      }
    }
  }
}
