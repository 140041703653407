@import '../../styles/variables.scss';
@import '../../styles/richtTextStyles.scss';

.app-rich-text-box {
  margin: 10px 0;

  .ql-container {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
    background: $white;

    .ql-tooltip.ql-editing {
      input::placeholder {
        opacity: 0;
      }
    }
  }
  
  .ql-snow.ql-toolbar {
    display: block;
    background: $temo;
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
  }

  .ql-picker.ql-font {
    .ql-picker-label::before {
      content: attr(data-value) !important;
      text-transform: capitalize;
    }
    .ql-picker-item {
      font-size: 0;
     
      &:before {
        content: attr(data-value) !important;
        font-size: 14px;
        font-family: attr(data-value) !important;
        text-transform: capitalize;
      }
    }
  }

  .ql-picker.ql-size {
    width: 65px;

    .ql-picker-label::before {
      content: attr(data-value) !important;
      text-transform: capitalize;
    }
    .ql-picker-item {
      font-size: 0;
     
      &:before {
        content: attr(data-value) !important;
        font-size: 14px;
        font-family: attr(data-value) !important;
        text-transform: capitalize;
      }
    }
  }
  
  .ql-editor {
    min-height: 150px;
  }

  .ql-picker.ql-font .ql-picker-label::before, 
  .ql-picker.ql-font .ql-picker-item::before
  {
      font-family: attr(data-value) !important;
  }

  .ql-picker {
    &.ql-fontSizes {
      width: 65px;

      .ql-picker-label::before {
        content: attr(data-value) !important;
      }

      .ql-picker-item {
        font-size: 0;
      
        &:before {
          content: attr(data-value) !important;
          font-size: 14px;
          font-family: attr(data-value) !important;
        }
      }
    }
  }
  .ql-color-picker {
    margin-bottom: -5px;
  }

  .error-msg {
    color: $red;
    font-size: $fontSize12;
    padding: 0 10px;
  }

  @include richTextFontStyles;
}