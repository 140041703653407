@import '../../styles/globalStyles.scss';
.create-project {
  .modal-content {
    width: 500px;
    font-size: $fontSize14;
    font-family: $eyInterstateLight;
    font-weight: 300;
    .label {
      font-weight: bold;
    }
    .label-modal-border {
      border-bottom: 1px dashed $athens_gray_light;
      margin-bottom: 5px;
    }
    .label-margin-bottom {
      margin-bottom: 5px;
    }
  }
  
}
.change-or-confirm-project-category {
  .pc-modal-content {
    margin-bottom: 20px;
  }
  .pc-modal-content-confirm {
    margin-bottom: 15px;
  }
  #app-dialog-title {
    h5 {
      font-size: 1.2rem;
    }
  }
}
