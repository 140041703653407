﻿@import './utilities.scss';
@import './variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');


@mixin richTextFontStyles {
	.ql-font-EYRegular{
		font-family: "EY-Regular";
	}
	.ql-font-EYLight{
		font-family: "EY-Light";
	}
	.ql-font-arial {
		font-family: "arial";
	  }
	  .ql-font-sans-serif {
		font-family: "sans-serif";
	  }
	  .ql-font-tahoma {
		font-family: "tahoma";
	  }
	  .ql-font-calibri {
		font-family: "calibri";
	  }
	  .ql-font-verdana {
		font-family: "verdana";
	  }	  
	  .ql-font-Georgia {
		font-family: "georgia";
	  }	  
}

@mixin richTextColorStyles {
	.ql-bg-black {
		background-color: #000;
	  }
	   .ql-bg-red {
		background-color: #e60000;
	  }
	   .ql-bg-orange {
		background-color: #f90;
	  }
	   .ql-bg-yellow {
		background-color: #ff0;
	  }
	   .ql-bg-green {
		background-color: #008a00;
	  }
	   .ql-bg-blue {
		background-color: #06c;
	  }
	   .ql-bg-purple {
		background-color: #93f;
	  }
	   .ql-color-white {
		color: #fff;
	  }
	   .ql-color-red {
		color: #e60000;
	  }
	   .ql-color-orange {
		color: #f90;
	  }
	   .ql-color-yellow {
		color: #ff0;
	  }
	   .ql-color-green {
		color: #008a00;
	  }
	   .ql-color-blue {
		color: #06c;
	  }
	   .ql-color-purple {
		color: #93f;
	  }
}

@mixin richTextHeadingSizes {
	.ql-size-small {
		font-size: 0.75em;
	}
	.ql-size-large {
		font-size: 1.5em;
	}
	.ql-size-huge {
		font-size: 2.5em;
	}
}