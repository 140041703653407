@import '../../styles/variables.scss';
@import "../../styles/globalStyles.scss";

.admin-generic-modal {
    .MuiFilledInput-root{
        border-radius: 4px;
        background-color: $white !important;
    }
    .MuiTextField-root{
        border-radius: 4px;
    }
    .MuiFilledInput-input {
        background-color: $white !important;
        color: $ey-phoenix-light-gray !important;
    }

    .MuiFormHelperText-root {
        font-size: $fontSize14;
    }

    .content-title {
        font-weight: 700;
        font-size: 0.9rem;
    }

    .schedule-duration {
        display: flex;
        flex-direction: row;

        .app-number-picker {
            margin: -25px 0 0 20px;
        }

        .seprator {
            margin-top: 10px;
            margin-left: 8px;
        }
    }

    .margin-date-time {
        margin-top: 20px;
    }

    .tool-tip {
        margin-left: 5px;
        margin-top: 5px;
    }

    .MuiTooltip-tooltip {
        min-width: 500px;
    }
}

#menu-wfr_ScheduleLookupId,
#menu-communicationTemplateId,
#menu-wfr_WorkflowLookupId {
  max-width: 250px;

  .app-select-option {
    white-space: unset !important;
    word-break: break-all;
  }
}
/** New styles for the generic form modal */
.manage-workflow-modal{
.project-form-new {
    display: flex;
    width: 100%;
    overflow-x: hidden;
    background-color: $white;
    color: $mine_shaft;
    font-family: $eyInterstateLight;
    min-height: unset;
    flex: unset;
  
    .details-wrapper {
      height: max-content;
      min-height: 390px;
      width: 285px;
      padding-right: 36px;
      box-shadow: 30px 0 25px -30px rgba($gray, 0.08);
      border-right: 1px solid $temo;
      position: relative;
      min-width: 280px;
  
      .arrow {
        width: 20px;
        height: 20px;
        background-color: $whisper;
        box-shadow: 30px 0 25px -20px rgba($gray, 0.08);
        display: inline-block;
        transform: rotate(-45deg);
        position: absolute;
        right: -10px;
        top: calc(50% - 20px);
        border-right: 1px solid $temo;
        border-bottom: 1px solid $temo;
      }
    }
    .form-wrapper {
      min-width: 100%;
      flex: 1 1 0px; // 0px deliberately written
  
      .pace-id-info-box {
        border: 1px solid $motif-toast-orange;
        padding: 10px;
        border-radius: 8px;
        margin: 0 0 15px 0;
        display: flex;
        min-width: 1180px;
        position: relative;
        background-color: $motif-toast-orange-light;
        color: $motif-toast-orange-medium;
  
        h5 {
          margin: 0 10px;
          font-size: 14px;
          margin-bottom: 5px;
        }
  
        .info-box-icon {
          color: $neon_carrot;
        }
  
        .info-description {
          padding: 0 20px 0 10px;
          font-size: $fontSize14;
          font-family: $eyInterstateLightNew;
          font-weight: 400;
  
          .info_desc_line_2 {
            margin-top: 5px;
            font-size: $fontSize14;
            font-family: $eyInterstateLightNew;
            font-weight: 400;
          }
  
          .info-box-link {
            font-weight: bold;
            cursor: pointer;
            margin-top: 5px;
            display: inline-block;
            font-size: $fontSize14;
            text-decoration: none;
            color: $secondary_button_grey;
            display: flex;
            flex-direction: row;
            align-items: center;
            p {
              margin: 0 10px 0 0;
            }
            .info-box-arrow {
              height: 16px;
            }
          }
        }
  
        .pace-id-info-box-close {
          position: absolute;
          right: 10px;
          color: $ey-phoenix-light-gray;
  
          .pace-info-close-btn {
            font-size: 1rem;
          }
        }
      }
  
      .paceid-warning-icon {
        width: 25px;
        cursor: pointer;
        color: $neon_carrot;
      }
  
      .enter-details {
        font-size: $fontSize18;
        margin-bottom: 20px;
      }
      .warning-container {
        color: $motif-warning-orange;
      }
  
      .app-select {
        .MuiFormLabel-root {
          color: $gray !important;
          font-family: $eyInterstateLight;
          font-size: $fontSize14;
        }
        .MuiSelect-root {
          padding: 21px 10px 6px 10px;
          background-color: $white;
          color: $gray !important;
          font-size: $fontSize14;
        }
        .Mui-disabled {
          background-color: lightgrey !important;
        }
        .MuiInputLabel-filled.MuiInputLabel-shrink {
          transform: translate(10px, 6px) scale(0.82);
        }
        .MuiFilledInput-underline {
          &::before {
            border-bottom: 1px solid $emerald !important;
          }
          &::after {
            border-bottom: 1px solid $emerald !important;
          }
        }
      }
      .app-select.in-valid {
        .MuiFilledInput-underline {
          &::before {
            border-bottom: 1px solid $thunderbird !important;
          }
          &::after {
            border-bottom: 1px solid $thunderbird !important;
          }
        }
      }
  
      .input-row {
        display: flex;
  
        .MuiFormHelperText-root {
          font-family: $notoSans;
          font-size: $fontSize12;
        }
  
        .middle {
          align-items: center;
        }
  
        .text-area-wrapper {
          flex: 1;
          margin-right: 8px;
  
          .app-text-area {
            .MuiInputBase-input {
              background-color: $white;
              padding: 0;
              color: $gray !important;
              font-size: $fontSize15;
  
              &:hover,
              &:focus {
                border-bottom-width: 1px;
              }
            }
            .MuiFormLabel-root,
            .MuiInputLabel-shrink,
            .Mui-focused {
              color: $gray !important;
              font-size: $fontSize14;
            }
            .MuiInputBase-root {
              background-color: $white;
              border-radius: 0;
  
              &:hover,
              &:focus {
                background-color: $white;
  
                &::before {
                  border-bottom: 1px solid $jumbo;
                }
              }
            }
          }
          // TODO- hardcoding width need to be removed, for now stay with what it is, since refactor is involves whole form!
  
          .width-100-percent {
            width: 100%;
          }
        }
        .text-area-field {
          min-width: 100% !important;
          max-width: 100% !important;
          position: relative;
          margin-bottom: 16px;
  
          .mfa-tool-tip {
            z-index: 1;
            display: flex;
            width: 16px;
            color: $ey-phoenix-light-gray;
            cursor: pointer;
          }
        }
      }
      .project-form-field {
        position: relative;
        margin-bottom: 16px;
        flex: 1 1 0px; // 0px deliberately written
        max-width: 440px;
        min-width: 440px;
        .input-label {
          font-family: $eyInterstateLight;
          font-size: $fontSize14;
          color: $ey-phoenix-light-gray;
        }
        .serviceline-warning {
          color: $motif-toast-orange-medium;
        }
      }
  
      .app-input {
        .MuiInputBase-input {
          background-color: $white;
          padding: 20px 12px 7px;
          color: $gray !important;
          font-size: $fontSize15;
  
          &:hover,
          &:focus {
            border-bottom-width: 1px;
          }
        }
        .MuiFormLabel-root,
        .MuiInputLabel-shrink,
        .Mui-focused {
          color: $gray !important;
          font-size: $fontSize14;
        }
      }
      .required,
      .in-valid {
        .MuiInputBase-root {
          &::before {
            border-bottom: 1px solid $thunderbird;
          }
  
          &:hover,
          &:focus {
            &::before {
              border-bottom: 1px solid $thunderbird;
            }
          }
        }
      }
      .data-location-tooltip {
        padding: 0 0 0 19px;
        z-index: 1;
        min-width: 37px;
        &.search-tooltip {
          right: 50px;
          top: 33px;
          display: flex;
          position: relative;
          color: $ey-phoenix-light-gray;
        }
        .app-tooltip {
          width: 16px;
          color: $ey-phoenix-light-gray;
        }
      }
    }
    .mfa-check-box {
      .checbox-mfa-div {
        text-align: left;
        display: flex;
        align-items: center;
      }
  
      .mfa-tool-tip {
        z-index: 1;
        display: flex;
        width: 16px;
        color: $ey-phoenix-light-gray;
      }
    }
    &::-webkit-scrollbar {
      width: 6px;
      height: 7px;
    }
  
    &::-webkit-scrollbar-track {
      border-radius: 5px;
    }
  
    &::-webkit-scrollbar-thumb {
      background: $scarpa_flow;
      border-radius: 5px;
    }
    .margin-left-client-field {
      margin-left: -20px;
    }
    .choose-icon-wrapper {
      background: $athens_gray;
      height: 147px;
      min-width: 350px;
      max-width: 350px;
      padding: 15px;
      .project-icon-message {
        color: $icon-message-color;
        font-size: $fontSize14;
        font-family: $eyInterstateLight;
        font-weight: 300;
        line-height: 16px;
        margin-bottom: 20px;
      }
      .icons-container {
        display: flex;
        justify-content: center;
        align-items: center;
        .project-icon {
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          &.highlight {
            margin-right: 25px;
            height: 62px;
            width: 62px;
            svg {
              fill: $white;
            }
          }
        }
        .icon-list {
          display: flex;
          flex-wrap: wrap;
          .project-icon {
            width: 24px;
            height: 24px;
            margin-right: 20px;
            margin-top: 7px;
            background-color: $temo;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &.select {
              border: 1px solid $frenchy_gray;
            }
            svg {
              height: 19px !important;
              width: 16px !important;
            }
          }
        }
      }
    }
    .policy-text-wrapper {
      min-width: 100%;
      max-width: 100%;
  
      .policy-text {
        font-size: $fontSize14;
        font-family: $eyInterstateLightNew;
        h3 {
          margin: 0 0 14px;
        }
      }
    }
  
    @include search-wrapper();
  
    .search-wrapper {
      flex-direction: column;
      .dropdown-menu {
        background-color: $white;
        border: 1px solid $black;
        border-radius: 0;
        border-top: none;
        max-height: 203px !important;
  
        .dropdown-item {
          border: none;
        }
      }
      .async-dropdown {
        padding: 8px;
        color: $gray;
        font-family: $eyInterstateLight;
        font-size: $fontSize14;
        font-weight: 300;
  
        &:hover {
          background-color: $whisper;
        }
      }
      .render-search {
        .input-search {
          color: $gray;
          background: $white !important;
          font-family: $eyInterstateLight;
          font-size: $fontSize14;
          font-weight: 300;
          height: 40px;
          border: 1px solid $ey-phoenix-light-gray-2;
          border-radius: 5px;
          padding: 2px 5px 2px 35px;
          width: 100%;
          margin-bottom: 0px;
        }
        .app-icon {
          opacity: 0.5;
          left: 0;
          top: 2.1rem;
          height: 25px;
          span {
            svg {
              width: 24px;
            }
          }
        }
        .disable-input {
          cursor: not-allowed;
        }
        .disable-clear {
          display: none;
        }
      }
      .disable-search {
        cursor: not-allowed;
  
        .input-search {
          padding-left: 12px;
        }
      }
    }
  }
  .MuiPaper-root{
    padding: 20px 32px;
  }
  .MuiDialogTitle-root{
      padding: 0px 0 8px;
      font-size: $fontSize20;
      color: $secondary_button_grey;
      .MuiButtonBase-root{
        right: 30px;
        top: 15px;
        width: 40px;
        height: 40px;
        padding: 14px;
      }
  }
  .MuiDialogContent-root{
    padding: 20px 0 15px;
    border-bottom: 0;
  }
  .MuiDialogActions-root{
    padding: 0;
    .footer-container{
        padding: 15px 0 0;
        .footer-btns .app-button{
            margin-right: 0;
            margin-left: 10px;
        }
    }
  }
}
