@import '../../styles/variables.scss';
@import '../../styles/globalStyles.scss';

.admin-client-sizes-modal-content {
  min-height: 150px;

  .MuiInputBase-root.Mui-disabled {
    background-color: $dropdown_form_control_bg;
  }
  .admin-client-sizes-modal-textarea {
    margin: 15px 0;
  }
}