@import "../../styles/variables.scss";
.dropdown-menu {
  display: flex;
  .arrow {
    border: solid $grey;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
  }

  .right {
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  }

  .left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  .up {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }

  .down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  .greyed-outline {
    color: $grey;
    fill: currentColor;
  }
  .user-avatar {
    color: $grey;
    fill: currentColor;
    font-size: $fontSize32;
    padding-right: 12px;
    display: flex;
    align-items: center;

    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
    }
    .image-overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(249, 244, 244, 0.3);
      opacity: 0;
      transition: opacity 0.3s ease;
    }

    &:hover .image-overlay {
      opacity: 1;
    }
  }
  .user-icon {
    color: $grey;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e2e2e2;
    border-radius: 50%;
    &:hover {
      background-color: rgba(245, 245, 245);
    }
  }
}

#user-drop-down-menu {
  .MuiPaper-root {
    top: 51px !important;
    border-radius: 4px;
  }
}

.user-profile-menu-pop {
  .more-menu-option {
    padding: 8px 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: $fontSize14;
  }
  .more-menu-option:first-child {
    border-bottom:1px solid $light-border;
  }
}
