@import "../../../styles/variables.scss";

.ag-react-container {
    display: flex;
    align-items: center;
    .select-cell-editor {
        width: 100%;
        border: 0px;
        .custom-option {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-family: $eyInterstateRegular;
            font-size: $fontSize12;
            cursor: pointer;

            &:hover {
                .check-option {
                    input ~ .checkmark {
                        border-width: 2px;
                    }
                }
            }

            .check-option {
                display: block;
                position: relative;
                padding-left: 25px;
                margin-bottom: 12px;
                cursor: pointer;
                font-size: $fontSize22;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                height: 10px;

                input {
                    position: absolute;
                    opacity: 0;
                    cursor: pointer;
                    height: 0;
                    width: 0;
                }

                .checkmark {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 20px;
                    width: 20px;
                    border: 1px solid $header_admin_menu_active
                }

                &:hover input ~ .checkmark {
                    border-width: 2px;
                }

                input:checked ~ .checkmark {
                    background-color: $header_admin_menu_active;
                    border-width: 1px;
                }

                .checkmark:after {
                    content: "";
                    position: absolute;
                    display: none;
                }

                /* Show the checkmark when checked */
                input:checked ~ .checkmark:after {
                    display: block;
                }

                /* Style the checkmark/indicator */
                .checkmark:after {
                    left: 6px;
                    top: 2px;
                    width: 5px;
                    height: 10px;
                    border: solid $white;
                    border-width: 0 2px 2px 0;
                    -webkit-transform: rotate(45deg);
                    -ms-transform: rotate(45deg);
                    transform: rotate(45deg);
                }

            }
        }
    }
}