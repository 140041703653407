@import '../../styles/globalStyles.scss';
@import '../../styles/variables.scss';
footer.footer-admin {
	display: flex;
	background: $whisper;

	.content {
		display: flex;
		margin: 13px 19px;
		color: $charade;
		font-size: $fontSize10;
		line-height: 18px;
		align-items: center;
		justify-content: flex-start;
		.footer-title {
			margin-right: 20px;
		}
		.anchor-link {
			flex: 0 0 auto;
			font-weight: bold;
			margin-right: 20px;
			a {
				color: inherit;
				text-decoration: none;
			}
		}
	}
}
