@import '../../styles/variables.scss';

.app-search-dropdown {
    width: 100%;
    max-width: 300px;
    
    .app-dropdown-button {
      width: 100%;
      padding: 8px 15px;
      max-width: 300px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: transparent;
      border: 0;
      cursor: pointer;
      font-family: inherit;

      &:disabled {
        cursor: default;
      }

      .dropdown-arrow {
        color: $frenchy_gray;
        font-size: 14px;
      }

      .app-tooltip.app-tooltip-container {
        width: 80% !important;
      }

      .dropdown-selected-values {
        display: inline-block;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    ul, li {
      padding: 0;
      margin: 0;
      list-style: none;
    }

    .app-dropdown-list {
      border: 1px solid $gray;
      background-color: $white;
      padding: 10px;
      position: fixed;
      width: 255px;
      z-index: 9999;

      &.top-list {

        &.search-results {
          .app-dropdown-options {
            max-height: 250px;
          }
        }

        .no-results {
          max-height: 200px;
        }
      }

      .no-results {
        padding: 0 5px;
        color: $gray;
        max-height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .dropdown-search-bar {
        width: 100%;
        padding: 8px 15px;
        margin-bottom: 10px;
      }

      .app-dropdown-options {
        max-height: 200px;
        overflow: auto;
        margin-right: -8px;

        .single-select {
          padding: 5px 10px;
          cursor: pointer;
        }

        .app-checkbox {
          .MuiButtonBase-root {
            padding: 8px 9px;
          }
        }

        &::-webkit-scrollbar {
          width: 6px;
          height: 7px;
        }
    
        &::-webkit-scrollbar-track {
          border-radius: 5px;
        }
    
        &::-webkit-scrollbar-thumb {
          background: $scarpa_flow;
          border-radius: 5px;
        }
      }

      .dropdown-paginate {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        border-top: 1px solid $black;
        margin-top: 8px;
      }
    }
}