@import '../../styles/variables.scss';

.chip-container {
  border: 1px solid $ey-phoenix-light-gray-2;
  display: inline-flex;
  border-radius: 2px;
  padding: 4px 10px;
  background-color: $disabled_text_color;
  margin-right: 10px;
  margin-bottom: 10px;
  align-items: center;
  span {
    color: $secondary_button_grey;
    font-size: $fontSize14;
    white-space: nowrap;
    line-height: 1.3;
  }
  .remove-chip {
    padding-left: 10px;
    display: inline-flex;
    cursor: pointer;
    svg{
      font-size: $fontSize13;
    }
  }
  .chilp-name{
    text-overflow: ellipsis;
    max-width: 160px;
    min-width: 31px;
    overflow: hidden;
    @media screen and (max-width: 1599px) {
      max-width: 140px;
    }
    @media screen and (max-width: 1399px) {
      max-width: 120px;
    }
    @media screen and (max-width: 1299px) {
      max-width: 90px;
    }
  }
}
