@import '../../styles/variables.scss';

.admin-apps-modal {

  .add-region-btn {
    font-weight: bold;
    color: $black;
  }

  .type-name-key {
    display:flex;

    div:nth-child(1) {
      min-width: 60%;
      margin-right: 40px;
    }
    div:nth-child(2) {
      min-width: calc(40% - 40px);
    }
  }
  .type-desc {
    min-width: 700px;

    .MuiInputBase-input {
      font-size: $fontSize16;
    }
  }
  .appOwner {
    .app-col-xs-1{
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  .close-input-field {
    display: flex;
    .close-btn {
      margin-left: 8px;
      margin-top: 10px;
      cursor: pointer;
    }
  }

  .dropzone {
    width:100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2;
    border-radius: 2;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
  }

  .MuiFilledInput-input {
    padding: 22px 12px 10px;
  }

  .async-dropdown-modal {
    width:100%;
    .async-dropdown-name-modal {
      width:100%;
    }
  }


  .search-wrapper {

    .close {
      font-size: $fontSize25;
      font-weight: 700;
      line-height: 1;
      color: $black;
      text-shadow: 0 1px 0 $white;
      opacity: .5;
  
      // Override <a>'s hover style
      &:hover {
        color: $black;
        text-decoration: none;
      }
  
      &:hover,
      &:focus {
        opacity: .75;
      }
  
      &:disabled,
      &.disabled {
        pointer-events: none;
      }
    }
  
    button.close {
      padding: 0;
      background-color: transparent;
      border: 0;
    }

    @keyframes spinner-border {
      to { transform: rotate(360deg); }
    }

    .spinner-border {
      display: inline-block;
      width: 2rem;
      height: 2rem;
      vertical-align: text-bottom;
      border: .25em solid currentColor;
      border-right-color: transparent;
      border-radius: 50%;
      animation: spinner-border .75s linear infinite;
    }

    .sr-only {
      display: none;
    }
  
    .spinner-border-sm {
      width: 1rem;
      height: 1rem;
      border-width: .2em;
    }
    .project-form-field {
      position: relative;
      margin-bottom: 0px !important;

      .app-icon {
        position: absolute;
        z-index: 1;
        padding: 14px;
        color: $frenchy_gray;
        min-width: 30px;
        text-align: center;
        width: 45px;
        height: 50px;
        color: $black;

        svg {
          width: 1.3rem;
          height: 1.3rem;
        }
      }
    }
    button, input {
      overflow: visible;
      margin: 0;
      font-family: inherit;
    }
    a {
      text-decoration: none;
    }
    .form-control {
      display: block;
      width: 100%;
      padding: 0.375rem 2.5rem;
      background-color: $dropdown_form_control_bg;
      height: 52px;
      font-size: $fontSize14;
      font-weight: 400;
      line-height: 1.5;
      color: $dropdown_form_control_color;
      background-clip: padding-box;
      border: none;
      border-radius: 0;
      transition: border-color 0.15s;
      border-bottom: 1px solid $black;
    } 
    .rbt-menu > .dropdown-item {
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .dropdown-menu {
      background-color: $search_input_color;
      border: 1px solid $search_dropdown_border;
      margin: 0;
      padding: 0;
      z-index: 1000;
      float: left;
      min-width: 10rem;
      font-size: $fontSize16;
      color: $search_dropdown_color;
      text-align: left;
      list-style: none;
      background-clip: padding-box;
      border-radius: 0.25rem;
      max-height: 450px!important;
      .dropdown-item {
        display: block;
        width: 100%;
        padding: 0;
        clear: both;
        font-weight: 400;
        color: $search_dropdown_color;
        text-align: inherit;
        white-space: break-spaces;
        background-color: transparent;
        border: 0;
        border-bottom: 1px solid $search_dropdown_border;
        font-size:$fontSize13;
      }
      .dropdown-item:last-child {
        border-bottom: 0;
      }
    } 
 
  }

  .accept-file {
    width:100%;
    .accept-file-container {
      display: flex;
      justify-content: space-between;
      border-color: #eeeeee;
      border-style: solid;
      background-color: #fafafa;
      border-width: 1px;
      width:100%;
      padding:1rem;
      .video-text-container {
        display:flex;
        align-items: center;
        .video-text{
          padding-left: 10px;
        }
      }
    }
  }
  .video-header {
    padding:1rem 0;
  }

  .file-name {
    padding-bottom: 2px;
  }

  .limited-available-tool-tip {
    padding: 5px 8px 0 0;
    z-index: 1;
    margin-left: 5px;
  }
  .checkbox-limited-available-div {
    display: flex;
    align-items: center;
    .check-box-la {
      margin-top: 5px;
    }
  }
  .autoPublish-box {
    margin-top: 5px;
  }
  .MuiFormHelperText-root {
    font-size: $fontSize14;
  }
  .content-title {
    font-weight: 700;
    font-size: 0.9rem;
  }
}

.appOwner {
  .close-btn {
    .MuiButtonBase-root {
      background-color: rgba($black, 0.8);
      border-radius: 50%;
      width: 38px;
      height: 38px;

      &:hover {
        background-color: $black;
      }
      .app-icon {
        color: $white;
        position: relative;
      }
    }
  }
}