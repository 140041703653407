@import '../../styles/variables.scss';

.chip-type-wrapper {
  display: flex;
  align-items: center;
  padding: 10px 1.5rem 0;
  background: $white;
  .type-name{
    width: 7rem;
    border-right: 1px solid $black;
    margin-bottom: 10px;
    span {
      color: $secondary_button_grey;
      font-size: $fontSize14;
    }
  }
  .chip-more{
    display: flex;
    align-items: center;
    border-radius: 2px;
    padding: 4px 10px;
    background-color: $secondary_button_grey;
    margin-bottom: 10px;
    cursor: pointer;
    min-width: 72px;
    span {
      color: $white;
      font-size: $fontSize14;
      white-space: nowrap;
      line-height: 1.3;
      margin-right: 8px;
    }
    svg{
      font-size: $fontSize13;
      color: $white;
    }
  }
  .more-chips-wrap{
    width: 100%;
    flex-wrap: wrap;
  }
}
.chips-wrapper{
  display: flex;
  align-items: center;
  width: calc(100% - 7rem);
  padding-left: 1rem;
  flex-wrap: wrap;
  & > div {
    display: flex;
    align-items: center;
  }
}
