@import '../../styles/globalStyles.scss';

.admin-regions {
	max-width: 895px;

	&.read-only {
		max-width: 852px;
	}

	.region-owners-cell-renderer {
		.owner-count {
			background-color: $light_blue;
			font-size: $fontSize10;
			letter-spacing: 0;
			text-align: center;
			color: $white;
			margin-left: 5px;
			border-radius: 10px;
			width: 30px;
			max-width: 30px;
			height: 22px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	.update-region-cell-renderer {
		.MuiButtonBase-root.Mui-disabled {
			pointer-events: unset;
			cursor: not-allowed;
		}
	}
	
}
