@import '../../styles/variables.scss';
@import '../../styles/globalStyles.scss';

@mixin textEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.nav-menu {
  font-family: $eyInterstateLight;
  h4 {
    font-size: $fontSize18;
    font-weight: 400;
    color: $secondary_button_grey;
    margin: 0;
    font-family: $eyInterstateRegular;
  }
  .workflow-title {
    width: 60%;
    h3 {
      font-size: $fontSize20;
      font-weight: 400;
      color: $secondary_button_grey;
      margin: 0 0 8px;
    }
    p {
      margin: 0;
      color: $ey-phoenix-light-gray;
      font-family: $eyInterstateLight;
      @include textEllipsis;
    }
  }
  .workflow-btn {
    width: 40%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    button {
      margin-left: 10px;
    }
    svg {
      color: $ey-phoenix-light-gray;
      font-size: 1.1rem;
    }

    li {
      position: relative;
      list-style-type: none;

      .dropdown-wrapper {
        padding-top: 10px;
        position: absolute;
        top: 100%;
        right: 0;
        display: none;
        z-index: 99;
      }

      .dropdown-content {
        background-color: $white;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
        min-width: 300px;
        border: 1px solid $ey-phoenix-light-gray-2;

        .sub-nav-link {
          font-family: $eyInterstateLight;
          color: $header_admin_menu_active;
          padding: 10px 10px 10px 15px;
          display: block;
          text-decoration: none;
          transition: background-color 0.3s;
          font-size: $fontSize16;

          &:hover {
            background-color: $dropdown_form_control_bg;
          }

          &.selected {
            background-color: $dropdown_form_control_bg;
            font-weight: 600;
          }

          svg {
            margin-right: 5px;
          }

          .app-icon {
            font-size: inherit;
          }
        }
      }
      &:hover .dropdown-wrapper {
        display: block;
      }
    }
  }
  &.nav-dropdown-wrap{
    padding-top: 0 !important;
  }
}
