﻿@import './utilities.scss';
@import './variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');

* {
    box-sizing: border-box;
}

html {
    font-size: 16px !important; // To override css from theme.scss
    height: 100%;
}
body {
    color: $black;
    font-weight: lighter;
    font-size: 1rem;
    line-height: 1.57143rem;
	background-color: $ey-dull-background;
    overflow-x: hidden;
    margin: 0;
    overflow: auto;
}

body {
		font-family: $eyInterstateRegular !important; // To override css from typography.scss
    	height: 100%;
}

img, svg {
    max-width: 100%;
    max-height: 100%;
}
.content-h4 {
	color: $black;
	line-height: 1.42857rem;
	font-size: $fontSize18;
	font-weight: 600;
	font-family: "noto sans", sans-serif;
}

#root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
		
    .full-height-container{
        flex: 1;
        &> div:first-child {
            height: 100%;
            display: flex;
            flex-direction: column;
        }
        .full-height-fix-app-insights {
            display: block !important;
            &> div:first-child {
                height: 100%;
                display: flex;
                flex-direction: column;
            }
        }
    }
}

.with-sticky-footer .full-height-container { 
	padding-bottom: 126px;
}

.plain-btn {
    color: $manate;
    background: none;
    border: none;
    font-size: $fontSize12;
    font-family: inherit;
    cursor: pointer;
    padding: 0;
}

@mixin multiline-ellipsis($lines) {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    overflow-wrap: break-word;
    -webkit-box-orient: vertical;
}

@mixin singleline-ellipsis() {
    overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

@mixin search-wrapper {
    .search-wrapper {
		display: flex;
  		justify-content: flex-start; 
		.close {
			font-size: $fontSize25;
			font-weight: 700;
			line-height: 1;
			color: $black;
			text-shadow: 0 1px 0 $white;
			opacity: .5;
		
			// Override <a>'s hover style
			&:hover {
				color: $black;
				text-decoration: none;
			}
		
			&:hover,
			&:focus {
				opacity: .75;
			}
		
			&:disabled,
			&.disabled {
				pointer-events: none;
			}
		}
		
		button.close {
			padding: 0;
			background-color: transparent;
			border: 0;
		}

		@keyframes spinner-border {
			to { transform: rotate(360deg); }
		}

		.spinner-border {
			display: inline-block;
			width: 2rem;
			height: 2rem;
			vertical-align: text-bottom;
			border: .25em solid currentColor;
			border-right-color: transparent;
			border-radius: 50%;
			animation: spinner-border .75s linear infinite;
		}

		.sr-only {
			display: none;
		}
		
		.spinner-border-sm {
			width: 1rem;
			height: 1rem;
			border-width: .2em;
		}
		.project-form-field {
			margin-bottom: 0px !important;
			margin-left: auto;
			.app-icon {
				position: absolute;
				z-index: 1;
				padding: 4px;
				text-align: center;
				min-width: 25px;
				height: 90%;
				margin-left: 9px;
				color: $ey-phoenix-light-gray;
			}
		}
		button, input {
			overflow: visible;
			margin: 0;
			font-family: inherit;
		}
		a {
			text-decoration: none;
		}
		.form-control {
			display: block;
			padding: 0.375rem 2.5rem;
			background-color: $white;
			width: 330px;
			height: 44px;
			font-size: $fontSize14;
			font-weight: 400;
			line-height: 1.5;
			color: $dropdown_form_control_color;
			background-clip: padding-box;
			border: 1px solid $ey-phoenix-light-gray-2;
			transition: border-color 0.15s;
			border-radius: 0.25rem;
			margin-bottom: 6px;
		} 
		.rbt-menu > .dropdown-item {
			overflow: hidden;
			text-overflow: ellipsis;
		 }
		.dropdown-menu {
			background-color: $white;
			border: 1px solid $search_dropdown_border;
			margin: 0;
			padding: 5px 0;
			z-index: 1000;
			float: left;
			min-width: 10rem;
			font-size: $fontSize14;
			color: $ey-phoenix-light-gray;
			text-align: left;
			list-style: none;
			background-clip: padding-box;
			border-radius: 0.25rem;
			max-height: 450px!important;
			.dropdown-item {
				display: block;
				width: 100%;
				padding: 0;
				clear: both;
				font-weight: 400;
				color: $ey-phoenix-light-gray;
				text-align: inherit;
				white-space: nowrap;
				background-color: $white;
				border: 0;
				strong {
					font-family: $eyInterstateBold;
					font-weight: 700;
				}
			}
			.dropdown-item:last-child {
				border-bottom: 0;
			}
		} 
	}
    
}

@font-face {
    font-family: "EYInterstate-Regular";
    src: url('../assets/fonts/EYInterstate-Regular.woff') format('woff'), url("../assets/fonts/EYInterstate-Regular.ttf") format('truetype');
}

@font-face {
    font-family: "EYInterstate-Light";
    src: url('../assets/fonts/EYInterstate-Light.woff') format('woff'), url("../assets/fonts/EYInterstate-Light.ttf") format('truetype');
}

@font-face {
    font-family: "EYInterstate-Bold";
    src: url('../assets/fonts/EYInterstate-Bold.woff') format('woff'), url("../assets/fonts/EYInterstate-Bold.ttf") format('truetype');
}

@font-face {
    font-family: "EYInterstate-Italic";
    src: url('../assets/fonts/EYInterstate-Italic.woff') format('woff'), url("../assets/fonts/EYInterstate-Italic.ttf") format('truetype');
}

@font-face {
	font-family: "EY-Light";
	src: url('../assets/fonts/EYInterstate-Light.woff') format('woff'), url("../assets/fonts/EYInterstate-Light.ttf") format('truetype');
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: "EY-Light";
	src: url('../assets/fonts/EYInterstate-LightItalic.woff') format('woff'), url("../assets/fonts/EYInterstate-LightItalic.ttf") format('truetype');
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: "EY-Light";
	src: url('../assets/fonts/EYInterstate-LightBold.woff') format('woff'), url("../assets/fonts/EYInterstate-LightBold.ttf") format('truetype');
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: "EY-Light";
	src: url('../assets/fonts/EYInterstate-LightBoldItalic.woff') format('woff'), url("../assets/fonts/EYInterstate-LightBoldItalic.ttf") format('truetype');
	font-style: italic;
	font-weight: 700;
}

@font-face {
	font-family: "EY-Regular";
	src: url('../assets/fonts/EYInterstate-Regular.woff') format('woff'), url("../assets/fonts/EYInterstate-Regular.ttf") format('truetype');
	font-style: normal;
	font-weight: 400;
}

@font-face {
	font-family: "EY-Regular";
	src: url('../assets/fonts/EYInterstate-Italic.woff') format('woff'), url("../assets/fonts/EYInterstate-Italic.ttf") format('truetype');
	font-style: italic;
	font-weight: 400;
}

@font-face {
	font-family: "EY-Regular";
	src: url('../assets/fonts/EYInterstate-Bold.woff') format('woff'), url("../assets/fonts/EYInterstate-Bold.ttf") format('truetype');
	font-style: normal;
	font-weight: 700;
}

@font-face {
	font-family: "EY-Regular";
	src: url('../assets/fonts/EYInterstate-BoldItalic.woff') format('woff'), url("../assets/fonts/EYInterstate-BoldItalic.ttf") format('truetype');
	font-style: italic;
	font-weight: 700;
}

.font-bold {
    font-weight: bold;
}

.color-grey {
    color: $grey;
}

.font-italic {
    font-style: italic;
}

.container {
    display: flex;
}
.app-justify-center {
	justify-content: center;
}
.app-justify-between {
	justify-content: space-between;
}
.app-pr-0 {
	padding-right: 0 !important;
}

.page-breadcrumb{
    font-family: $eyInterstateBold;
    font-size: $fontSize12;
    font-weight: bold;
    a {
        color: $tuna;
        text-transform: none;
        text-decoration: none;
    }
}

.btn{
    a{
        text-decoration: none;
    }
}

.create-project{
    height: 100%;
}

.full-height{
    height: 100%;
}

a{
    &.btn{
        text-decoration: none;
    }
}

@mixin app-col {
	box-sizing: border-box;
    flex: 0 0 auto;
    position: relative;
}

.app-row {
	box-sizing: border-box;
    display: flex;
    flex: 0 1 auto;
    flex-direction: row;
    flex-wrap: wrap;
	margin-right: -12px;
	margin-left: -12px;
}

.app-col-lg-12 {
	@include app-col();
    flex-basis: 100%;
    max-width: 100%;
}
.app-col-xs-1 {
	@include app-col();
	flex-basis: 25%;
    max-width: 25%;
}
.app-col-xs-2 {
	@include app-col();
    flex-basis: 50%;
    max-width: 50%;
}

.app-col-xs-4 {
    flex: 0 0 auto;
    flex-basis: 100%;
    max-width: 100%;
}

.app-col-lg-2 {
	@include app-col();
    flex-basis: 16.66667%;
    max-width: 16.66667%;
}

.app-col-lg-4 {
	@include app-col();
    flex-basis: 33.33333%;
    max-width: 33.33333%;
}

.app-col-lg-5 {
	@include app-col();
    flex-basis: 41.66667%;
    max-width: 41.66667%;
}

.app-col-lg-6 {
	@include app-col();
    flex-basis: 50%;
    max-width: 50%;
}

.app-col-lg-7 {
	@include app-col();
    flex-basis: 58.33333%;
    max-width: 58.33333%;
}

.app-col-lg-8 {
	@include app-col();
    flex-basis: 66.66667%;
    max-width: 66.66667%;
}

[class^='app-col-'], [class*='app-col-'] {
    padding-right: 12px;
    padding-left: 12px;
}

@media screen and (min-width: 48em){
	.app-col-md-2 {
		flex: 0 0 auto;
		flex-basis: 25%;
		max-width: 25%;
	}
}

@media screen and (min-width: 48em) {
	.app-col-md-2, .app-col-md-offset-2 {
		box-sizing: border-box;
		position: relative;
	}
}

@media screen and (min-width: 64em){
	.app-col-lg-2 {
		flex: 0 0 auto;
		flex-basis: 16.66667%;
		max-width: 16.66667%;
	}
}

.app-form-helper-icon {
	margin-right: 10px;
}

.tooltip-no-delete {
	display: inherit;
	width: 100%;
}

.do-not-delete {
	color: $thunderbird;
}

@mixin app-headings {
	font-size: $fontSize20;
	color: $secondary_button_grey
}

@mixin primary-button-icon {
	color: $motif-primary-button-icon-color;
	font-size: $fontSize10;
}
