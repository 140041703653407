@import '../../styles/globalStyles.scss';

.app-select {

  .MuiFormLabel-root {
    color: $selected_color !important;
    font-family: $eyInterstateLight;
    font-size: $fontSize16;
  }

  .MuiFilledInput-underline {
    &::before {
      border-bottom: 1px solid $gray !important;
    }
    &::after {
      border-bottom: 1px solid $gray !important;
    }
  }

  .MuiSelect-root {
    background-color: $white;
    color: $gray !important;
    font-size: $fontSize14;

    .option-with-description {
      .option-desc {
        display: none;
      }
    }
  }
  .MuiInputBase-input {
    font-family: $eyInterstateLight;
    background-color: $temo;
    color: $tuna !important;
    font-size: $fontSize15;

    &:hover, &:focus {
      background-color: $mischka;
      border-bottom: 2px;
    }
  }
  .MuiFilledInput-root.Mui-focused {
    background-color: $mischka;
    border-bottom: 2px;
  }
  .Mui-disabled {
    cursor: not-allowed !important;

    &:hover, &:focus {
      background-color: $temo;
    }
    &::before {
      border-bottom: 1px solid !important;
    }
  }
}

.MuiList-root {
  .MuiListItem-root {
    &.app-select-option {
      font-family: $eyInterstateLight;
      font-size: $fontSize14;
      font-weight: 300;
      color: $black;
      min-height: 40px;
      .MuiIconButton-label {
        .MuiSvgIcon-root {
          fill: $black;
        }
      }
    }
  }

  .option-with-description {
    .option-desc {
      font-size: $fontSize10;
      font-weight: bold;
      color: $gray;
    }
  }
}
