@import "../../styles/variables.scss";

.project-usage-reports-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px 50px;

  .page-hdr-title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    .go-back-icon {
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;

      .icon-link {
        font-size: $fontSize12;
        margin-right: 10px;
      }
      span {
        color: $black;
        text-decoration: none;
        font-weight: 600;
        font-size: 12px;
      }
    }
    .page-hdr-title {
      font-size: 20px;
      font-weight: 400;
    }
  }
  #powerBiProjectUsageReport {
    width: 100%;
    height: 100%;

    .project-report-power-bi-embed {
      width: 100%;
      height: 100%;
    }
  }
}
