@import "../../styles/variables.scss";

.filters-dialog {
    .MuiDialog-container{
        justify-content: flex-end;
    }
    .MuiDialog-paperScrollPaper{
        max-width: 400px !important;
        padding: 20px 0px 0;
        position: relative;
    }
    .filters-header{
        padding: 0 30px;
        h5{
            display: flex;
            align-items: center;
            font-size: $fontSize20;
            color: $breadcrumb-color-active;
            font-weight: 400;
            margin: 0 0 4px;
            span{
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: $fontSize12;
                height: 24px;
                min-width: 24px;
                border-radius: 50%;
                border: 1px solid $ey-border-grey;
                margin-left: 10px;
            }
        }
        p{
            font-size: $fontSize14;
            color: $ey-phoenix-light-gray;
            margin: 0;
            font-family: $eyInterstateLight;
        }
    }
    .filter-check-block{
        padding-top: 20px;
        h5{
            display: flex;
            align-items: center;
            font-size: $fontSize18;
            color: $breadcrumb-color-active;
            font-weight: 400;
            margin: 0 0 15px;
        }
        .check-boxes-wrap{
            .check-boxes-head{
                border-bottom: 1px solid $light-border;
                padding-bottom: 5px;
            }
        }
        ul{
            padding: 5px 0;
            margin: 0;
            list-style-type: none;
            ul{
                padding: 0 0 0 20px;
            }
        }
    }
    .filter-body{
        overflow-y: auto;
        max-height: calc(100vh - 145px);
        padding: 5px 30px 10px;
    }
    .filters-footer{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-top: 1px solid $light-border;
        padding: 16px;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }
}
