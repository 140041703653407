@import "../../styles/globalStyles.scss";
@import "../../styles/variables.scss";

.admin-maintenance {
  padding: 30px 50px;
  display: flex;
  flex-direction: column;
  height: 100%;
  .local-time-warning {
    color: $red;
    text-align: center;
    padding: 20px 0;
  }
  .schedule-container {
    background: $white;
    padding: 20px;
  }
  .tool-tip {
    margin-left: 5px;
    .app-icon {
      font-size: $fontSize16;
    }
  }
  .app-tooltip {
    height: auto;
  }

  .banner-msg-title {
    display: flex;
    flex-direction: row;
    justify-self: center;
  }
  .schedule-duration {
    display: flex;
    flex-direction: row;

    .seprator {
      margin-top: 10px;
      margin-left: 8px;
    }
    .app-number-picker {
      margin: -25px 0 0 20px;
    }
  }
  .status-radio-group {
    padding: 15px 0;
    margin-bottom: 25px;
    .status-radio-option {
      label {
        padding: 0 5px;
      }
    }
  }
  .update-section {
    display: flex;
    flex-direction: row;
    align-items: center;

    .app-icon {
      margin: 0 10px;
    }
  }

  .scheduler {
    .add-event {
      font-weight: bold;
      cursor: pointer;

      .plus-icon {
        display: inline-block;
        border-radius: 100%;
        background-color: $turbo;
        padding: 0 8px;
        margin: 0 8px 8px 0;
      }
    }
  }
  .email-section {
    padding: 30px 0 15px;

    .email-buttons {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px 0;
      .email-for-text {
        padding: 0 10px;
      }
    }
  }

  .disableProjectCreation {
    margin-bottom: 1rem;
    .makeStyles-label-26 {
      font-family: $eyInterstateRegular !important;
    }
    .app-checkbox{
      span.MuiTypography-body1{
        text-transform: none !important;
      }
    }
    .MuiFormControlLabel-root.Mui-disabled {
      cursor: not-allowed;
    }
  }
}

.delete-maintenanace-event-modal {
  .MuiDialog-paperFullWidth {
    width: 400px;
  }
}
