@import '../../styles/variables.scss';

.project-workflow-tabs {
    background: $white;
    .go-back-icon {
        .icon-link {
            font-size: $fontSize14;
            margin-right: 5px;
        }

        a {
            color: $black;
            text-decoration: none;
            font-weight: 600;
            font-size: 12px;
            margin-left: -20px;
        }
    }

    .manage-header {
        display: flex;
        justify-content: space-between;
        padding: 18px 50px 0;
        align-items: flex-end;
        background: #ffffff;

        .title-wrapper {
            font-family: $eyInterstateLight;
            margin: 10px 0px 10px 0px;
            width: 100%;

            h2 {
                color: $mine_shaft;
                font-size: $fontSize18;
                font-weight: 600;
                margin: 10px 0 5px;
            }

            h3 {
                color: $gray;
                margin: 0;
                font-weight: 300;
                font-size: $fontSize14;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                width: calc(100% - 10px);
                min-height: 25px;
            }
        }

        .tab-btn {
            color: $tuna;
            font-family: $eyInterstateLight;
            font-size: $fontSize14;
            font-weight: 300;
            padding: 13px 12px 8px;
            border-bottom: 4px solid $white;
        }

        .active-tab {
            border-bottom: 4px solid $tuna;
            color: $tuna;
            font-family: $eyInterstateBold;
            font-weight: bold;
        }
    }

    .project-workflow-list {
        display: flex;
        flex-direction: column;
        padding: 0px 0 15px;

        .nav-menu {
            padding: 24px 50px 14px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            ul {
                padding: 0;
                margin: 0;
            }
        }

        .administration-section {
            padding: 20px 50px;
        }

        .error-log {
            .ag-center-cols-container {
                min-width: 100%;
            }

            .ag-filter {
                min-width: 160px;
            }
        }

        .ag-body-horizontal-scroll-viewport {
            padding-right: 100px;
        }

    }


    .admin-definitions .admin-definitions-table .list.sticky-scroll .ag-body-horizontal-scroll,
    .admin-schedules .admin-schedules-table .list.sticky-scroll .ag-body-horizontal-scroll,
    .admin-executions .admin-executions-table .list.sticky-scroll .ag-body-horizontal-scroll,
    .error-log .error-log-table .list.sticky-scroll .ag-body-horizontal-scroll,
    .admin-communications .admin-communications-table .list.sticky-scroll .ag-body-horizontal-scroll,
    .admin-templates .admin-templates-table .list.sticky-scroll .ag-body-horizontal-scroll {
        padding-left: 0 !important;
    }
}
