@import '../../styles/globalStyles.scss';

.app-loader {
    z-index: 1005 !important;
    height: 100vh;
    position: fixed;
    width: 100vw;
    left: 0;
    top: 0;
    align-items: center;
    background-color: rgba($white, 0.75);
    display: flex;
    justify-content: center;
}
