@import '../../styles/globalStyles.scss';

.project-footer {
  z-index: 1;
  display: flex;
  justify-content: space-between;
  background-color: $whisper;
  box-shadow: 0 0 8px 0 rgba($black, 0.04);
  padding: 19px 50px;
  color: $mine_shaft;

  button {
    font-family: $eyInterstateBold;
    font-size: $fontSize14;
    font-weight: bold;
    min-height: 43px;
    min-width: 0;
  }
  
  .left-btns {
    display: flex;
    flex-direction: row;
    
    .save-btn {
      padding: 0 34px;
      margin-right: 20px;
    }

    .save-btn {
      &.app-button {
        width: 100%;
        &.active{
          background-color: $secondary_button_grey;
          color: $white;
          &:hover {
            background-color: $turbo;
            color: $black;
          }
        }
      }
    }

    .disabled {
      background: $charade_dark;
      color: $mid_gray;
      border: none;
    }

    .btn-disabled {
			color: $placeholder_color;
			background-color: $disabled_background;
			cursor: not-allowed;
		}
    
    .create-btn {
      padding: 0 35px;
      margin-right: 20px;
    }
    .cancel-btn {
      padding: 0 35px;
    }
  }
  .right-btns {
    &:hover, &:focus {
      border: none !important;
    }
  }
  .back-btn {
    margin-right: 60px;
  }
}
