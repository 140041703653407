@import '../../../styles/variables.scss';

.customize-apps {
  padding: 34px 12px 24px 33px;
  color: $tuna;
  font-family: $eyInterstateLight;
  margin-right: -12px;
  display: flex;
  flex-direction: column;
  height: 100%;
  .bottom-btn-app {
    background: linear-gradient(-180deg, rgba(229, 229, 236, 0) 0%, rgb(229, 229, 236) 100%);
    border-radius: 0;
    height: 90px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;

    .add-bn-new {
      position: absolute;
      bottom: 20px;
      left: 0;
      width: 100%;
      padding: 0 32px;
      .app-button {
        width: 100%;
        background-color: $secondary_button_grey;
        color: $white;
        &:hover {
          background-color: $turbo;
          color: $black;
          .app-icon {
            color: $black;
          }
        }
      }
      .app-icon {
        color: $white;
      }
    }
  }
  .float-right {
    float: right;
  }
  .app-count {
    font-size: $fontSize13;
    font-weight: bold;
  }
  .apps-header {
    margin-left: -12px;
    margin-right: -12px;
    max-height: 500px;
    overflow-y: auto;
    .add-bn-new {
      padding: 0 18px 0 0;
      margin-left: -2px;
    }
  }

  .apps-header::-webkit-scrollbar {
    width: 10px;
}

.apps-header::-webkit-scrollbar-track { // sets style to track
    border-radius: 5px;
}

.apps-header::-webkit-scrollbar-thumb { // sets style to thumb
    background: $scarpa_flow;
    border-radius: 5px;
}
  .apps-wrapper {
    margin-top: 24px;
    color: $mid_gray;
    cursor: pointer;
    .app-card {
      margin: 3px 0 20px;
      padding: 0;
    }
    .selectedAppCard {
      .top-section { 
        background-color: $white;
        color: $mid_gray;
        .app-title, .app-icon {
          color: $mid_gray;
        }
        .app-category {
          color: $jumbo;
        }
      }
    }
    .grayedOut {
      opacity: 0.5;
    }
    .top-section {
      width: 100%;
      .more-menu {
        color: $mid_gray;
      }
      .content, .app-title, .app-category, .footer-btns {
        width: 100%;
      }
      .content-data {
        margin-left: 6px;
        width: 100%;
        .app-title {
          margin-bottom: 10px;
          height: 100%;
        }
      }
      .content {
        .icon {
          min-width: 46px;
          min-height: 46px;
        }
      }
    }
  } 
}
