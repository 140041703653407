@import '../../styles/variables.scss';
@import '../../styles/globalStyles.scss';

.app-card {
    position: relative;
    margin: 0 30px 10px 0;
    .pending-state {
        background-color: $jumbo !important;
        .app-title {
            color: $spun_earl !important;
        }
        .app-category {
            color: $mischka !important;
        }
    }
    .not-pending-state {
        cursor: pointer;
    }
    .app-card-body{
        background: $white;
        display: flex;
        flex-direction: column;
        box-shadow: 0 2px 10px 0 rgba($black, 0.05);
        height: 160px;
        width: 244px;
        padding: 15px;
        position: relative;
        .content {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            flex: 1;
    
            .icon {
                min-width: 46px;
                min-height: 46px;
                max-width: 46px;
                max-height: 46px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
            }
    
            .content-data {
                padding-left: 5px;
                width: 100%;
    
                .app-title {
                    color: $scarpa_flow_dark;
                    font-size: $fontSize16;
                    width: 100%;
                    margin-bottom: 10px;
                    @include multiline-ellipsis(2);
                }
                .add-spacing {
                    width: 90%;
                }
                .app-category, .active-status {
                    color: $silver;
                    font-family: $eyInterstateLight;
                    font-size: $fontSize12;
                    font-weight: 300;
                    height: 15px;
                    width: 100%;
                    line-height: 18px;
                }
                .app-category {
                    height: 100%;
                    max-height: 35px;
                    @include multiline-ellipsis(2);
                }
            }
            .content-data-cross {
                padding-left: 20px;
            }
        }
    
        .top-section {
            border-radius: 0;
            display: inline-flex;
            flex-direction: column;
            align-items: flex-end;

            .more-menu {
                position: absolute;
                min-width: 30px;
                min-height: 15px;
                top: 0;
                right: 3px;
                color: $mid_gray;
                fill: currentColor;
            }
            .cross-menu {
                top: 8px;
                min-height: 15px;
                margin-top: -2px;
                margin-bottom: 4px;
                display: flex;
                justify-content: center;
            }
        }            
        
        .demo-btn {
            background: $charade;
            border: none;
            color: $spun_earl;
            font-size: $fontSize12;
            border-radius: 16px;
            padding: 4px 10px;
        }

        .app-status-pill{
            color: $white;
            background-color: $unpublished_bg_color;
            text-align: center;
            width: 90px;
            padding: 0;
            border-radius: 15px;
            font-size: $fontSize11;
        }

        .app-status-pending {
            background-color: $neon_carrot;
            border-radius: 16px;
            color: $tuna_dark;
            font-size: $fontSize11;
            text-align: center;
            width: fit-content;
            padding: 0 12px;
            font-weight: bold;
        }

        .app-status-draft {
            background: $mischka;
            color: $tuna;
        }

        .footer-btns {
            display: flex;
            flex-direction: row;
            align-self: flex-start;
            width: 100%;
            justify-content: space-between;
            &.preview-btn {
                justify-content: flex-end;
            }
            .app-button {
                padding: 8px 12px;
                min-height: auto;
                min-width: auto;
            }
            .active-section {
                display: flex;
                align-items: center;
                
            .active-status {
                color: $jumbo;
                font-family: $eyInterstateLight;
                font-size: $fontSize12;
                font-weight: 300;
                padding: 0 8px;
            }
        }
        }
    }

    .app-card-body-cross {
        height: 140px;
        padding: 30px 35px 25px 25px;
    }

    .app-description {
        color: $scarpa_flow_dark;
        font-size: $fontSize12;
        font-family: $eyInterstateLight;
        font-weight: 300;
        letter-spacing: 0;
        line-height: 18px;
        background-color: inherit;
        padding: 2px;
        width: 217px;

        .content {
            .show-details-btn {
                font-weight: bold;
                text-decoration: underline;
                cursor: pointer;
                margin-left: 5px;
            }
        }
        .details {
            white-space: initial;
            @include multiline-ellipsis(2);
        }
    }
 
    .data-location-tooltip {
        .app-tooltip {
            width: fit-content;
            height: fit-content;
        }
    }
}
  
  @keyframes shine {
    to {
      background-position: 100% 0;
    }
  }

.demo-video-dialog {
    z-index: 10001;

    .app-modal {
        overflow: visible;
    }
}

.new-app-card {
    position: relative;
    font-family: $eyInterstateRegularNew;
    .card {
        width: 100%;
    }
    .card-header {
        display: flex;
        justify-content: space-between;
        & > div{width: 100%;}
        .app-title-space {
            width: calc(100% - 24px);
        }
        .app-more-menu-space {
            width: 24px;
        }
    }
    .menu-option {
        .more-menu {
            position: relative !important;
        }
    }
    .card-title {
        max-width: 95%;
        font-size: $fontSize16;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        margin: 0;
        display: inline-block;
        @include singleline-ellipsis;
        span{display: inline-block;}
    }

    .card-description {
        margin: 10px 0 16px;
        font-size: $fontSize14;
        font-weight: 300;
        line-height: 20px;
        color: $ey-phoenix-light-gray;
        min-height: 40px;
        font-family: $eyInterstateLight;
        @include multiline-ellipsis(2);
      }

    .card-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .app-hot-link {
            height: 36px;
            .app-button {
                border: 1px solid $ey-phoenix-light-gray-2;
                font-size: $fontSize14;
                font-weight: 400;
                line-height: 24px;
                padding: 8px 14px 8px 14px;
                height: 36px;
                color: $secondary_button_grey;
                &:hover {
                    background-color: $light-border;
                }
            }
        }
    }

    .status-pill {
        padding: 3px 8px 3px 8px;
        border-radius: 16px;
        font-size: $fontSize12;
        font-weight: 400;
        line-height: 18px;
        text-align: center;
        color: $ey-phoenix-light-gray;
        &.restricted {
            background: $light-red-0001;
            color: $ey-phoenix-red-1;
        }
        &.default-pill {
            border: 1px solid $ey-border-grey;
        }
    }
    .new-app-card-container {
        display: flex;
        padding: 16px;
        border-radius: 4px;
        border: 1px solid $light-border;
        background: $white;
    }
    .card-show-thumbnail {
        .card {
            .video-play-button {
                position: absolute;
                top: 25%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: transparent;
                border: none;
                cursor: pointer;
                img {
                  width: 50px;
                  height: auto;
                }
            
                &:focus {
                  outline: none;
                }
            }
            .card-description {
                margin-top: 10px;
            }
            .app-thumbnail {
                margin-bottom: 5px;
                min-height: 132px;
                .img-app-card {
                    width: 300px;
                    height: 124px;
                }
            }
        }
        
    }
    .not-pending-state {
        cursor: pointer;
    }
}
