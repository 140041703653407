@import '../../styles/variables';

.app-tooltip {
    &.app-tooltip-container {
        display: inherit;
        width: 100%;
    }
}

.MuiTooltip-tooltip {
    box-shadow: 0 0 8px 2px rgba($header_admin_menu, 0.15);
}

.tooltip-icon{
    height: 16px;
    width: 16px;;
}
