@import '../../styles/variables.scss';
@import '../../styles/globalStyles.scss';

.admin-regions-modal-content {
  min-height: 300px;

  .MuiInputBase-root.Mui-disabled {
    background-color: $dropdown_form_control_bg;
  }
  .admin-regions-modal-textarea {
    margin: 15px 0;
  }

  .email-recipients-title {
    font-size: $fontSize13;
    margin: 10px 0 5px;
  }

  @import '../../components/editableGrid/editor/_pillEditor.scss';
  @include search-wrapper();
  .admin-region-pill-editor{
  --pill-editor-pill-padding: 5px 15px 5px 0;
  --pill-editor-min-height: 150px;

    .form-control {
        padding: 0;
        background-color: $white;
        border: 0;
        height: auto;
      }
      .dropdown-menu {
        max-height: 100px !important;
        background-color: $athens_gray;
      }
 }
}