@import '../../../styles/variables.scss';

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.column {
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-basis: 100%;
}

.single-column {
    max-width: 25%;
    overflow: hidden;
}
.triple-column {
    display: flex;
    flex-direction: column;
    flex: 3;
    flex-basis: 100%;
    max-width: 75%;
}

.step2 {
    flex: 1;
    .page-top {
        display: flex;
        flex-direction: row;
        background-color: $temo;
        padding: 20px;

        .eng-data {
            padding: 10px 0 0 20px;
        }

        .bundle-section {
            display: flex;
            flex-direction: column;
            background: transparent;

            .step2-header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                background: transparent;
                padding: 10px 20px 15px 0;
                color: $tuna;
                height: auto;

                .select-label {
                    font-size: $fontSize16;
                    font-weight: 300;
                    height: 21px;
                    font-family: $eyInterstateLight;
                    margin: 0 2px 10px;
                }

                .bundle-info {
                    display: flex;

                    .bundle-count {
                        font-size: $fontSize12;
                        font-weight: lighter;
                        padding-right: 10px;
                    }

                    .app-button {
                        margin-top: -13px;
                    }
                }
            }

            .bundle-wrapper {
                display: flex;
                overflow-x: auto;
                padding-bottom: 15px;
            }

            .bundle-wrapper::-webkit-scrollbar {
                width: 1px;
                height: 7px;
            }
    
            .bundle-wrapper::-webkit-scrollbar-track { // sets style to track
                border-radius: 5px;
            }
    
            .bundle-wrapper::-webkit-scrollbar-thumb { // sets style to thumb
                background: $scarpa_flow;
                border-radius: 5px;
            }

            .bundle-wrapper > * {
                flex: 1 1 15%;
            }
        }
    }

    .page-mid {
        display: flex;
        flex-direction: row;
        background-color: $silver;

        .bundle-wrapper {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }

        .bundle-wrapper > * {
            flex: 1 1 15%;
        }
    }

    .page-bottom {
        display: flex;
        flex-direction: row;
        background-color: $athens_gray1;
        padding: 20px 5px 20px 15px;

        .app-wrapper {
            display: flex;
            overflow-x: auto;
        }

        .app-wrapper::-webkit-scrollbar {
            width: 1px;
            height: 7px;
        }

        .app-wrapper::-webkit-scrollbar-track { // sets style to track
            border-radius: 5px;
        }

        .app-wrapper::-webkit-scrollbar-thumb { // sets style to thumb
            background: $scarpa_flow;
            border-radius: 5px;
        }
    }
}

.bottom-arrow {
    content: '';
    position: absolute;
    border-top: 13px solid $temo;
    border-left: 18px solid transparent;
    border-right: 18px solid transparent;
}

.arrow-box {
    width: 100%;
    background: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    position:relative;
}

.own-bundle-desc {
    .MuiFormLabel-root, .MuiInputLabel-shrink, .Mui-focused {
        font-size: $fontSize17;
      }
    .MuiInputBase-input {
        font-size: $fontSize16;
      }
}