@import "../../styles/globalStyles.scss";

.privacy-policy {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  display: flex;
  align-items: center;
  padding: 1% 0;
  justify-content: flex-start;

  .privacy-policy-conatiner {
    width: 85%;
    max-height: 550px;
    max-width: 1400px;
    padding-bottom: 1%;
  }
}
