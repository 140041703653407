@import '../../styles/variables.scss';

.admin-projects-apps-modal {
  .ag-theme-material {
    .ag-layout-auto-height .ag-center-cols-clipper, 
    .ag-layout-auto-height .ag-center-cols-container, 
    .ag-layout-print .ag-center-cols-clipper, 
    .ag-layout-print .ag-center-cols-container {
      min-height: auto;
    }
  }
  
  .ag-body-viewport {
    min-height: auto;
  }
  .MuiPaper-root {
    min-width: 90%;
    max-height: 90%;
  }

  .apps-play-button {
    margin-right: 10px;
  }
  .apps-copy-button, .apps-play-button {
		&:hover {
			text-decoration: none;
			border: 0
		}
		&:focus {
			border: 0;
			box-shadow: none;
		}
  }
  .category-color {
		display: inline-block;
		min-width: 10px;
		height: 10px;
		border-radius: 100%;
		margin-right: 5px;
  }
  .category-title {
		max-width: 70%;
	}

  .owner-count-tooltip {
		width: auto;
	}
  
  .apps-owner-button {
		text-decoration: none;
		color: $link_blue;
    max-width: 180px;
		display: inline-block;
		overflow: hidden;
		text-overflow: ellipsis;
  }
  .owner-count {
    background-color: $light_blue;
    font-size: $fontSize10;
    letter-spacing: 0;
    text-align: center;
    color: $white;
    margin-left: 5px;
    border-radius: 10px;
    max-width: 30px;
    width: 30px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .link-copied-notification {
		position: fixed;
		left: 50%;
		bottom: 30px;
		padding: 5px 15px;
		background-color: $white;
		color: $emerald;
		border-radius: 15px;
		box-shadow: 0 0 15px 0 rgba($black, 0.08);
		transform: translate(-50%, -50%);
  }
  .project-app-table {

    .app-table {
      .ag-cell-value {
        overflow: hidden!important;
      }
    }

    .ag-header-cell {
      pointer-events: none;
    }
    .ag-header-cell-sortable {
      pointer-events: initial;
    }
    .ag-center-cols-clipper {
      overflow: hidden;
    }
    &.app-sticky-scroll {
      position: relative;
      .ag-body-horizontal-scroll {
        position: fixed;
        z-index: 999;
        width: 90%;
      }
      .ag-center-cols-viewport {
        overflow-x: hidden;
      }
      .ag-body-horizontal-scroll-viewport {
        overflow-x: auto;
      }
    }
  }
  .ag-body-horizontal-scroll {
    position: fixed;
    bottom: 0;
    padding: 0 50px;
    left: 0;
    z-index: 999;
    width: 100%;
  }
  .ag-react-container
  {   
   width: 240px;
  }
  .status-pill {
    color: $white;
    background-color: $unpublished_bg_color;
    text-align: center;
    width: 90px;
    padding: 0;
    border-radius: 15px;
    font-size: $fontSize11;
  }
  .pending-state {
    background-color: $neon_carrot;
  }
  .draft-state {
    background-color: $mischka;
    color: $black;
  }
  .ready-state {
    background-color: $emerald;
  }
  .error-state {
    background-color: $thunderbird;
  }
  .MuiButton-text {
		color: $denim;
		cursor: pointer;
    }

    [col-id='noOfUsers'] .app-table-cell-renderer {
      padding: 0 5px;

      .MuiButton-text {
        min-width: auto;
        padding: 0;
      }
  }
}
