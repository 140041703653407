@import "../../styles/variables.scss";

.label-icon-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: 0;
  background: transparent;
  box-shadow: none;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  font-family: $eyInterstateRegular;
  svg{font-size: $fontSize16;}
  span {
    padding-Left: 8px;
    color: $secondary_button_grey;
    font-size: $fontSize16;
    font-weight: 400;
  }
  &:disabled{
    cursor: default;
    opacity: 0.6;
  }
}
