@import "../../styles/globalStyles.scss";
@import "../../styles/variables.scss";
.assistant-flyout {
  .assistant-button {
    cursor: pointer;
    position: fixed;
    top: calc(50vh - 22px);
    right: -35px;
    width: 117px;
    height: 44px;
    background-color: #3a3a4a;
    transform: rotate(-90deg);
    z-index: 2;
    display: inline-flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 2px 2px 0 0;
    border: 1px solid #3a3a4a;
    &.disabled {
      opacity: 0.8;
      cursor: not-allowed;
    }

    .flyout-button-text {
      color: #fff;
      font-size: 16px;
    }
  }
  .flyout-button:hover {
    cursor: pointer;
  }
}

.drawer {
  transition: all 0.3s ease;
  .ml-auto {
    margin-left: auto;
    display: flex;
    justify-content: center;
    cursor: pointer;
    .icon {
      margin: 10px;
    }
    .icon-close {
      margin-top: 16px;
    }
  }
  .assistant {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    .header-title {
      font-family: $eyInterstateLightNew;
      .title {
        display: flex;
        flex-direction: row;
        font-size: 20px;
        font-weight: 400;
        color: $header_admin_menu_active;
        margin: 0;
        align-items: center;
        .beta-logo {
          background-color: $turbo;
          font-family: $eyInterstateRegularNew;
          font-weight: 400;
          border-radius: 16px;
          margin-left: 10px;
          padding: 2px 12px 2px 12px;
          font-size: 13px;
        }
        .help-tooltip-icon {
          margin: 5px 0 0 10px;
          position: relative;
          svg {
            path {
              stroke: $ey-tooltip-grey;
              transition: stroke 0.3s;
            }

            &:hover {
              path {
                stroke: $placeholder_color;
              }
            }
          }
          .custom-tooltip {
            position: absolute;
            background-color: $white;
            color: $secondary_button_grey;
            font-family: $eyInterstateLightNew;
            padding: 5px 10px;
            border: 1px solid $shadow-grey;
            border-radius: 8px;
            z-index: 1000;
            top: 50%;
            left: 100%;
            width: 269px;
            white-space: normal;
            transform: translateY(-50%);
            margin: 35px 0 0 7px;
            font-size: 12px;
            pointer-events: none;
            line-height: 1.5;
          }
        }
      }

      .subtitle {
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        color: $placeholder_color;
        margin: 0;
      }
    }
  }

  .drawerPaper {
    padding: 20px;
    width: 50vw; // Initial width
    transition: all 0.3s ease; // Transition for width change
  }
  .drawerPaper-fullWidth {
    width: 100vw;
    padding: 20px;
    transition: all 0.3s ease; // Transition for width change
  }

  .drawerOpen .MuiPaper-root {
    transform: translateX(0);
    transition: all 0.3s ease;
  }

  .drawerClose .MuiPaper-root {
    transform: translateX(100%);
    transition: all 0.3s ease;
  }
}
