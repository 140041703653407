@import '../../styles/globalStyles.scss';

.admin-project-types {
	padding: 30px 50px;

	.page-hdr-title-container{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 10px;
	}

	.project-types-table {

		.ag-theme-material .ag-ltr .ag-cell {
			overflow: hidden !important;
		}
	}
}

.edit-modal-content {
	.type-name-key {
		display:flex;

		div:nth-child(1) {
			min-width: 60%;
			margin-right: 40px;
		}
		div:nth-child(2) {
			min-width: calc(40% - 40px);
		}
	}
	.type-desc {
		min-width: 700px;
	}
}
