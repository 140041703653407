@import "../../styles/globalStyles.scss";

.project-card {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  background: $white;
  color: $scarpa_flow_dark;
  min-height: 135px;
  width: 288px;
  border-radius: 5px;
  border: 1px solid $ey-border-grey;
  box-shadow: 0 2px 10px 0 rgba($black, 0.05);
  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 10px 0 rgba($black, 0.3);
  }

  &.remove-cursor {
    cursor: default;
  }
  @media only screen and (min-width: 1685px) and (max-width: 1865px)  {
    width: 308px;
  }
  @media only screen and (min-width: 1300px) and (max-width: 1559px)  {
    width: 308px;
  }
  @media only screen and (min-width: 1024px) and (max-width: 1253px)  {
    width: 308px;
  }

  .project-title {
    font-size: $fontSize16;
    line-height: 1.2;
    padding: 12px 16px 0;
    width: 100%;
    color: $secondary_button_grey;

    .project-n-sector {
      .project-header-container {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .project-name-container {
          display: flex;
          justify-content: space-between;

          .ellipses-1-lines {
            max-width: 180px;
            overflow: hidden;
            display: -webkit-box;
            line-clamp: 1;
            -webkit-line-clamp: 1;
            overflow-wrap: break-word;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
  }

  .project-card-sectors {
    font-family: "EY-Light";
    font-size: $fontSize13;
    color: $jumbo;
    padding: 5px 0;
    font-weight: 300;
  }

  .project-content-buttons {
    display: flex;
    align-items: center;
    margin: 8px 0 10px;

    .project-card-category-name {
      border: 1px solid $ey-border-grey;
      border-radius: 2px;
      padding: 3px 5px;
      display: inline-flex;
      align-items: center;
      font-size: $fontSize12;
    }

    .project-card-restriction {
      text-align: center;
      padding: 3px 5px;
      border-radius: 2px;
      font-size: $fontSize12;
      display: inline-flex;
      line-height: 13px;
      border: 1px solid $ey-border-grey;
      align-items: center;
      margin-left: 10px;

      .project-card-restriction-icon {
        font-size: $fontSize13;
        margin-right: 5px;
      }
    }

    .demo-btn {
      border: 1px solid $ey-border-grey;
      padding: 3px 5px;
      color: $ey-link-blue;
      font-size: $fontSize12;
      border-radius: 16px;
      cursor: pointer;
      line-height: 1.4;

      &:hover {
        background: $athens_gray_light;
      }
    }
  }

  .more-options {
    font-size: $fontSize20;
    letter-spacing: -1px;
  }

  .draft-btn {
    background: $mischka;
    border: none;
    color: $tuna;
    font-size: $fontSize12;
    border-radius: 16px;
    padding: 4px 10px;
    max-height: 22px;
    cursor: pointer;
    margin-bottom: 12px;
  }

  .footer-btns {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    a {
      text-decoration: none;
    }

    .footer-btn-demo {
      min-width: 60px;
      min-height: 28px;
    }

    .right-arrow {
      text-align: center;
      margin-bottom: 4px;
    }

    .top-card-menu {
      align-self: flex-end;
      min-height: 20px;
      .more-menu {
        .MuiButtonBase-root {
          color: $black;
        }
      }
    }
  }

  .app-tooltip {
    display: inline;
    height: auto;
    width: auto;
  }

  .modal-content {
    font-family: $eyInterstateRegular;
    color: $black;
    font-size: $fontSize14;
    margin-bottom: 30px;
    min-width: 400px;
    font-weight: bold;

    .sub-text {
      font-weight: 300;
      font-family: $eyInterstateLight;
    }
  }

  .project-card-footer {
    display: flex;
    width: 100%;
    padding: 3px 15px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: $tuna;
    font-size: $fontSize12;
    border-radius: 0 0 10px 10px;
    border-top: 1px solid $ey-border-grey;

    .project-created-date {
      font-family: "EY-Light";
      font-style: italic;
      color: $jumbo;
    }

    .project-card-confidential-icon {
      font-size: $fontSize14;
      margin-right: 5px;
      color: $tuna;
    }

    .draft-project-label {
      margin-right: 10px;
      text-transform: capitalize;
      color: $ey-phoenix-light-gray;
      padding: 3px 8px 3px 8px;
      border-radius: 16px;
      border: 1px solid $ey-border-grey;
      background-color: $white;
    }
  }

  &.draft {
    background-color: $light-red-0003;
  }
}

.demo-video-dialog .app-modal {
  overflow: visible;
}
