@import '../../styles/globalStyles.scss';

.project-footer-container {
  background-color: $white;
  padding: 20px 32px;
  
  .footer-action-panel {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  
    .app-button {
      margin-right: 12px;

      &:last-child {
        margin-right: 0;
      }

      .next-btn-icon {
        @include primary-button-icon;
        margin-left: 5px;
      }
    }
  }
}
