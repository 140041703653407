@import "../../styles/globalStyles.scss";

.project-announcements {
    z-index: 1002 !important;

    .no-announcement {
        text-align: center;
        margin-top: 35%;
        font-size: $fontSize13;
    }

    .announcement-list {
        height: calc(100vh - 130px);
        overflow-y: auto;

        .infinite-scroll-component {
            margin-right: 12px;
        }

        .isLoading {
            text-align: center;
        }
    }

    .drawer-paper {
        padding: 20px;
        width: 40vw;
        transition: all 0.3s ease;
        background-color: $ey-dull-background;
        border-radius: 8px;
    }

    .announcement-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .announcement-label {
            font-family: $eyInterstateRegularNew;
            font-size: $fontSize20;
            font-weight: 400;
            line-height: 20px;
            color: $secondary_button_grey;
        }

        .create-announcement {
            .close-button {
                margin-left: 12px;
                cursor: pointer;
                color: $ey-phoenix-light-gray;
                font-size: inherit;
            }
        }
    }

    .divider-line {
        border-bottom: 1px solid $light-border;
        margin-bottom: 24px;
    }
    .create-announcement-btn {
        .ann-btn {
            display: inline-block;
            margin-bottom: 4px;
            .label-icon-wrapper {
                color: $ey-link-blue;
                span {
                    color: $ey-link-blue;
                    font-family: $eyInterstateRegularNew;
                    font-size: $fontSize18;
                    font-weight: 400;
                    line-height: 28px;
                }
            }
        }
    }
}

.delete-ann-model {
    .MuiDialog-paper {
        padding: 24px;
    }

    .delete-model-content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            margin: 0;
            font-family: $eyInterstateLightNew;
            font-size: $fontSize18;
            font-weight: 300;
            line-height: 28px;
            color: $secondary_button_grey;
        }

        .delete-close-model {
            padding-top: 10px;
        }
    }

    .MuiDialogContent-root {
        min-height: auto;
        padding: 0;
        padding-bottom: 24px;
        padding-top: 0px !important;
    }

    .MuiDialogActions-root,
    .footer-container {
        padding: 0;
    }
}

.create-update-ann-modal {
    .announcement-form {
        .input-row {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 16px;
            .label {
                font-family: $eyInterstateRegularNew;
                font-size: $fontSize14;
                font-weight: 400;
                line-height: 20px;
                text-align: left;
                color: $ey-phoenix-light-gray;
            }

            .input-field {
                flex: 1;
                padding-right: 8px;

                &:last-child {
                    padding-right: 0;
                }
            }

            .text-area-field {
                width: 100%;
            }
        }
    }
}