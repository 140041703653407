@import "../../styles/variables.scss";

.emg-policy {
  width: 862px;
  margin: auto;
  .accept-terms {
    margin-bottom: 20px;
    .agree-check-box {
      margin-top: -15px;
    }
  }
  .emg-content {
    min-height: 400px;
    padding: 0 16px 0 16px;
    font-family: $eyInterstateLight !important;
    color: $ey-phoenix-light-gray;
    font-size: $fontSize14;
    p,
    a,
    span,
    div {
      font-family: $eyInterstateLight !important;
      color: $ey-phoenix-light-gray !important;
      font-size: $fontSize14 !important;
    }
    a {
      color: $link_blue !important;
    }
  }
}
