@import '../../styles/variables.scss';
@import '../../styles/globalStyles.scss';

.app-scheduler{
    background-color: $dropdown_form_control_bg;
    .rbc-event, .rbc-day-slot .rbc-background-event  {
        background-color: $turbo;
        color: $black;
    }
    .rbc-event-label {
        display: none;
    }
    .rbc-today {
        background-color: $selec-react-hover-bg-color;
    }
    .rbc-month-header {
        background-color: $dropdown_form_control_border;
    }

    .rbc-toolbar {
        color: $white;
        background-color: $tuna;
        padding: 4px;
        margin-bottom: 0;

        .rbc-btn-group {
            button {
                color: $white;
                border: 0;
                cursor: pointer;
                padding: 5px 8px;
                border-radius: 0 !important;
                border-bottom: 3px solid transparent; 

                &:hover {
                    background-color: inherit;
                    color: inherit
                }
                &.rbc-active,  &:active , &:focus {
                    background-color: inherit;
                    color: inherit;
                    border-bottom: 3px solid $turbo; 
                }
            }
        }
    }

    .event-component {
      display: flex;
      justify-content: space-between;

      .event-title {
         display: inline-block;
         width: 85%;
         text-overflow: ellipsis;
         overflow: hidden;
      }
      .close-button {
          visibility: hidden;
          min-width: 10px;
          padding: 0;
      }

      &:hover {
        .close-button {
            visibility: visible;
        }
      }
    }

    .rbc-agenda-table {
        tbody {
            tr {
               .rbc-agenda-time-cell {
                   border-left: 1px solid $mischka;
               }
            }
        }
    }

    .rbc-day-slot {
        .event-title {
           @include multiline-ellipsis(1);
         }
    }
}