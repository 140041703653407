@import "../../styles/globalStyles.scss";

.motif-input {
   &.in-valid {
    .MuiOutlinedInput-notchedOutline {
      border-color: $thunderbird; // Red border for invalid state
      border-width: 1px; // Increase border width if needed
    }

    .MuiInputAdornment-positionStart,
    .MuiInputAdornment-positionEnd {
      color: $thunderbird; // Change color for invalid state
    }
    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: $thunderbird;
    }
  }

  .MuiOutlinedInput-adornedStart {
    background: $white;

    &:hover {
      background-color: $disabled_text_color;
    }

    .MuiInputAdornment-positionStart,
    .MuiInputAdornment-positionEnd {
      height: auto;
      margin: 0;
      color: $breadcrumb-color;
    }
  }

  .MuiInputBase-input {
    font-family: $eyInterstateLight;
    background-color: $white;
    color: $ey-phoenix-light-gray !important;
    font-size: $fontSize15;
    padding: 10px 10px;

    &:hover,
    &:focus {
      background-color: $disabled_text_color;
    }

    &::-webkit-input-placeholder {
      color: $ey-phoenix-light-gray;
      opacity: 0.8;
    }

    &::-moz-placeholder {
      color: $ey-phoenix-light-gray;
      opacity: 0.8;
    }

    &::-ms-placeholder {
      color: $ey-phoenix-light-gray;
      opacity: 0.8;
    }
  }

  .MuiOutlinedInput-root {
    color: $ey-phoenix-light-gray;
    padding-right: 0;
  .MuiInputAdornment-positionEnd{
    position: absolute;
    right: 7px;
  }
    &.Mui-focused fieldset {
      border-color: $ey-phoenix-light-gray-2;
      border-width: 1px;
    }

    &:hover fieldset {
      border-color: $ey-phoenix-light-gray-2;
    }
  }

  .Mui-focused {
    &.MuiOutlinedInput-adornedStart,
    .MuiInputAdornment-positionEnd {
      background: $disabled_text_color;
    }
  }

  .Mui-disabled {
    background-color: $disabled_text_color;
    cursor: not-allowed;

    .MuiInputBase-input {
      color: $breadcrumb-color !important;
    }

    fieldset {
      border-width: 0px;
    }

    &.MuiOutlinedInput-adornedStart,
    .MuiInputAdornment-positionEnd {
      svg {
        color: $ey-phoenix-light-gray;
      }
    }
  }

  &.motif-search-box {
    .MuiOutlinedInput-root {
      &.Mui-focused fieldset {
        border-color: $motif-searchbox-outline;
        border-width: 2px;
      }
    }

    .MuiInputBase-input {
      &:focus {
        background-color: $white;
      }
    }

    .MuiOutlinedInput-adornedStart {
      &.Mui-focused {
        background-color: $white;
      }
    }
  }
}
