@import '../../styles/globalStyles.scss';

footer {
	display: flex;
	background: $white;
	border-top: 1px solid $light-border;

	.content {
		display: flex;
		margin: 13px 24px;
		color: $charade;
		font-size: $fontSize8;
		line-height: 18px;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		
		.footer-title {
			margin-right: 20px;
		}
		.anchor-link {
			flex: 0 0 auto;
			font-weight: bold;
			margin-right: 20px;
			font-size: $fontSize10;

			&:last-child {
				margin-right: 0;
			}
			a {
				color: inherit;
				text-decoration: none;
			}
		}
	}
}

body.with-sticky-footer footer {
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 10;
}
