@import '../../../styles/globalStyles.scss';

.admin-portal-survey {
	.mail {
			color: $denim;
			border-color: $denim;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	.add-btn {
		margin-bottom: 10px;
		.add-btn-icon {
			background-color: $turbo;
			padding: 9px;
			.add-icon {
				font-size: $fontSize15;
			}
		}
		.label-icon {
			font-weight: bold;
			margin-left: 10px;
			cursor: pointer;
			text-transform: uppercase;
			color: $grey;
			font-size: $fontSize14;
		}
		.label-disable {
			cursor: not-allowed;
			opacity: .6;
		}
	}
    .menuPaper{
        max-height: 170px !important;
    }
    .admin-portal-survey-table {
		.ag-theme-material .ag-header-cell-label .ag-header-cell-text {
			text-overflow: clip;
			overflow: visible;
			white-space: normal;
			height: auto;
		}
		.ag-cell-wrapper {
			.app-table-cell-renderer {
				width: 100%;
				height: 100%;
				overflow: auto;
				max-height: 55px;
			}
		}
		.ag-theme-material .ag-ltr .ag-cell:not(.ag-cell-last-left-pinned) {
			overflow: hidden !important;
		}
		.ag-body-horizontal-scroll .ag-horizontal-left-spacer, .ag-body-horizontal-scroll .ag-horizontal-right-spacer {
			display: none;
		}
		.ag-body-horizontal-scroll-viewport {
			padding-right: 40px;
		}

		&.sticky-scroll {
			position: relative;
			.ag-body-horizontal-scroll {
				position: fixed;
				bottom: 0;
				padding: 0 50px;
				left: 0;
				z-index: 999;
				width: 100%;
			}
		}
		.list.sticky-scroll .ag-body-horizontal-scroll {
			padding-left: 285px;
		}
	}
	.async-dropdown {
		display: flex;
		.async-dropdown-name {
			border-right: 1px solid $search_dropdown_border;
		}
		div {
			flex: 1;
			align-self: flex-start;
			padding: .6rem 1.5rem;
		}
		.async-description {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	.deleteModal {
		.red-highlighted {
			text-transform: uppercase;
		}
		.app-modal-delete-body {
			font-weight: normal;
		}
	}
	@include search-wrapper();
}
