@import '../../styles/globalStyles.scss';

.admin-projects {
	padding: 30px 50px;

	.project-list-heading {
		font-size: $fontSize18;
	}

	.title-section {
		display: flex;
		justify-content: space-between;
		margin-bottom: 20px;
	}

	.download-icon {
		height: 45px;

		svg {
			width: 45px;
			height: 45px;
		}
	}

	.download-success {
		color: $white !important;
		background-color: $special-green !important;
	}
	  
	.download-failure {
		color: $white !important;
		background-color: $special-red !important;
	}

	[col-id='apps'] .MuiButton-text,
	[col-id='name'] .MuiButton-text,
	[col-id='noOfUsers'] .MuiButton-text,
	[col-id='isCustomized'] .MuiButton-text
	 {
		color: $denim;
		text-decoration: none;
		font-weight: 600;
		min-width: auto;
		padding: 0;

		&:hover {
			text-decoration: underline;
		}
	}

	[col-id='isCustomized'] .MuiButton-text {
		padding: 0 15px;
	}
	.project-table {
		.status-pill {
			color: $white;
			background-color: $unpublished_bg_color;
			text-align: center;
			width: 90px;
			padding: 0;
			border-radius: 15px;
			font-size: $fontSize11;
		}
		.pending-state {
			background-color: $neon_carrot;
		}
		.draft-state {
			background-color: $mischka;
			color: $black;
		}
		.ready-state {
			background-color: $emerald;
		}
		.softdelete-state {
			background-color: $turbo;
			color: $black;
		}
		.soft-closed-italica {
			font-style: italic;

			.MuiButton-label, .app-table-cell-renderer {
				font-style: italic;
				padding-right: 0.3rem;
			}
		}
		.error-state {
			background-color: $thunderbird;
		}
		.view-log-btn {
			margin-right: 10px;
		}
		.view-log-btn, .delete-btn {
			text-decoration: none !important;
			font-weight: bold;
		}
		.do-not-delete-desc {
			color: $thunderbird;
		}
		.mail {
			color: $denim;
			border-color: $denim;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
		.ag-theme-material .ag-ltr .ag-cell {
			overflow: hidden !important;
		}
		.ag-pinned-right-header {
			width: 198px !important;
			max-width: 198px !important;
			min-width: 198px !important;
		}
		.ag-theme-material .ag-pinned-right-header {
			border-right: solid 1px $grid_table_border_color;
			border-left: none;
		}
		.ag-theme-material {
			.ag-horizontal-right-spacer:not(.ag-scroller-corner) {
				width: 199px !important;
				max-width: 199px !important;
				min-width: 199px !important;
			}
		}

		.app-table-cell-renderer {
			.do-not-delete-btn {
				color: $thunderbird;
			}
		}
		.ag-center-cols-viewport {
			overflow-y: visible;
		}
	}
	.projects-table {
		&.sticky-scroll {
			position: relative;
			.ag-body-horizontal-scroll {
				position: fixed;
				bottom: 0;
				padding: 0 50px;
				left: 0;
				z-index: 999;
				width: 100%;
			}
		}

		.ag-theme-material .ag-row-last:not(.ag-row-first) .ag-cell-inline-editing {
			bottom: unset;
		}

		.bottom-expiration-cell {
			bottom: 0 !important;
		}
	}
	.app-table {
		.ag-theme-material .ag-ltr .ag-cell:not(.ag-cell-last-left-pinned) {
			overflow: hidden !important;
		}
		.ag-body-horizontal-scroll .ag-horizontal-left-spacer, .ag-body-horizontal-scroll .ag-horizontal-right-spacer {
			display: none;
		}
		.ag-body-horizontal-scroll-viewport {
			padding-right: 40px;
		}
		
		&.sticky-scroll {
			position: relative;
			.ag-body-horizontal-scroll {
				position: fixed;
				bottom: 0;
				padding: 0 50px;
				left: 0;
				z-index: 999;
				width: 100%;
			}
		}
	}

	div[col-id="additionalPaceIds"]:has(.additional-paceIds-popup-container) {
		min-height: 215px !important; // Increased min-height to accommodate header
		width: 265px !important; // Adjusted width

		/* Nullify all styles for ag-cell-wrapper */
		.ag-cell-wrapper {
			all: unset;
		}
	}

		.list-date-container{
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			overflow: visible;
			white-space: normal;	
		}

	// Define a mixin to hide the parent div
	@mixin hide-parent-if-child-present($parent, $child) {
		#{$parent}[col-id="additionalPaceIds"]:has(#{$child}) {
			display: none;
		}
	}

	@include hide-parent-if-child-present('div', '#emptyDiv');

	
	.async-dropdown {
		padding: 5px 8px;
		width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		.async-dropdown-name {
			border-right: 1px solid $search_dropdown_border;
		}
		div {
			flex: 1;
			align-self: flex-start;
			padding: .6rem 1.5rem;
		}
	}
	@include search-wrapper();
	.search-wrapper .form-control {
        width: 500px;
      } 

  .additional-paceIds-popup-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0.5rem;
    background-color: #fafafa;

    .additional-paceIds-pill-editor {
      --pill-editor-width: 239px;
      --pill-editor-max-width: 250px;
      --pill-editor-pill-width: 115px;
      --pill-editor-pill-padding: 5px 15px 5px 15px;
      --pill-editor-min-height: 100px;
      --pill-editor-search-wrapper-width: 110px;

      .form-control {
        border-radius: 20px !important;
        font-size: 0.7rem !important;
		padding: 0 !important;
        padding-block: 10px !important;
        padding-inline-end: 20px !important;
        background-color: $white;
        border: 0 !important;
        height: 100% !important;
      }

      .dropdown-menu {
        max-height: 100px !important;
        height: 100px;
        background-color: $athens_gray;
        width: 130px;
        a{
          font-size: 0.7rem;
          padding-inline-start: 0.4rem;
	      padding-block: 0.2rem;
          .async-dropdown-name{
			padding: 0;
            border: 0;
          }
        }
      }
    }
  }
}
