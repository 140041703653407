@import "../../styles/variables.scss";

.app-sort-wrapper {
  position: relative;
  background-color: $white;

  &:hover,
  &:focus {
    background-color: $disabled_text_color;
  }

  img {
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 11;
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiInputBase-root {
    background-color: transparent !important;
    color: $selected_color !important;
    font-family: $eyInterstateRegular;
    font-size: $fontSize14;
    height: 38px;

    &.Mui-focused fieldset {
      border-color: $ey-phoenix-light-gray-2;
      border-width: 1px;
    }

    &:hover fieldset {
      border-color: $ey-phoenix-light-gray-2;
    }


    .MuiListItemIcon-root {
      display: none;
    }
    .MuiSelect-select {
      padding-left: 44px;
      z-index: 99;
    }
  }

  .app-icon {
    right: 13px;
  }
}

.sort-menu-item {
  color: $header_admin_menu_active !important;
  font-family: $eyInterstateLight !important;
  font-size: $fontSize14 !important;
  padding: 9px 16px !important;
  justify-content: space-between !important;
  &.Mui-selected{
    background-color: $ey-off-white !important;
  }
  .MuiListItemIcon-root{
    min-width: 17px !important;
  }
}
