@import './styles/variables.scss';
@import './styles/richtTextStyles.scss';

.app {
  text-align: center;
}

.admin-console-page-container {
  background-color: $temo;
}

.app-maintenance-banner {
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  padding: 10px 20px;
  z-index: 1001;
  background-color: $light-red-0001;
  
  .banner-msg {
    display: inline-block;
    white-space: nowrap;
    min-width: 100%;
    -moz-transform:translateX(100%);
    -webkit-transform:translateX(100%);	
    transform:translateX(100%);
    -moz-animation: banner 40s linear infinite;
    -webkit-animation: banner 40s linear infinite;
    animation: banner 40s linear infinite;
    padding-left: 100%;

    * {
      padding: 0;
      margin: 0;
    }
    &:hover {
      animation-play-state: paused;
    }

    @include richTextFontStyles;
    @include richTextColorStyles;
    @include richTextHeadingSizes;
}
  .close-button {
    position: absolute;
    right: 0;
    top: 0;
    background-color: $light-red-0001;
    padding: 12px 16px 8px 16px;
  }
}

@-moz-keyframes banner {
  0%   { -moz-transform: translateX(0%); }
  100% { -moz-transform: translateX(-100%); }
 }
 @-webkit-keyframes banner {
  0%   { -webkit-transform: translateX(0%); }
  100% { -webkit-transform: translateX(-100%); }
 }
 @keyframes banner {
  0%   { 
  -moz-transform: translateX(0%);
  -webkit-transform: translateX(0%);
  transform: translateX(0%); 		
  }
  100% { 
  -moz-transform: translateX(-100%);
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%); 
  }
 }

.demo-video-dialog {
  .app-modal {
    max-height: 100%;
  }
  .close-btn-right {
    z-index: 1;
  }
}

.project-form-field {
  position: relative;
  margin-bottom: 16px;
}

@include richTextFontStyles;
@include richTextColorStyles;
@include richTextHeadingSizes;