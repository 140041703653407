@import "../../styles/variables.scss";

.select-cell-editor {
    &.disabled {
        cursor: not-allowed;
        .value-container {
            opacity: 0.4;
            font-style: italic;
        }
    }
}

.value-container {
    .value-ellipses{
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        padding-left: 0;
        width: 99%;
    }
    input {
        height: 0px;
    }
    div[class$="multiValue"], div[class$="singleValue"], div[class$="placeholder"]{
        display: none;
    }
    .app-tooltip{
        &.roles-selector{
            width: 99%;
            max-width: 250px;
            .value-ellipses {
                width: 100%;
            }
        }
    }
}

.custom-select-menu {
    max-height: 200px;
}