@import "../../styles/variables.scss";
.breadcrumb {
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;
  .icon-link {
    font-size: $fontSize16;
    margin-right: 15px;
  }
  .breadcrumb-item-custom {
    color: $breadcrumb-color-active;
    font-size: $fontSize14;
    font-family: $eyInterstateRegularNew;
    cursor: pointer;
    text-decoration: none;
    color: $breadcrumb-color-active !important;
  }

  .breadcrumb-item-active {
    font-size: $fontSize14;
    color: $breadcrumb-color;
    font-family: $eyInterstateRegularNew;
    a {
      text-decoration: none;
    }
  }
  .breadcrumb-arrow {
    margin: 0 12px 0 12px;
    font-size: $fontSize12;
    color: $breadcrumb-color;
  }
}
