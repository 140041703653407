@import "../../styles/globalStyles.scss";

.privacy-and-Terms {
  width: 100%;
  height: 100%;

  .privacy-header {
    background-color: $white;
    border-bottom: 1px solid $selec-react-hover-bg-color;
    padding: 46px 70px 54px 80px;

    .step-tracker {
      display: flex;
      flex-direction: row;
      position: relative;

      .step-tracker-line {
        background: $phoenix-dark-black;
        height: 2px;
        width: 100%;
        margin-left: 30px;
        margin-right: 16px;
      }
      .privacy-notice-icon {
        position: absolute;
        width: 70px;
        left: -2px;
      }
      .terms-conditions-icon {
        position: absolute;
        width: 70px;
        right: -2px;

        .icon-label {
          width: 70px;
          left: 35px;
        }
      }
      .icon-label {
        position: absolute;
        left: -10px;
        top: 22px;
        color: $tuna;
        font-family: $eyInterstateLight;
        font-size: $fontSize10;
        font-weight: 300;
      }
      .icon-wrapper {
        position: absolute;
        height: 24px;
        width: 24px;
        border-radius: 50%;
        top: 4px;
        left: 4px;

        .app-icon {
          width: 24px;
          height: 24px;
          font-size: $fontSize24;
          display: inline-block;
          position: relative;
          left: 7px;
          top: 7px;
          margin-bottom: 8px;
        }
      }
      .inprogress-state {
        width: 46px;
        height: 46px;
        position: absolute;
        border-radius: 50%;
        top: -16px;
        left: 4px;
      }
      .no-progress-state {
        background-color: transparent;
      }
      .validate-icon {
        left: calc(100% - 30px);
      }
    }
  }
  .privacy-content {
    background-color: rgba($white, 0.8);
    padding: 18px 16px 30px 35px;

    .content-title {
      font-size: $fontSize18;
    }
    .content-text {
      font-family: $eyInterstateLight !important;
      font-size: $fontSize15;
      max-height: 400px;
      overflow-y: auto;
      padding-right: 20px;
      .WordSection1 {
        h5 {
          margin-left: 2.5%;
          margin-bottom: 0;
          display: flex;
          justify-content: flex-end;
        }
        h2 {
          margin-left: 2.5% !important;
          text-align: left !important;
          margin-top: 0;
          span {
            font-size: $fontSize20 !important;
          }
        }
      }

      span {
        font-family: $eyInterstateLight !important;
      }

      &::-webkit-scrollbar {
        width: 8px;
        height: 7px;
        padding-left: 10px;
      }
      &::-webkit-scrollbar-track {
        border-radius: 5px;
        margin-left: 20px;
      }
      &::-webkit-scrollbar-thumb {
        background: $alto;
        border-radius: 5px;
      }
    }
  }
  .privacy-footer {
    background-color: $white;
    border-top: 1px solid $selec-react-hover-bg-color;
    padding: 10px 35px;
    display: flex;
    justify-content: space-between;
    .next-button-wrapper {
      display: flex;
      justify-content: flex-end;
    }
  }
}
