@import '../../styles/globalStyles.scss';

.step-indicator {
  z-index: 1;
  display: flex;
  box-shadow: 0 0 15px 0 rgba($black, 0.08);
  padding: 15px 48px 0;
  color: $mine_shaft;
  font-family: $eyInterstateLight;
  font-weight: 300;
  position: relative;

  .title-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 88px 7px 0;
    min-width: 300px;
    .title {
      font-size: $fontSize18;
      letter-spacing: -0.56px;
    }
    .sub-title {
      color: $gray;
      font-size: $fontSize14;
    }
  }
  .step {
    display: flex;
    margin-right: 30px;

    .indicator-wrapper {
      display: flex;
      margin-right: 50px;
      cursor: pointer;
      &>div {
        display: inherit;
      }
    }
    .active-step {
      pointer-events: none;
      cursor: default;
      &>div{
       display: inherit; 
      }
    }
    .radio-btn {
      margin-right: 20px;
      min-height: 20px;
      min-width: 20px;
      max-height: 20px;
      max-width: 20px;
      border: 1px solid $gray;
      border-radius: 50%;
      position: relative;
      top: 11px;
      display: flex;
      justify-content: center;
      align-items: center;

      .circle {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .in-progress {
        background-color: $emerald;
      }
      .complete {
        background-color: transparent;
      }
      .tick {
        position: absolute;
        display: inline-block;
        width: 8px;
        height: 4px;
        border-bottom: 1px solid $trout;
        border-left: 1px solid $trout;
        transform: rotate(-45deg);
      }
    }
    .text-tile {      
      .step {
        color: $gray;
        font-size: $fontSize13;
        position: relative;
        bottom: 2px;
        margin-right: 0;
      }
      .step-title {
        color: $gray;
        font-size: $fontSize15;
        position: relative;
        bottom: 7px;
      }
    }
    .border-btm {
      border-bottom: 6px solid $tuna;
    }
    .arrow {
      border-bottom: 1px solid $gray;
      border-right: 1px solid $gray;
      height: 12px;
      width: 12px;
      transform: rotate(-45deg);
      position: relative;
      top: 19px;
    }
  }
  .step-btns {
    position: absolute;
    right: 10px;
    top: 25px;

    .back-btn {
      padding-right: 0;
    }

    .right-btns {
      text-decoration: none !important;
      font-weight: bold;
      &:hover, &:focus {
        border: none !important;
        text-decoration: none !important;
      }
    }
  }
}
.header-project-nav {
  justify-content: space-between;
  min-height: 70px;
  .nav-button-text {
    display: flex;
    align-items: flex-end;
    align-self: flex-end;
    .app-tooltip {
      cursor: not-allowed;
    }
  }
  .text-tile {
    width: 100%;
    margin-left: 20px;
    cursor: pointer;
    border-bottom: 6px solid transparent;
  }
  .disable-tab {
    pointer-events: none;
    cursor: not-allowed;
  }
  .step-title {
    color: $gray;
    font-size: $fontSize15;
    position: relative;
    bottom: 4px;
  }
  .border-btm {
    border-bottom: 6px solid $tuna;
    .step-title {
      color: $black;
      font-weight: bold;
    }
  }
}
