@import '../../styles/globalStyles.scss';
.text-contain{
    display: inline;
    width: 100%;
  }
    
  .read-text-or-hide {
    color: $frenchy_gray;
    cursor: pointer;
  }

  .read-more-modal-content {
    font-family: $eyInterstateLight;
    font-size: $fontSize14;
  }

  .MuiTypography-root {
    text-transform: capitalize;
  }