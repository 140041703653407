@import "../../styles/variables.scss";
@import "../../styles/richtTextStyles.scss";
.header {
  height: 50px;
  border-bottom: 1px solid $light-border;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1002;
  background-color: $white;
  
  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    .MuiIconButton-root {
      border-radius: 0 !important;
      padding: 8px;
    }
  }

  .logo-link {
    display: flex;
    align-items: center;
    text-decoration: none;
    outline: none;
    
    .logo {
      height: 40px;
      cursor: pointer;
      margin: 0 8px 0 0;
    }
  
    .header-title {
      font-family: $eyInterstateRegularNew;
      font-weight: 400;
      font-size: $fontSize16px;
      color: $secondary_button_grey;
    }
  }

  .header-items {
    .MuiBadge-anchorOriginTopRightRectangular {
      width: 15px;
      height: 15px;
      min-width: 15px;
      font-size: 9px;
    }

    .logged-in-user-icon {
      margin-left: 5px;
    }
  } 
}

.MuiMenu-paper {
  .user-info-menu-container {
    display: flex;
    align-items: center;

    .user-avatar-icon {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $grid_table_border_color;
      border-radius: 50%;
      margin-right: 10px;

      .app-icon {
        margin: 0;
      }
    }

    .user-name {
      color: $ey-phoenix-light-gray;
      font-family: $eyInterstateRegular;
    }
    .user-email {
      color: $ey-phoenix-light-gray-3;
    }
  }

  .Mui-focusVisible {
    background-color: $white !important;
  }

  .menu-icon {
    color: $ey-phoenix-light-gray-3;
    margin-right: 8px;
    font-size: $fontSize14;
  }
  .menu-item-label {
    color: $charade;    
    display: flex;
    align-items: center;
  }
}
