@import "../../styles/globalStyles.scss";
@import "../../styles/variables.scss";

  .manage-assistant-page {
    padding: 0 24px;
    .title-wrapper {
      font-family: $eyInterstateLight;
      display: flex;
      flex-direction: column;
      padding: 24px 0;
    }
    .page-content{
      //we will have to subtract the height of header and the footer
      height: calc(100% - 138px);
    }
  }