@import '../../../styles/variables.scss';

.report-tile-conatiner{
    padding: 10px;
    min-height: 150px;
    .report-title-container {
        display: flex;
        justify-content: space-between;
        .report-title {
            width: 250px;
            font-size: $fontSize18;
            word-wrap: break-word;
        }
        .report-actions{
            align-self: flex-start;
        }
        margin-bottom: 10px;
    }
    .report-description {
        color: $placeholder_color;
        font-size: $fontSize14;
    }
    .report-roles {
        color: $placeholder_color;
        font-size: $fontSize14;
    }
}