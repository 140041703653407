@import '../../styles/globalStyles.scss';

.project-details {
  color: $mine_shaft;
  font-family: $eyInterstateLight;
  font-size: $fontSize14;
  font-weight: 300;

  .title {
    margin: 0 0 13px 0;
    font-size: $fontSize24;
  }
  .desc {
    margin-bottom: 15px;
    line-height: 22px;
    max-height: 72px;
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .details {
    margin-bottom: 14px;
    font-size: $fontSize18;
    display: inline-block;
  }
  .details-table {
    font-family: $eyInterstateRegular;
    font-weight: normal;
    display: flex;
    flex-direction: column;
    .detail-row{
      display: flex;
      span {
        display: block;
        word-break: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .keys, .values {
        flex: 1 1 0px; // 0px instead of 0 is needed here
      }
    }
  }
}
