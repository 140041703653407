@import '../../styles/globalStyles.scss';

.admin-users {
    padding: 30px 50px;
    
    .admin-users-title {
        font-size: $fontSize18;
    }

    .user-actions{
		display: flex;
    	bottom: 80px;
		.cancel-btn {
			padding: 0 35px;
			margin-left: 20px;
		}
	}

	.user-details{
        width: 240px;
        color: $placeholder_color;
        &.disabled{
           font-style: italic;
           opacity: 0.4;
           cursor: not-allowed;
        }
        .user-name{
            font-family: $eyInterstateBold;
            color: $selected_color;
        }
	}

	.app-table{
        .ag-header {
            border-right: solid 0.07143rem var(--table-row-border-color)!important;
            .app-table .ag-row{
                border-right: solid 0.07143rem var(--table-row-border-color)!important;
            }
            .user-type-header {
                display: flex;
                span {
                    margin-right: 10px;
                }
            }
        }
        .ag-header-viewport:after{
            width: 0px;
        }
        .ag-horizontal-right-spacer {
          display: none;
        }
        .ag-cell-inline-editing{
          background-color: transparent;
          border: 0;
          box-shadow: none;
        }

        .ag-center-cols-container {
            min-width: 100%;
        }

        .ag-cell{
            font-family: $eyInterstateRegular !important;
            padding: 0 8px;
            .select-cell-editor {
                &.validation-error {
                    .custom-select__control {
                        .custom-select__value-container {
                            border-bottom: 1px solid $thunderbird;
                            color: $thunderbird;
                        }
                    }
                }
                &.disabled { 
                    pointer-events: none;
                }
                &.field-data-warning {
                    .value-container {
                        color: $neon_carrot;
                    }
                }
                .custom-select__value-container {
                    padding: 0;
                }
            }
            .warning-container {
                color: $neon_carrot;
            }
        }
        .ag-cell-value{
            width: 100%;
            overflow: visible !important;
            .app-table-cell-renderer{
                width: 100%;
                .project-form-field {
                    width: 100%;
                    max-width: 250px;
                }
            }
        }

        .ag-paging-panel{
            padding: 10px 10px 20px 10px;
            height: auto;
        }
    }

	.link {
		color: $light_blue;
		cursor: pointer;
    }
    
    .disabledResetAll {
        cursor: not-allowed;
    }

    .ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .async-dropdown {
        padding: 5px 8px;
		width: 98%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
        .async-dropdown-name {
          border-right: 1px solid $search_dropdown_border;
        }
        div {
          flex: 1;
          align-self: flex-start;
          padding: .6rem 1.5rem;
        }
    }
    @include search-wrapper();
    .search-wrapper .form-control {
        width: 500px;
      }    
}


