@import '../../../styles/variables.scss';
@import '../../../styles/globalStyles.scss';

.customize-bundle {
  min-height: 500px;
  padding: 25px 35px;
  color: $tuna;
  font-family: $eyInterstateLight;
  margin-right: -12px;
  .bundle-wrapper {
    width: 100%;
    margin-top: 0;
    .bundle-card .description {
      max-width: 100%;
      @include multiline-ellipsis(4);
    }
  }
  .overview-count {
    font-weight: bold;
  }
  .overview, .apps-bundle {
    margin-top: 18px;
  }
  .overview-container {
    display: flex;
    flex-direction: column;
    margin: 35px 0 0 -12px;
    font-weight: 300;

    .title {
      font-size: $fontSize18;
      margin-bottom: 10px;
    }
    .border-btm {
      border-bottom: 1px solid $athens_gray1;
    }
    .app-tiles-wrapper {
      max-height: 320px;
      overflow-y: auto;

      .app-tile {
        display: flex;
        font-size: $fontSize14;
        width: calc(100% - 8px);
        padding: 20px 0;
        justify-content: space-between;
  
        .icon-n-title {
          display: flex;
          align-items: center;
  
          .app-icon {
            min-width: 32px;
            min-height: 32px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            width: 32px;
            height: 32px;
          }
          .app-title {
            margin: 0 6px 0 10px;
            line-height: 1.3;
          }
        }
        .app-toggle {
          display: flex;
          align-items: center;
          color: $jumbo;
          font-size: $fontSize12;
          font-weight: 300;
  
          .project-form-field {
            margin: 0;
            height: 20px;
          }
        }
      }
    }
    .app-tiles-wrapper::-webkit-scrollbar {
      width: 6px;
      height: 7px;
    }

    .app-tiles-wrapper::-webkit-scrollbar-track {
        border-radius: 5px;
    }

    .app-tiles-wrapper::-webkit-scrollbar-thumb {
        background: $scarpa_flow;
        border-radius: 5px;
    }
  }
}
