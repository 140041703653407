@import "../../styles/variables.scss";
.get-help-wrapper {
  .help-btn {
    padding: 8px;
    .app-icon {
      font-size: $fontSize20;
    }
  }
}

.help-modal {
  .modal-content {
    padding-top: 8px;
    .label-modal-border {
      color: $black;

      .country-wrapper {
        padding: 0 0 0 50px;
        line-height: 2rem;
      }
      .users-wrapper {
        padding-left: 8px;
      }
      .users-list-margin-bottom {
        margin-bottom: 20px;
      }
      .title {
        font-weight: bold;
      }
      .sub-title {
        margin-bottom: 10px;
        font-size: $fontSize17;
        font-weight: bold;
      }
      .title-margin-top {
        margin-top: 20px;
      }
    }
  }
}

.help-menu-pop {
  .MuiPaper-root {
    top: 48px !important;
  }
  .more-menu-option {
    padding: 12px 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: $fontSize16;
  }
}
