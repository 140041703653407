@import '../../styles/variables.scss';
@import '../../styles/globalStyles.scss';

.admin-reports {
    padding: 30px 50px;

    .page-hdr-title-container {
		display: flex;
		justify-content: flex-end;
		align-items: center;
        margin-bottom: 10px;
        position: relative;

        .go-back-icon {
            cursor: pointer;
            position: absolute;
            left: 0;
            
            .icon-link {
                font-size: $fontSize14;
                margin-right: 5px;
            }
            span {
                color: $black;
                text-decoration: none;
                font-weight: 600;    
                font-size: 12px;
            }
        }

        .btn-grp {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            button {
                margin-right: 10px;
            }
        }
    }
    
    .reports-list {
        display: flex;
        flex-wrap: wrap;
        .reports-item {
            margin-right: 10px;
            width: 350px;
            padding: 10px;
            background-color: $white;
            box-shadow: 0 0 1px 1px $box_shadow;
            margin-bottom: 10px;
            &.clickable{
                cursor: pointer;
            }
            &.add-report, &.edit-report {
                .btn-grp {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    button {
                        width: 100px;
                        min-width: 100px;
                        height: 40px;
                        min-height: 40px;
                        margin-right: 10px;
                    }
                }
            }
            .MuiFormHelperText-root {
                font-size: $fontSize14;
              }
        }
    }
}

#powerBiAdminReport {
    width: 100%;
    height: 100%;
    .admin-report-power-bi-embed {
        width: 100%;
        height: 100%;
    }
}