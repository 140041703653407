@import "../../styles/globalStyles.scss";

.home {
  .head-section {
    padding: 0 0 30px 0;

    .home-banner {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      background: url('../../assets/images/banner/home-banner-1440.jpg') no-repeat;
      height: 280px;
      background-size: cover;

      img {
        object-fit: cover;
        width: 100%;
        height: 280px;
      }
      .banner-text {
        position: absolute;
        top: 16%;
        left: 19.75%;
        transform: translateX(-50%);
        width: 23%;
        height: 75%;
        background-color: $turbo;
        clip-path: polygon(0% 28%, 100% 0%, 100% 100%, 0% 100%);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 40px 10px 10px 30px;
        p {
          margin: 0;
          margin-top: 10px;
          font-size: $fontSize30;
          font-family: $eyInterstateRegularNew;
          line-height: 1;
          @media screen and (max-width: 1199px) {
            font-size: $fontSize20;
          }
        }
      }
    }

    .image-cropper {
      width: 50px;
      height: 50px;
      overflow: hidden;
      border-radius: 50%;
      margin-bottom: 20px;
      background-color: $black;
    }
    .welcome {
      color: $scarpa_flow_dark;
      font-size: $fontSize18;
      font-family: $eyInterstateLight;
      font-weight: 300;
      letter-spacing: -0.56px;
    }
    .user-name {
      font-size: $fontSize30;
    }
    .btn-align {
      display: flex;
      .project_settings {
        white-space: nowrap;
        a {
          text-decoration: none;
        }
      }
    }
  }

  .title-section {
    padding: 0 24px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .top-action-container {
      display: flex;

      .search-container {
        width: 250px;
        margin-right: 10px;

        .search-box-icon {
          font-size: $fontSize14;
        }
      }
      .sort-container{
        width: 190px;
        margin-right: 10px;
      }
    }
    
    .app-tooltip {
      display: inline;
      height: auto;
      width: auto;
    }
    
    .create-button-icon {
      @include primary-button-icon;
      margin-right: 5px;
    }

    .manage-user-btn-disabled {
      color: $disabled_text_color;
      background-color: $disabled_motif_background;
      cursor: not-allowed;
    }

    .tiles-heading {
      @include app-headings;
    }
  }

  .tiles-section-container {
    min-height: 218px;

    .tiles-section {
      display: grid;
      grid-template-columns: repeat(auto-fill, 288px);
      grid-gap: 18px;
      padding: 10px 24px 20px;
      justify-content: center;
      @media only screen and (min-width: 1685px) and (max-width: 1865px)  {
        grid-template-columns: repeat(auto-fill, 308px);
        grid-gap: 24px;
      }
      @media only screen and (min-width: 1300px) and (max-width: 1559px)  {
        grid-template-columns: repeat(auto-fill, 304px);
        grid-gap: 24px;
      }
      @media only screen and (min-width: 1024px) and (max-width: 1253px)  {
        grid-template-columns: repeat(auto-fill, 308px);
        grid-gap: 24px;
      }
    }

  }
 
  .no-records {
    font-size: $fontSize13;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .load-more-buttons {
    display: flex;
    justify-content: center;
    padding: 10px 0 20px;

    .load-button-icon {
      @include primary-button-icon;
      margin-left: 5px;
    }
  }

  .delete-success-confirmation {
    position: fixed;
    bottom: 30px;
    right: 20px;
    z-index: 999;
    min-width: 500px;
    background-color: $white;
  }

  .unauthorized-message {
    text-align: center;
    padding-top: 130px;
    .unauthorized-title {
      font-size: $fontSize22;
      font-weight: 700;
      color: $red;
      padding-bottom: 1rem;
    }
  }
  .skeleton-loader {
    display: block;
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5) 50%,
        rgba(138, 74, 74, 0) 80%
      ),
      lightgray;
    background-repeat: repeat-y;
    background-size: 50px 500px;
    background-position: 0 0;
    animation: shine 1s infinite;
  }

  @media screen and (min-width: 1920px){
    .home-banner {
      background: url('../../assets/images/banner/home-banner-1920.jpg') no-repeat !important;
      height: 280px !important;
      background-size: cover !important;
    }
  }
}
