@import '../../styles/variables.scss';

.app-button {
    svg {    
        position: relative;
        top: 2px;
        height: 14px !important;
        width: 14px !important;
    }
    .app-icon {
        top: 0px
    }
    &.btn-link {
        color: $denim;
    }
}
.btn-ey-yellow{
    color: $selected_color !important;
    background-color: $turbo !important;
    height: 36px;
    img{
        margin-right: 8px;
    }
}
