@import "../../styles/variables.scss";

.app-button-with-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 16px;
    height: 44px;
    border: 1px solid $ey-phoenix-light-gray-2;
    background: $white;
    box-shadow: none;
    outline: none;
    white-space: nowrap;
    border-radius: 4px;
    cursor: pointer;
    span {
      padding-Left: 12px;
      color: $secondary_button_grey;
      font-size: $fontSize16;
      font-weight: 400;
    }
}


