@import '../../styles/variables.scss';

	.header-admin-bar {
		margin-top: 3em;
		padding-left: 54px;
		background-color: $header_admin_menu!important;
		a{
			text-decoration: none;
		}
		.app-button {
			color: $white!important;
			padding: 18px 35px 18px 35px!important;
			border-bottom-width: 5px;
			border-color: transparent !important;
			background-color: $header_admin_menu;
			font-size: $adminMenuFontSize !important;
			line-height: $adminMenuFontSize !important;
			font-weight: 400;
			font-family: $eyInterstateRegular;
		}
		.active-bar {
			.app-button{
				border-bottom-color:$turbo!important;
				background-color: $header_admin_menu_active!important;
			}
		}
	}
