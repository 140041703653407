@import '../../styles/variables.scss';

.stepper-indicator {
  margin: 15px 0;

  .MuiStepper-root {
    background: transparent;
    width: 100%;
  }

  .stepper-icon {
    cursor: pointer;
    margin-top: -3px;
    position: relative;
    right: -1px;
    img {
      width: 30px;
      height: 30px;
    }
  }

  .MuiStepLabel-label {
    margin-top: 3px !important;

    .step-label{
      font-size: $fontSize15;
      font-family: $eyInterstateLight;
      cursor: pointer;
    }
    .step-title{
      font-size: $fontSize15;
      cursor: pointer;
      text-transform: none;
      margin-top: 3px;
    }
  }

}