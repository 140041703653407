@import "../../styles/globalStyles.scss";
@import "../../styles/variables.scss";

.hs-page-wrapper {
  background-color: $ey-off-white;
  overflow-x: hidden;
  .page-header {
    padding-left: 2rem;
    height: 4rem;
    .back-text {
      font-size: $fontSize12;
      cursor: pointer;
      width: 3rem;
      justify-content: space-between;
      display: flex;
      align-items: center;
      height: 2rem;
      font-family: $eyInterstateBold;
      color: $secondary_button_grey;
      .icon-link {
        font-size: $fontSize14;
        color: $placeholder_color;
      }
    }
    .page-title {
      margin: 5px 0 5px 0;
      font-size: $fontSize24;
      font-weight: 400;
    }
  }
  a:hover {
    cursor: pointer;
  }
  .hs-banner {
    height: 110px;
    width: 100%;
    position: relative;
    text-align: center;
    .help-img {
      position: relative;
      z-index: 10;
    }
    .hs-img-line {
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      z-index: 9;
      max-height: 100%;
      object-fit: fill;
      position: absolute;
      left: 0;
    }

    .w-100 {
      width: 100%;
    }
  }
  .mt-2 {
    margin-top: 2rem;
  }
  .help-description {
    font-weight: 400;
    font-size: $fontSize14;
    font-family: $eyInterstateLightNew;
    color: $placeholder_color;
  }
  .pl-2 {
    padding-left: 2rem;
  }
  .flex-nowrap {
    flex-wrap: nowrap !important;
  }
  .mt-1 {
    margin-top: 1rem;
  }
  .mb-1 {
    margin-bottom: 1rem;
  }
  .section-title {
    text-align: center;
    font-size: $fontSize31;
    font-family: $eyInterstateLightNew;
    color: $secondary_button_grey;
    font-weight: 400;
  }

  .help-container {
    background-color: $white;
    width: 100%;
    display: flex;
    padding: 1rem;
    flex-wrap: wrap;
    height: 100%;
    .size-xl {
      font-size: 52px;
      justify-content: center;
      text-align: center;
    }
    .align-right {
      right: 36px;
      position: absolute;
      font-size: $fontSize16;
    }
    .r-24 {
      right: 24px;
    }
    .r-0 {
      right: 0;
    }
  }
  .icon-wrapper {
    min-height: 7rem;
    background-color: $ey-phoenix-gray;
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .content-wrapper {
    h4 {
      font-family: $eyInterstateRegularNew;
      font-weight: 400;
      font-size: $fontSize16px;
    }
    p {
      margin: 0;
      line-height: 1.5;
      font-size: $fontSize13;
    }
    .mb-1 {
      margin: 0.5rem 0 0.5rem 0;
    }
  }
  a {
    text-decoration: none;
    color: inherit;
    margin-bottom: 1rem;
  }
  .pb-2 {
    padding-bottom: 2rem;
  }
  .hs-tiles-container {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
  }
}
