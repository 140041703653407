@import "../../styles/globalStyles.scss";

.motif-app-text-field {
  border: 1px solid $ey-phoenix-light-gray-2 !important;

  .MuiFormLabel-root,
  .MuiInputLabel-shrink,
  .Mui-focused {
    font-family: $eyInterstateLight;
    color: $tuna !important;
    font-size: $fontSize16;
  }
  .MuiInputLabel-shrink {
    transform: translate(12px, 6px) scale(0.82) !important;
  }
  .MuiFilledInput-multiline {
    padding: 10px 30px 10px 10px;
  }
  .MuiInputBase-input {
    font-family: $eyInterstateLight;
    background-color: $white;
    color: $tuna !important;
    font-size: $fontSize15;
  }
  .MuiInputBase-root .Mui-disabled {
    cursor: not-allowed;
    color: $placeholder_color !important;
  }
  .MuiFormLabel-root.Mui-disabled {
    color: $placeholder_color !important;
  }
  .MuiInputBase-root {
    background-color: $white;
    border-radius: 0;

    &:hover,
    &:focus {
      &::before {
        border-bottom: none;
      }
    }
  }
  .MuiFilledInput-root:hover {
    background-color: $white;
  }
  .MuiFilledInput-underline {
    &::before {
      border-bottom: none;
    }
    &::after {
      border-bottom: none;
    }
  }
  .MuiFilledInput-underline.Mui-disabled {
    &::before {
      border-bottom-style: none;
    }
  }
  .MuiInputBase-root.Mui-disabled {
    &:hover,
    &:focus {
      &::before {
        border-bottom: none;
      }
    }
  }
}
.in-valid {
  .MuiInputBase-root {
    &:hover,
    &:focus {
      &::before {
        border-bottom: 1px solid $thunderbird;
      }
    }
  }
  .MuiFilledInput-underline {
    &::before {
      border-bottom: none;
    }
  }
}
