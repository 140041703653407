@import "../../../styles/globalStyles.scss";

.render-card {
  border: 1px solid $light-border;
  border-radius: 8px;
  padding: 20px !important;
  max-width: 495px;
  min-height: 100px;
  margin-bottom: 12px;

  &.unread-card {
    background-color: $white;
    cursor: pointer;
  }

  .card-header {
    display: flex;
    align-items: center;
    margin-bottom: 16px;

    .card-title {
      font-family: $eyInterstateRegularNew;
      font-size: $fontSize20;
      font-weight: 400;
      line-height: 30px;
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .critical-pill {
      padding: 3px 8px 3px 8px;
      border-radius: 16px;
      font-size: $fontSize12;
      font-weight: 400;
      line-height: 18px;
      text-align: center;
      background: $light-red-0001;
      color: $ey-phoenix-red-1;
      margin-left: 5px;

      &:hover {
        background: $light-red-0002;
      }
    }

    .unread-pill{
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-left: 10px;
      background-color: $notification-mid-blue;
    }

    .more-menu {
      background: none;
      border: none;
      cursor: pointer;
      margin-left: auto;
      font-size: 1.5em;
    }
  }

  .card-description {
    font-family: $eyInterstateLightNew;
    font-size: $fontSize16;
    font-weight: 300;
    line-height: 24px;
    margin-top: 0;
    margin-bottom: 16px;
    color: $ey-phoenix-light-gray;
    min-height: 40px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    overflow: hidden;
  }

  .card-footer {
    display: flex;
    justify-content: space-between;

    .createdBy {
      font-family: $eyInterstateRegularNew;
      font-size: $fontSize14;
      font-weight: 400;
      line-height: 20px;
      font-style: italic;
      color: $secondary_button_grey;
      text-transform: capitalize;
    }

    .createdOn {
      font-family: $eyInterstateLightNew;
      font-size: $fontSize12;
      font-style: italic;
      font-weight: 300;
      line-height: 18px;
    }
  }

  .toggle-description {
    background: none;
    border: none;
    color: $ey-link-blue;
    cursor: pointer;
    padding: 0;
    margin-left: 5px;
    font-family: $eyInterstateRegularNew;
    font-size: $fontSize14;
    font-weight: 400;
    line-height: 20px;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}
