@import "../../styles/variables.scss";
.manage-user-add-user {
  .stepper-container {
    width: 100%;
    .MuiStepper-root {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .MuiDialogTitle-root {
    h6 {
      text-transform: none;
    }
  }
  .manage-search-item-margin {
    margin-top: 10px;
  }
  .padding-left {
    padding-left: 15px;
  }
  .form-field-width {
    width: 526px;
  }
  .search-btn {
    width: 84px;
  }
  .check-tick {
    position: relative;
    bottom: 60px;
    right: -40px;
    padding: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .check-icon {
    background-color: $ey-green-check;
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }
  .Component-root-219 {
    padding: 16px;
  }
  .Component-root-54 {
    padding: 16px;
  }
  .MuiDialogContent-dividers {
    margin-left: 15px;
    margin-right: 15px;
    border-bottom: transparent;
    padding: 5px 16px 5px 16px;
  }
  .MuiInputLabel-outlined {
    transform: translate(1px, -22px) scale(1);
    font-size: $fontSize14;
    color: $ey-phoenix-light-gray !important;
    font-weight: 300;
    font-style: $eyInterstateLightNew;
  }
  .MuiButton-label {
    font-size: $fontSize14;
    font-weight: 400;
  }
  .motif-input .MuiInputBase-input {
    font-size: $fontSize14;
    font-weight: 300;
    font-family: $eyInterstateLightNew;
    color: $ey-phoenix-light-gray !important;
    &::placeholder {
      color: $ey-phoenix-light-gray !important;
      opacity: 1;
    }
  }
  .MuiDialog-paperFullWidth {
    width: 650px;
  }
  .MuiFormHelperText-contained {
    margin-left: 0px;
    margin-right: 0px;
  }
  .motif-input .Mui-disabled {
    background-color: transparent; /* Remove background color for the input field */
  }
  .motif-input .Mui-disabled input {
    border-radius: 4px;
    border: solid 1px $ey-phoenix-light-gray-2;
  }
  .disabledInput {
    &::placeholder {
      color: rgba(0, 0, 0, 0.38); // Adjust the color as needed
      opacity: 1; // Ensure the placeholder is visible
    }
  }
  .noLabelBackground .MuiInputLabel-root {
    background-color: transparent; // Remove the label background
  }
  .manage-user-modal {
    .manage-user-modal-header {
      padding: 1rem 3rem 1rem 3rem;
    }
    .project-form-field {
      margin-bottom: 15px;
    }
    .manage-search {
      display: flex;
      flex-direction: row;
      margin-bottom: 30px !important;
      .MuiFormHelperText-root {
        font-size: $fontSize12;
      }
    }
    .manage-search-item {
      flex-grow: 3;
      width: 100%;
      margin-right: 10px;
      .search-box-icon {
        font-size: $fontSize16;
        color: $ey-phoenix-light-gray-4 !important;
        &::placeholder {
          color: $ey-phoenix-light-gray-4 !important;
        }
      }
    }
    .my-ey-register {
      padding: 0 5px;
      color: $thunderbird;
      font-family: inherit;
    }
    .form-field {
      display: flex;
      margin-bottom: 15px;

      .image-cropper {
        width: 84px;
        height: 70px;
        overflow: hidden;
        border-radius: 50%;
        margin-bottom: 20px;
      }
      .form-field-margin {
        margin-right: 10px;
        width: 100%;
      }
      .rt-mrgn {
        margin-right: 0;
      }
      .user-img-wrapper {
        margin-left: 40px;
        margin-top: 15px;
      }
    }
    .app-icon {
      color: $frenchy_gray;
    }
  }

  .additional-btns {
    justify-content: flex-start;
  }
  .radio-grp {
    margin: 0px 0 20px;
    z-index: 1;
    font-size: $fontSize14;
    .MuiIconButton-label {
      width: 16px;
      height: 18px;
    }
  }
  .step-tracker {
    margin-top: 26px;
    display: flex;
    flex-direction: row;
    position: relative;
    margin-bottom: 50px;

    .step-tracker-line {
      background: $alto;
      height: 4px;
      width: 50%;
    }
    .icon-wrapper {
      position: absolute;
      background: $alto;
      height: 34px;
      width: 34px;
      border-radius: 50%;
      top: 4px;
      left: 4px;

      .app-icon {
        display: inline-block;
        position: relative;
        left: 7px;
        top: 6px;
        margin-bottom: 8px;
        height: 20px;
        width: 20px;
      }
      .icon-label {
        color: $tuna;
        font-family: $eyInterstateLight;
        font-size: $fontSize10;
        font-weight: 300;
      }
    }
    .inprogress-state {
      background-color: rgba($turbo, 0.5);
      width: 42px;
      height: 42px;
      position: absolute;
      border-radius: 50%;
      top: -20px;
      left: -2px;
    }
    .no-progress-state {
      background-color: transparent;

      .app-icon {
        opacity: 0.5;
      }
    }
    .progress-complete {
      .app-icon {
        opacity: 1;
      }
    }
    .complete-state {
      background: $turbo;
    }
    .validate-icon {
      left: calc(50% - 24px);
    }
    .save-icon {
      left: calc(100% - 30px);
    }
  }
  .button-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $ey-phoenix-light-gray;
    font-family: $eyInterstateLight;
    font-size: $fontSize16;
    font-weight: 300;

    .download-btn {
      margin: 6px 0 20px;
      &:hover {
        svg {
          fill: $white;
        }
      }
      .app-icon {
        width: 22px;
        height: 22px;
        margin-right: 5px;
      }
      .download-btn-icon {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: $fontSize14;
      }
      .download-icon {
        .app-icon {
          svg {
            width: 45px;
            height: 45px;
          }
        }
      }
    }

    .download-sucess {
      color: $white !important;
      background-color: $special-green !important;
    }

    .download-failure {
      color: $white !important;
      background-color: $special-red !important;
    }
    .app-button {
      .app-icon {
        svg {
          width: 22px;
          height: 22px;
        }
      }
    }
  }
  .drag-drop-wrapper[style*="pointer-events: none"] {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .drag-drop-wrapper {
    background: $white;
    border: 1px dashed $ey-phoenix-light-gray-2;
    height: 126px;
    padding: 16px 24px 16px 24px;
    border-radius: 4px;
    gap: 4px;
    .dropzone {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-top: 5px;
      .upload-icon-size {
        max-width: 6%;
        max-height: 6%;
        margin-bottom: 8px;
      }
      .upload-text {
        font-family: $eyInterstateBold;
        font-weight: 400;
        font-size: $fontSize14;
        color: $breadcrumb-color-active;
        margin-top: 12px;
      }
      .upload-options {
        font-family: $eyInterstateLightNew;
        font-weight: 300;
        font-size: $fontSize12;
        color: $ey-phoenix-light-gray;
      }
    }
    &:hover {
      background-color: $disabled_background;
    }
  }
  .drag-drop-wrapper-alert {
    .MuiAlert-icon {
      color: $ey-error-icon-red;
    }
    background: $light-red-0001;
    border: 1px dashed $ey-alert-red;
    border-radius: 4px;
    height: 126px;
    padding: 16px 24px 16px 24px;
    gap: 4px;
    color: $ey-error-icon-red;
    .dropzone {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin-top: 5px;
      .upload-icon-size {
        max-width: 5%;
        max-height: 5%;
        margin-bottom: 8px;
      }
      .upload-text {
        font-family: $eyInterstateBold;
        font-weight: 400;
        font-size: $fontSize14;
        color: $ey-alert-red;
        margin-top: 12px;
      }
      .upload-options {
        font-family: $eyInterstateLightNew;
        font-weight: 300;
        font-size: $fontSize12;
        color: $ey-alert-red;
      }
    }
  }

  .bulk-import-success {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 6.5rem;
    .bulk-import-success-done {
      font-weight: 700;
    }
    .bulk-import-check {
      .app-icon {
        color: $special-green;
        height: 30px;
        width: 30px;
      }
    }
    .bulk-import-error {
      .app-icon {
        color: $special-red;
        height: 30px;
        width: 30px;
      }
    }
  }

  .filename-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: $alto;
    border: 1px solid $box_shadow;
    font-size: $fontSize14;
    .filename {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      svg {
        fill: $special-green;
      }
      .file-icon {
        color: $special-green;
        margin-right: 10px;
      }
    }
  }

  .filename-action {
    cursor: pointer;
    font-size: $fontSize14;
    margin-right: 10px;
    &.reupload {
      color: $link_blue;
    }
  }

  .validation-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 20px;
    font-size: $fontSize14;
    .app-icon {
      svg {
        fill: $white;
      }
    }
    &.error {
      margin-right: 5px;
      line-height: 1rem;

      svg {
        color: $special-red;
        margin-right: 5px;
      }
    }
    &.success {
      svg {
        width: 15px;
        height: 15px;
      }
    }

    .validation-icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 5px 0;
      background-color: $special-green;
      border-radius: 50%;
      color: $white;
      margin-right: 5px;
    }
  }

  .validation-errors {
    margin-top: 10px;
    overflow-y: auto;
    .validation-row {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      font-size: $fontSize12;
      background-color: $alto;
      .validation-field {
        padding: 7px;
      }
      .key-field {
        width: 120px;
      }
      .key-cell {
        width: 75px;
      }
      .key-error {
        flex: 1;
      }
      .hdr {
        font-weight: bold;
      }
      &.row {
        border: 2px solid $special-red;
        border-bottom: 0px;
        &:last-child {
          border-bottom: 2px solid $special-red;
        }
      }
    }
  }
}
