@import '../../styles/variables.scss';
@import '../../styles/globalStyles.scss';

.main{
    width: 100%;
    height: calc(100vh - 3.125rem);
    margin-top: 3.125rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0;
     background-size:'cover';
        .dialog{
        margin: 0 auto;
        width: 40%;
        padding: 2rem;
        background-color:$error-background-color;
        text-align: center;
        z-index: 1000;
        animation: 0.6s ease-out 0s 1 slideUp;
        line-height: 1.6;
    }
    .message {
        color: $mischka;
        white-space:pre-wrap;
        word-break:'break-word'
    }
    .shadow {
        -webkit-box-shadow: 5px 5px 20px 1px $error-background-color;
        -moz-box-shadow: 5px 5px 20px 1px $error-background-color;
        box-shadow: 5px 5px 20px 1px $error-background-color;
    }
    a.link {
        text-decoration: none;
        color: $turbo;
    }
    .errorIcon{
        color:$turbo;
        stroke-width:2.23;
        width:30px;
        height:30px;
      
    }
}

