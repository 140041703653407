@import '../../styles/variables.scss';
@import '../../styles/globalStyles.scss';

.administration-tabs {
    display: flex;
    flex-direction: column;
    padding: 30px 50px;
    .admin-administration-tab {
        margin-bottom: 10px;
        .administration-menu-button {
            border-right: 1px solid $silver;
            box-shadow: 0 0 5px 0px rgba($black, 0.10);
            text-decoration: none;
            border-radius: unset;
            height: 100%;
            min-width: 160px;
            text-align: center;
            color: $mine_shaft;
            background-color: $temo;
            padding: 12px;
            &:hover {
                font-weight: bold;
                background-color: $white;
                cursor: pointer;
            }
            &:first-child {
                border-top-left-radius: 5px;
                border-bottom-left-radius: 5px;
            }
    
            &:last-child {
                border-right: none;
                border-top-right-radius: 5px;
                border-bottom-right-radius: 5px;
            }
    
            &.administration-menu-active {
                font-weight: bold;
                background-color: $white;
                border-bottom: 5px solid $turbo;
            }
        }
    }
    .administration-section {
        background-color: $white;
        min-height: 400px;
        padding: 20px 15px;
        display: flex;
        flex-direction: row;
        overflow: auto;
        width: 100%;

        .sub-tabs {
            margin-right: 20px;
            max-width: 200px;
        }
        .admin-project-container {
            width: 100%;
        }
    }

    .general-tab-button {
        width: 200px;
        text-align: left;
        justify-content: space-between;
        color: $white;
        background-color: $trout;
        margin: 1px 0;

        &.location-button {
            font-size: $fontSize13;
            line-height: 25px;
        }
        &.active {
            color: $black;
            background-color: $turbo;
        }
    }

    .list {
		.ag-body-viewport {
			min-height: 200px;
			border: 0.5px solid $selec-react-hover-bg-color;
			border-top: 0;
            border-right: 0;
		}
	}
    .page-hdr-title-container{
		display: flex;
		justify-content: space-between;
		align-items: center;
        margin-top: -20px;
	}
    
    .ag-body-horizontal-scroll-viewport {
        overflow-x: auto;
      }
}

@media screen and (max-width: 64em) {
    .administration-tabs {
        .administration-section {
            flex-direction: column;
            .sub-tabs {
                max-width: none;
                display: inline-block;
                button svg {
                    transform: rotate(90deg);
                }
            }
            .general-tab-button {
                margin: 0 1px;
                width: 150px;

                &.location-button {
                    width: 200px;
                }
            }
        }
    }
  }

  @media only screen and (min-width: 1300px) {
    .error-log {
        max-width: 96% !important;
    }
  }