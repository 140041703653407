@import '../../styles/globalStyles.scss';

.app-text-field {
   .MuiFormLabel-root, .MuiInputLabel-shrink, .Mui-focused {
     font-family: $eyInterstateLight;
     color: $tuna !important;
     font-size: $fontSize16;
   }
   .MuiInputLabel-shrink {
     transform: translate(12px, 6px) scale(0.82) !important;
   }
   .MuiInputBase-input {
     font-family: $eyInterstateLight;
     background-color: transparent;
     color: $tuna !important;
     font-size: $fontSize15;

     &:hover, &:focus {
       background-color: transparent;
       border-bottom: 2px;
     }
   }
   .MuiInputBase-root .Mui-disabled {
     cursor: not-allowed;
     color: #747480 !important;

     &:hover, &:focus {
       background-color: $temo;
     }
   }
   .MuiFormLabel-root.Mui-disabled {
     color: #747480 !important;
   }
   .MuiInputBase-root {
      background-color: $temo;
      border-radius: 0;

     &:hover, &:focus {
      background-color: $mischka;

       &::before {
         border-bottom: 2px solid $jumbo;
       }
     }
   }
   .MuiFilledInput-underline {
     &::before {
       border-bottom: 1px solid $jumbo;
     }
     &::after {
       border-bottom: none;
     }
   }
   .MuiFilledInput-underline.Mui-disabled {
     &::before {
       border-bottom-style: solid;
     }
   }
   .MuiInputBase-root.Mui-disabled {

     &:hover, &:focus {
       &::before {
         border-bottom: 1px solid $jumbo;
       }
     }
   }
 }
 .in-valid {
   .MuiInputBase-root {
     &:hover, &:focus {
       &::before {
         border-bottom: 2px solid $thunderbird;
       }
     }
   }
   .MuiFilledInput-underline {
     &::before {
       border-bottom: 1px solid $thunderbird;
     }
   }
 }