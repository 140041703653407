@import "../../styles/globalStyles.scss";

.app-modal {
  z-index: 1003 !important;
  border-radius: 8px;
  .MuiDialog-paper {
    &.MuiPaper-rounded {
      border-radius: 12px;
    }
  }

  &.z-1000 {
    z-index: 1000 !important;
  }
  &.app-preview-modal {
    z-index: 1300 !important;
  }

  &.custom-close {
    .MuiDialog-paper {
      overflow-y: inherit;
    }
  }

  .modal-close {
    position: absolute;
    top: -15px;
    z-index: 1004;
    background-color: $header_admin_menu_active;
    border-radius: 50%;
    border: 1px solid $header_admin_menu_active;
    right: -15px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    .modal-close-icon {
      color: $white;
    }
  }

  .green {
    background-color: green;
    color: white;
  }
  .red {
    background-color: red;
    color: white;
  }

  .app-button {
    &.warning {
      background-color: $thunderbird !important;
      color: $white !important;
      border-color: $thunderbird !important;
      &:hover {
        background-color: $tuna_dark !important;
        border-color: $tuna_dark !important;
      }
    }
  }
  .close-btn-right {
    position: absolute;
    top: -11px;
    right: -14px;
    cursor: pointer;
  }
  .sub-text {
    font-weight: 300;
    font-family: $eyInterstateLight;
  }
  .app-title,
  .app-title-bold {
    font-family: $eyInterstateRegular;
    color: $black;
    font-size: $fontSize14;
    b {
      font-family: $eyInterstateBold;
      font-weight: bold;
    }
  }
  .app-title-bold {
    font-weight: bold;
  }

  .red-highlighted {
    color: $red;
  }

  .center {
    text-align: center;
  }

  .additional-btns {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
  }

  .footer-container {
    width: 100%;
    padding: 8px 0;
    .footer-btns {
      display: flex;
      flex-direction: column;
    }
  }

  @media screen and (min-width: 48em) {
    .footer-container {
      .footer-btns {
        flex-direction: row;
        justify-content: flex-end;
        .app-button {
          margin-right: 10px;
        }
      }
    }
  }

  &.sharepoint-settings {
    .MuiDialogTitle-root {
      padding: 20px 32px;
      .MuiButtonBase-root.MuiIconButton-root {
        right: 20px;
      }
    }
    .MuiDialogContent-dividers {
      border-bottom: none;
      padding: 16px 0px;
      border-top: 1px solid $ey-border-grey;
      margin: 0 32px 0 32px;
    }
  }
}

.app-model-new-design {
  .MuiDialog-paper {
    padding: 24px;
    .MuiDialogTitle-root {
      padding: 0;
      padding-bottom: 12px;
      .MuiTypography-root {
        text-transform: none;
        font-family: $eyInterstateRegular;
        color: $secondary_button_grey;
        font-size: $fontSize20;
      }
      .MuiButtonBase-root {
        right: 24px;
        top: 16px;
      }
    }
  }
  .MuiDialogContent-dividers {
    padding: 0;
    border-bottom: none;
    padding: 20px 0px;
    border-top: 1px solid $ey-border-grey;
  }
  .MuiDialogActions-root,
  .footer-container {
    padding: 0;
  }
  &.default-modal-dialog-new-design {
    .MuiDialogContent-dividers {
      font-family: $eyInterstateLight;
      font-size: $fontSize16;
      font-weight: 300;
      line-height: 24px;
      color: $secondary_button_grey;
    }
  }
}
