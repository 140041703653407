@import "../../../styles/variables.scss";

.manage-users-grid {
  border: 1px solid #e6e6e9 !important;
  border-top: 0 !important;
  .ag-center-cols-container {
    min-width: 100%;
    border-right: 1px solid $light-border;
  }

  .ag-row {
    &.disabled {
      cursor: not-allowed;
      background-color: $grid_disabled_row_color;

      .app-dropdown-button,
      .value-container,
      .value-ellipses {
        font-style: italic;
        cursor: not-allowed;
        touch-action: none;
        opacity: 0.4;
        pointer-events: none;
      }

      .app-dropdown-button {
        color: $gray;
      }

      .user-details {
        font-style: italic;
        opacity: 0.4;
        cursor: not-allowed;
      }
      .list-user-remove-container {
        opacity: 0.4;
        cursor: not-allowed;
        font-style: italic;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

    .select-cell-editor {
      max-width: 300px;
    }
  }

  .apps-count {
    background-color: $white;
    font-size: $fontSize12;
    letter-spacing: 0px;
    border: 1px solid $ey-border-grey;
    color: $ey-phoenix-light-gray;
    margin-left: 5px;
    border-radius: 50%;
    padding: 3px 3px;
    width: 26px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hdr {
    &.disabled {
      .hdr-title {
        color: $silver !important;
      }
      .apps-count {
        background-color: $silver;
      }
      .app-status {
        text-align: center;
      }
    }

    .hdr-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      align-items: center;
    }

    .hdr-top {
      display: flex;
      align-items: center;
    }
    .hdr-sharepoint-title {
      .app-tooltip {
        width: 100%;
      }
      .sharepoint-tooltip {
        display: inline;
      }
    }
  }

  .ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .user-details {
    margin-left: 10px;
    width: 225px;
    display: flex;
    align-items: center;
    .user-details-area {
      width: 100%;
      .user-name{
        font-family: $eyInterstateRegular;
      }

    }
    .user-tooltip {
      position: absolute;
      right: 8px;
      .app-tooltip.app-tooltip-container {
        width: 14px;
        color: $ey-phoenix-light-gray;
      }
    }
    &.disabled {
      font-style: italic;
      opacity: 0.4;
      cursor: not-allowed;
    }
    .user-icon {
      font-size: $fontSize14;
    }
    .user-email {
      color: $ey-phoenix-light-gray;
      width: 90%;
    }
  }

  .app-table {
    .ag-center-cols-clipper {
      z-index: 3;
      .ag-center-cols-viewport {
        height: 100% !important;
      }
    }

    .ag-header-cell {
      padding: 0px 10px !important;
    }
    .disabled {
      svg {
        fill: $grid_drop_down_color;
      }
      .custom-select-menu {
        display: none;
      }
    }
    .ag-pinned-right-header {
      width: 110px !important;
      min-width: 110px !important;
      max-width: 110px !important;
      border: 0;
      border-right: 1px solid $light-border;
    }

    .list-user-remove-container {
      cursor: pointer;
      width: 100%;
      display: flex;
      padding-left: 10px;
      justify-content: flex-start;
      align-items: center;
      .app-icon {
        margin-right: 5px;
      }
      &.disabled {
        opacity: 0.4;
        cursor: not-allowed;
        font-style: italic;
        pointer-events: none;
      }
    }
    .ag-cell {
      padding: 0 !important;
    }
    .ag-pinned-left-cols-container{
      .ag-row{
        .ag-cell:first-child{
          border-left: 0 !important;
        }
      }
    }
    .ag-cell-value {
      width: 100%;
      .app-table-cell-renderer {
        width: 100%;
      }
    }
    .ag-selection-checkbox {
      margin-right: 0;
      width: 100%;
      display: flex;
      justify-content: center;
      padding-right: 3px;
    }
    .ag-checkbox-input-wrapper {
      font-size: $fontSize22;
      width: 20px;
      height: 20px;
      box-shadow: none;
    }
    .ag-checkbox-input-wrapper::after {
      content: "";
      color: $disabled_motif_background;
      background: url("../../../assets/images/icons/checkbox.svg") no-repeat
        center;
      width: 20px;
      height: 20px;
    }
    .ag-checkbox-input-wrapper.ag-checked::after {
      content: "";
      color: $disabled_text_color;
      background: url("../../../assets/images/icons/checkbox-checked.svg")
        no-repeat center;
      width: 20px;
      height: 20px;
    }
    .ag-indeterminate::after {
      background: url("../../../assets/images/icons/checkbox_intermediate.svg")
        no-repeat center;
    }
    .ag-checkbox-input-wrapper.ag-disabled::after {
      background: url("../../../assets/images/icons/checkbox_disabled.svg")
        no-repeat center;
    }
    .ag-row-selected {
      background-color: transparent;
      .ag-cell-value {
        opacity: 0.4;
        font-style: italic;
        pointer-events: none;
        .dropdown-selected-values {
          font-style: italic;
        }
      }
    }
  }
  .list-pagination{margin-bottom: 0;border-top: 1px solid $light-border;}
  .app-dropdown-options{
    li{
      span{
        font-family: $eyInterstateLight;
        color: $secondary_button_grey;
      }
    }
  }
}
