@import '../../styles/globalStyles.scss';
.create-new-project {
  padding: 24px 32px 0;

  .page-header {
    display: flex; 
    align-items: center;
    @include app-headings;

    .back-link {
      display: flex;
      align-items: center;
      font-size: $fontSize16;
      margin-right: 16px;
      cursor: pointer;
    }
  }
  .stepper-container {
    padding: 0 144px;
  }

  .modal-content {
    width: 500px;
    font-size: $fontSize14;
    font-family: $eyInterstateLight;
    font-weight: 300;
    .label {
      font-weight: bold;
    }
    .label-modal-border {
      border-bottom: 1px dashed $athens_gray_light;
      margin-bottom: 5px;
    }
    .label-margin-bottom {
      margin-bottom: 5px;
    }
  }
}
.change-or-confirm-project-category {
  .pc-modal-content {
    margin-bottom: 20px;
  }
  .pc-modal-content-confirm {
    margin-bottom: 8px;
  }
  #app-dialog-title {
    h5 {
      font-size: 1.2rem;
    }
  }
}
