@import '../../styles/variables.scss';

.more-menu {
    display: flex;
    position: relative;

    .MuiIconButton-root {
        padding: 0;
    }

    .customised-more-meu {
        display: flex;
        cursor: pointer;
    }
}

.MuiListItem-button {
    .app-icon {
        color: $tuna;
        margin-right: 5px;
        font-size: $fontSize14;
    }
}

.app-more-menu-paper {
    .MuiMenu-paper {
        overflow: visible !important;
        font-size: $fontSize11;
        border: 1px solid $ey-border-grey;
        border-radius: 0;
        font-weight: normal;
        min-width: 120px;
        -webkit-box-shadow: 0px 3px 1px 1px rgba(0, 0, 0, 0.13);
        -moz-box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.13);
        box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.13);

        .MuiMenu-list {
            padding: 0;
        }
    }

    &.left {
        .MuiMenu-paper {
            margin-left: -3px;

            &::after {
                left: 7px;
            }
        }
    }

    &.right {
        .MuiMenu-paper {
            &::after {
                right: 3px;
            }
        }
    }

    .MuiMenuItem-root {
        font-size: $fontSize13;
        padding: 8px 10px;
        color: $tuna;
        font-family: $eyInterstateLight;
        z-index: 1;
    }

    .MuiButtonBase-root.Mui-disabled {
        pointer-events: all;
        cursor: not-allowed;
    }

    .icon-right {
        margin-left: 5px !important;
    }
}


.app-more-sub-menu-paper {
    .MuiMenu-paper {
        overflow: visible !important;
        font-size: $fontSize12;
        border: 1px solid $tuna;
        border-radius: 0;
        font-weight: normal;
        min-width: 120px;
        left: 235px !important;

        &::after {
            position: absolute;
            content: "";
            display: inline-block;
            width: 15px;
            height: 15px;
            transform: rotate(225deg);
            border-top: 1px solid $tuna;
            border-right: 1px solid $tuna;
            background-color: $white;
            clip: rect(-16px, 16px, 13px, 3px);
            top: 5px;
            left: -7px
        }
    }

    &.left {
        .MuiMenu-paper {
            margin-left: -3px;

            &::after {
                left: 7px;
            }
        }
    }

    &.right {
        .MuiMenu-paper {
            &::after {
                right: 3px;
            }
        }
    }

    .MuiMenuItem-root {
        font-size: $fontSize13;
        padding: 0 8px;
    }

    .MuiButtonBase-root.Mui-disabled {
        pointer-events: all;
        cursor: not-allowed;
    }

    .icon-right {
        margin-left: 5px !important;
    }

    .color-green {
        color: $special-green;
    }

    span.selected-item {
        width: 20px;
        display: inline-block;

    }
}