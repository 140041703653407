@import "../../styles/variables.scss";
.appAlert {
  height: 40px;
  display: flex;
  align-items: center;
  font-family: $eyInterstateRegularNew !important;
  &.MuiAlert-colorError {
    background-color: $light-red-0001 !important;
    border: 1px solid $ey-error-red !important;
    color: $ey-alert-red;
    margin-bottom: 12px;
    .MuiAlert-icon {
      color: $ey-error-icon-red;
    }
  }
  &.sp-info-alert {
    background-color: $ey-dull-background !important;
    border: 1px solid $ey-phoenix-light-gray-2 !important;
    color: $breadcrumb-color-active;
    display: flex;
    font-family: $eyInterstateRegularNew;
    font-weight: 400;
    font-size: $fontSize14;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    height: 4rem;
    margin-bottom: 2rem;
    .MuiAlert-icon {
      color: $ey-phoenix-light-gray;
      margin-top: -1rem;
    }
    .MuiAlert-action {
      padding: 4px 0 20px 16px;
    }
  }

  .MuiAlert-message {
    font-size: $fontSize14;
    font-weight: 400;
  }
  .MuiAlert-standardError .MuiAlert-icon {
    color: $ey-alert-circle-icon;
    height: 20px;
    width: 20px;
  }
  .MuiAlert-action {
    button {
      color: $ey-phoenix-light-gray;
    }
  }
}
