@import '../../styles/globalStyles.scss';

.app-toggle-switch {
    .MuiSwitch-colorSecondary {
        color: $mid_gray;

        &.Mui-checked {
            color: $bottle-green;

            &+ .MuiSwitch-track {
                background-color: $parrot;
            }
        }
    }
}

.new-app-toggle-switch {
    .MuiSwitch-root {
      width: 36px !important;
      height: 20px !important;
      padding: 0 !important;
    }
    .MuiSwitch-switchBase {
      padding: 0;
      margin: 2px;
      transition-duration: 300ms;
      &.Mui-checked {
        transform: translateX(16px);
        color: $white;
        & + .MuiSwitch-track {
          background-color: $toggle-bg-green;
          opacity: 1;
          border: 0;
        }
        &.Mui-disabled + .MuiSwitch-track {
          opacity: 0.5;
        }
      }
      &.Mui-focusVisible .MuiSwitch-thumb {
        color: $toggle-bg-green;
        border: 6px solid $white;
      }
      &.Mui-disabled .MuiSwitch-thumb {
        color: $white;
      }
      &.Mui-disabled + .MuiSwitch-track {
          opacity: 0.7;
      }
    }
    .MuiSwitch-thumb {
      box-sizing: border-box;
      width: 16px;
      height: 16px;
    }
    .MuiSwitch-track {
      border-radius: 10px;
      background-color: $ey-phoenix-light-gray-3;
      opacity: 1;
      transition: background-color 500ms;
    }
  }
  