@import '../../styles/globalStyles.scss';

.app-drawer {
  height: calc(100% - 50px) !important;
  bottom: 0;
  color: $tuna;
  background: $temo !important;
  width: 572px !important;
  margin-top: 50px;
  right: 0 !important;
  left: auto !important;

  .MuiDialog-container {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .MuiDialog-paper {
      background: $dropdown_form_control_bg;
      width: 100%;
      height: 100%;
      margin: 0;
      max-width: 100% !important;
      max-height: 100%;
      border-radius: 0;
    } 
  }

  .MuiDialogTitle-root {
    padding: 0 0 5px 0;
    margin: 20px 20px 0 20px;

    .MuiTypography-root {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      font-family: $eyInterstateLight;
      font-size: $fontSize18;
      font-weight: 300;

      .fullscreen-icon {
        margin: 5px 10px 0 0;
        cursor: pointer;
      }
    }
    .app-details {
      display: flex;

      .back-arrow {
        margin: 2px 20px 0 -6px;
        cursor: pointer;
      }
    }
  }
  .MuiDialogContent-root {
    padding-right: 0;
    overflow: hidden;
    padding: 0;
    padding-top: 20px;
    .tab-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      color: $tuna;
      font-family: $eyInterstateLight;
      font-size: $fontSize14;
      font-weight: 300;
      padding-right: 20px;
      padding-left: 20px;

      .all-tab {
        padding: 0 16px 8px;
        border-bottom: 4px solid $tuna;
      }
    }
    .search-wrapper {
      padding-right: 20px;
      padding-left: 20px;
      margin-bottom: 25px;

      .project-form-field {
        .app-icon {
          position: absolute;
          z-index: 1;
          top: 18px;
          left: 16px;
        }
        .app-input {
          .MuiInputBase-input {
            padding: 20px 6px 12px 54px;
            font-size: $fontSize16;
            background-color: $white;
          }
          .MuiInputBase-root {
            &:hover {
              &::before {
                border-bottom: 1px solid $jumbo;
              }
            }
          }
        }
      }
    }
    .app-list-wrapper {
      display: flex;
      flex-wrap: wrap;
      margin-right: 20px;
      padding-left: 20px;
      overflow-y: scroll;
      overflow-x: hidden;
      height: calc(100% - 120px);

      .no-result {
        font-size: $fontSize22;
        margin-top: 10px;
      }
      div:nth-of-type(even) {
        margin-right: 6px;
      }
      .fade-overlay {
        position: absolute;
        bottom: 0;
        width: 91%;
        height: 110px;
        pointer-events: none;
        background: linear-gradient(-180deg, rgba($athens_gray_dark, 0) 0%, $athens_gray_dark 100%);
      }
      .fade-overlay-cover {
        width: 91%;
        height: 80px;
      }

      &::-webkit-scrollbar {
        width: 6px;
        height: 7px;
      }
  
      &::-webkit-scrollbar-track {
        border-radius: 5px;
      }
  
      &::-webkit-scrollbar-thumb {
        background: $scarpa_flow;
        border-radius: 5px;
      }
    }
    
    .app-tile {
      display: flex;
      font-size: $fontSize22;
      width: calc(100% - 42px);
      padding: 25px 20px;
      justify-content: space-between;
      background: $white;
      box-shadow: 0 2px 10px 0 rgba($black, 0.05);
      max-width: 600px;
      margin-left: 20px;

      .icon-n-title {
        display: flex;
        align-items: center;

        .app-icon {
          min-width: 46px;
          min-height: 46px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          width: 46px;
          height: 46px;
        }
        .app-title {
          display: flex;
          flex-direction: column;
          margin: 15px 20px 0 15px;
          line-height: 1.3;

          .category-name {
            font-family: $eyInterstateLight;
            font-size: $fontSize12;
            font-weight: 300;
            margin-top: 8px;
          }
        }
      }
      .app-toggle {
        display: flex;
        align-items: center;
        color: $jumbo;
        font-size: $fontSize12;
        font-weight: 300;

        .project-form-field {
          margin: 0;
          height: 20px;
        }
      }
    }
    .details-title {
      font-family: $eyInterstateLight;
      font-size: $fontSize18;
      font-weight: 300;
      margin: 45px 20px 19px 20px;
    }
    .details-desc {
      font-family: $eyInterstateLight;
      font-size: $fontSize14;
      font-weight: 300;
      margin-right: 25px;
      height: 58%;
      overflow-y: scroll;
      padding-left: 20px;

      &::-webkit-scrollbar {
        width: 6px;
        height: 7px;
      }
  
      &::-webkit-scrollbar-track {
        border-radius: 5px;
      }
  
      &::-webkit-scrollbar-thumb {
        background: $scarpa_flow;
        border-radius: 5px;
      }
    }
  }
}

.app-drawer {
  &.fullscreen-drawer {
    width: 100% !important;
    max-width: 100% !important;

    .app-list-wrapper {
      display: grid;
      grid-template-columns: repeat(auto-fill, 244px);
      grid-gap: 1rem;
      justify-content: space-between;

      .fade-overlay, .fade-overlay-cover {
        display: none;
      }
    }
  }
}

.app-drawer-header-actions {
  display: flex;
  justify-content: center;
  align-items: center;
}