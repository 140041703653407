@import "../../styles/globalStyles.scss";

.confirm-create-new-project {
  .section-title {
    font-size: $fontSize18;
    padding: 10px 0;
    border-bottom: 1px solid $light-border;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .edit-btn-icon {
      margin-right: 5px;
    }
  }

  .selected-apps-details {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 10px;

    .app-tile {
      background: $white;
      border: 1px solid $light-border;
      padding: 12px 14px;

      .app-title {
        font-family: $eyInterstateRegular;
        color: $selected_color;
        font-size: $fontSize15;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .project-details {
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
    .no-padding-top {
      padding-top: 0;
    }
    .no-padding-bottom {
      padding-bottom: 0;
    }

    .project-details-item {
      display: flex;
      margin-bottom: 18px;

      .project-details-label {
        font-size: $fontSize14;
        color: $ey-phoenix-light-gray-3;
        font-family: $eyInterstateRegular;
        min-width: 190px;
      }
      .project-details-value {
        font-size: $fontSize14;
        color: $selected_color;
        font-family: $eyInterstateRegular;
      }

      .project-description {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        word-break: break-all;
      }

      &.last-item {
        margin-bottom: 0;
      }
    }
  }

  .project-MFA-details {
    padding-top: 15px;
    display: flex;
    align-items: center;

    .mfa-tool-tip {
      z-index: 1;
      display: flex;
      width: 16px;
      color: $ey-phoenix-light-gray;
      cursor: pointer;
    }
  }
}
