@import "../../styles/variables.scss";

.project-confirmation {
  .MuiDialogContent-dividers {
    border-top: transparent !important;
  }
  .pricing-information-link {
    text-decoration: none;
    color: black;
  }
  .errorEngagementSearchUser {
    margin-top: -6px !important;
  }
  .MuiDialogContent-dividers {
    border-bottom: transparent !important;
  }
  .MuiDialogContent-root {
    padding: 24px 0px 0px 0px !important;
  }
  .MuiDialogTitle-root {
    color: $secondary_button_grey !important;
    font-family: $eyInterstateRegularNew !important;
    font-weight: 400 !important;
    font-size: $fontSize20 !important;
    padding: 0px 0px 12px 0px !important;
  }
  .MuiAlert-colorError {
    height: 72px;
    font-family: $eyInterstateRegularNew;
    font-weight: 400;
    font-size: $fontSize14;
    .MuiAlert-icon {
      width: 30px;
      margin-bottom: 20px;
      margin-right: 10px;
      padding-left: 0px;
    }
  }
  .MuiDialog-paperFullWidth {
    max-width: 714px !important;
    padding: 24px;
  }
}
