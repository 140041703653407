@import '../../styles/variables.scss';

.app-number-picker{
  display: flex;
  align-content: center;
  justify-content: flex-start;
  flex-direction: column;
  width: 50px;
  .up-arrow, .down-arrow {
    width: 30px;
    border: none;
    background-color: $aqua;
    margin: 0 5px;
    cursor: pointer;
    .app-icon {
      font-size: $fontSize12;
      margin: 5px 0;
    }
  }

  .value-input {
    transition: all .5s ease-in-out;
    width: 40px;
    padding: 10px;
  }
}

