@import "../../styles/variables.scss";
@import "../../styles/globalStyles.scss";

.project-details {
  .app-button.manage-user-btn-disabled {
    border-color: $unpublished_bg_color !important;
    color: $placeholder_color;
    background-color: $disabled_background;
    cursor: not-allowed;
  }

  .head-section {
    padding: 24px 24px 0px 24px;
    .back-link {
      display: flex;
      align-items: center;
      font-size: $fontSize14;
      margin-bottom: 12px;
      .icon-link {
        font-size: $fontSize14;
        color: $placeholder_color;
      }
    }
    .ml-5 {
      margin-left: 5px;
      font-size: 12px;
    }
    .details-row {
      padding: 10px 10px 10px 0px;
      display: none;
    }
    .border-right {
      border-right: 0.9px solid $manate;
    }
    .content {
      padding-left: 0px;
      .project-name {
        font-size: $fontSize24 !important;
      }
      .page-title {
        font-family: $eyInterstateLight;
        font-size: $fontSize22;
      }
      .project-name {
        margin-top: 20px;
        margin-bottom: 35px;
        font-family: $eyInterstateLight;
        font-size: $fontSize32;
      }
      .project-meta {
        display: flex;
        flex-direction: row;

        .meta-item {
          display: flex;
          .item-title {
            width: 150px;
            font-family: $eyInterstateBold;
            font-size: $fontSize14;
            font-weight: bold;
          }
          .item-value {
            font-size: $fontSize14;
            font-family: $eyInterstateLight;
            font-weight: 300;
          }
        }
        .project-details-card {
          padding: 0 10px 5px 0;
          .project-card-body {
            background: $transparent_white;
            display: flex;
            flex-direction: column;
            box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.05);
            max-height: 6rem;
            height: 6rem;
            width: 13rem;
            padding: 8px;
            position: relative;

            .details-content {
              display: flex;
              flex-direction: row;
              position: relative;
              height: 30px;
              .tooltip-spacing {
                height: 80% !important;
                margin: 25px 0px 0px 25px !important;
                width: 80%;
              }
              .item-content {
                margin-top: 12px;
                font-size: $fontSize20;
              }

              .project-detail-action-link {
                color: $light_blue;
                text-decoration: none;
                font-family: $eyInterstateBold;
                font-size: $fontSize14;
                position: absolute;
                right: -5px;
                bottom: -25px;
                background: none;
                border: none;
                cursor: pointer;
                .icon-link {
                  font-size: $fontSize14;
                }
                &:disabled {
                  cursor: not-allowed;
                  opacity: 0.5;

                  .icon-link {
                    cursor: not-allowed;
                  }
                }
              }
              .btn-disabled {
                padding: 5px;
                background: $gray;
                text-decoration: none;
                font-family: $eyInterstateBold;
                font-size: $fontSize14;
                position: absolute;
                right: -5px;
                bottom: -25px;
                background: none;
                border: none;
                cursor: not-allowed;
                opacity: 0.5;
                .icon-link {
                  font-size: $fontSize14;
                  cursor: not-allowed;
                }
              }
            }
            .project-item-title {
              font-family: $eyInterstateBold;
              font-size: $fontSize14;
              color: $gray;
            }
          }
        }
      }
      .egagement-tags {
        font-family: $eyInterstateLight;
        margin-top: 20px;
        font-size: $fontSize16;
        word-wrap: break-word;
      }
      .btn-align {
        .project_settings {
          margin: 30px 0px;
          a {
            width: 100%;
          }
          .app-button {
            width: 12rem;
            height: 2.5rem;
            font-size: 1rem;
          }

          .MuiButtonBase-root {
            padding: 13px 5px;
          }
        }
      }
    }
    .app-section {
      margin: 10px 0px 0px;
      .app-sub-title {
        font-family: $eyInterstateRegularNew;
        font-size: $fontSize18;
        font-weight: 400;
        line-height: 28px;
        margin: 0px 0px 20px;
      }
      .project-home-divider-line {
        border-bottom: 1px solid $light-border;
      }
    }
    .bottom-banner {
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 0px;
    }
  }
  .app-tiles {
    margin: 24px 0px 10px 0px;
    min-height: 150px;
    grid-gap: 18px;
    display: grid;
    justify-content: center;
    padding: 10px 0px 20px;
    grid-template-columns: repeat(auto-fill, 345px);
    @media only screen and (min-width: 1686px) and (max-width: 1865px)  {
        grid-template-columns: repeat(auto-fill, 326px);
    }
    @media only screen and (min-width: 1456px) and (max-width: 1481px)  {
       grid-template-columns: repeat(auto-fill, 326px);
    }
    @media only screen and (min-width: 1362px) and (max-width: 1455px)  {
       grid-template-columns: repeat(auto-fill, 311px);
    }
    @media only screen and (max-width: 1361px)  {
       grid-template-columns: repeat(auto-fill, 282px);
    }
    .no-apps-found{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      justify-content: center;
      font-family: $eyInterstateRegularNew;
      font-size: $fontSize18;
      font-weight: 400;
    }
  }

  .app-notification .notification-wrapper {
    max-width: 750px;
    width: auto;
  }

  .skeleton-loader {
    display: block;
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5) 50%,
        rgba(138, 74, 74, 0) 80%
      ),
      lightgray;
    background-repeat: repeat-y;
    background-size: 50px 500px;
    background-position: 0 0;
    animation: shine 1s infinite;
  }

  @keyframes shine {
    to {
      background-position: 100% 0;
    }
  }
  .announcement-wrap{
    position: relative;
    .count-wrap{
      position: absolute;
      top: -12px;
      right: 5px;
      background: $motif-error-red;
      color: $white;
      border-radius: 50%;
      font-size: $fontSize8;
      min-width: 20px;
      min-height: 20px;
      line-height: 1;
      border: 1px solid $white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.workplan-sync-modal {
  .MuiPaper-root {
    min-width: 60%;
    max-width: 840px;
  }
  textarea {
    width: 100%;
    height: 150px;
    resize: none;
  }

  .rule-error {
    color: $red;
    padding: 5px 0;
  }
  label {
    font-size: $fontSize13;
    font-weight: bold;
  }
  .workplan-sync-info-msg {
    font-size: $fontSize12;
    font-weight: bold;
  }
  .workplan-active {
    display: flex;
    flex-direction: row;
    line-height: 1.5;
    align-items: center;
    padding: 10px;
    background-color: $white;

    .workplan-dropdown {
      width: 400px;
      margin-right: 15px;

      .app-select .MuiInputBase-input {
        padding: 8px;
        background-color: $white;
        border: 1px solid $black;
      }
      .MuiFilledInput-underline:after {
        border: none;
        transition: none;
      }
    }
  }
  .workplan-sync-rules {
    padding: 10px;
    background-color: $white;
    margin-top: 5px;
  }

  .MuiDialogActions-root {
    padding: 15px 25px !important;
  }
}

.check-in-modal {
  .MuiDialog-paperWidthMd {
    max-width: 714px;
  }
  .check-in-note {
    margin-bottom: 24px;
    .MuiAlert-icon {
      color: $ey-phoenix-light-gray;
      font-size: $fontSize32;
    }
    .MuiAlert-root {
      padding: 12px;
      border-radius: 8px;
      height: 72px;
      background-color: $ey-dull-background;
      border: 1px solid $ey-phoenix-light-gray-2;
    }
    .note {
      font-family: $eyInterstateRegularNew;
      font-size: $fontSize14;
      font-weight: 400;
      line-height: 20px;
      color: $secondary_button_grey;
    }
  }
  
  .content {
    font-family: $eyInterstateLight;
    font-size: $fontSize14;
    font-weight: 300;
    line-height: 24px;
    color: $secondary_button_grey;
    p,
    a,
    span,
    div {
      font-family: $eyInterstateLight;
    }
    strong{
      font-family: $eyInterstateRegularNew;
    }
  }
}

.publish-un-publish-contain {
  font-family: $eyInterstateLightNew;
  font-size: $fontSize16;
  font-weight: 300;
  line-height: 24px;
  color: $secondary_button_grey;
}

.change-or-confirm-project-category {
  .modal-content {
    font-family: $eyInterstateLightNew;
    font-size: $fontSize16;
    font-weight: 300;
    line-height: 24px;
    color: $secondary_button_grey;
  }
}

.project-governance-modal {
  .content{
    p{
      margin-top: 0;
    }
  }
  .rbt-input {
    width: 100%;
    height: 40px;
    font-weight: 300 !important;
    font-family: $eyInterstateLightNew !important;
    font-size: $fontSize14 !important;
    color: $ey-phoenix-light-gray !important;

    &:hover {
      background-color: $disabled_text_color;
      cursor: pointer;
    }
  }
  .rbt-aux{
    height: 44px;
  }
  .question{
    margin-top: 10px;
    width: 100%;
    float: left;
  }
  .search-wrapper{
    width: 100%;
    & > div{
      width: 100%;
    }
  }
}
