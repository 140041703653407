@import '../../../styles/globalStyles.scss';

.admin-schedules {
	.add-btn {
		margin-bottom: 10px;
		.add-btn-icon {
			background-color: $turbo;
			padding: 9px;
			.add-icon {
				font-size: $fontSize15;
			}
		}
		.label-icon {
			font-weight: bold;
			margin-left: 10px;
			cursor: pointer;
			text-transform: uppercase;
			color: $grey;
			font-size: $fontSize14;
		}
		.label-disable {
			cursor: not-allowed;
			opacity: .6;
		}
	}
	.admin-schedules-table {
		.ag-theme-material .ag-header-cell-label .ag-header-cell-text {
			text-overflow: clip;
			overflow: visible;
			white-space: normal;
			height: auto;
		}
		.ag-cell-wrapper {
			.app-table-cell-renderer {
				width: 100%;
				height: 100%;
				overflow: auto;
				max-height: 55px;
			}
		}
		.ag-theme-material .ag-ltr .ag-cell:not(.ag-cell-last-left-pinned) {
			overflow: hidden !important;
		}
		.ag-body-horizontal-scroll .ag-horizontal-left-spacer, .ag-body-horizontal-scroll .ag-horizontal-right-spacer {
			display: none;
		}
		.ag-body-horizontal-scroll-viewport {
			padding-right: 40px;
		}
		
		&.sticky-scroll {
			position: relative;
			.ag-body-horizontal-scroll {
				position: fixed;
				bottom: 0;
				padding: 0 50px;
				left: 0;
				z-index: 999;
				width: 100%;
			}
		}
		.list.sticky-scroll .ag-body-horizontal-scroll {
			padding-left: 285px;
		}
	}
	.async-dropdown {
		background: $white;
		padding: 5px 8px;
		width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.deleteModal {
		.red-highlighted {
			text-transform: uppercase;
		}	
		.app-modal-delete-body {
			font-weight: normal;
		}
	}
	.search-wrapper {
		align-items: center;
		.form-control{
			&:hover {
			  background: $disabled_text_color;
			}
			&:focus {
			  background: $white;
			}
		}
		.rbt-input-hint{
			display: none;
		}
	}

	@include search-wrapper();
}
