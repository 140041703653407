@import '../../styles/globalStyles.scss';

.app-input {

  &.readonly {
    .MuiInputBase-root::before {
      border: 0 !important;
    }
  }
  
  .MuiFormLabel-root, .MuiInputLabel-shrink, .Mui-focused {
    font-family: $eyInterstateLight;
    color: $tuna !important;
    font-size: $fontSize16;
  }
  .MuiInputLabel-shrink {
    transform: translate(12px, 6px) scale(0.82) !important;
  }
  .MuiInputBase-input {
    font-family: $eyInterstateLight;
    background-color: $temo;
    color: $tuna !important;
    font-size: $fontSize15;

    &:hover, &:focus {
      background-color: $mischka;
      border-bottom: 2px;
    }
  }
  .MuiInputBase-root .Mui-disabled {
    cursor: not-allowed;
    color: #747480 !important;

    &:hover, &:focus {
      background-color: $temo;
    }
  }
  .MuiFormLabel-root.Mui-disabled {
    color: #747480 !important;
  }
  .MuiInputBase-root {
    &:hover, &:focus {
      &::before {
        border-bottom: 2px solid $jumbo;
      }
    }
  }
  .MuiFilledInput-underline {
    &::before {
      border-bottom: 1px solid $jumbo;
    }
    &::after {
      border-bottom: none;
    }
  }
  .MuiFilledInput-underline.Mui-disabled {
    &::before {
      border-bottom-style: solid;
    }
  }
  .MuiInputBase-root.Mui-disabled {

    &:hover, &:focus {
      &::before {
        border-bottom: 1px solid $jumbo;
      }
    }
  }
}
.in-valid {
  .MuiInputBase-root {
    &:hover, &:focus {
      &::before {
        border-bottom: 2px solid $thunderbird;
      }
    }
  }
  .MuiFilledInput-underline {
    &::before {
      border-bottom: 1px solid $thunderbird;
    }
  }
}