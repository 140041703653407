@import '../../styles/globalStyles.scss';


.app-notification {
	.notification-wrapper {
		z-index: 1004;
		position: fixed;
		background-color: $white;
		margin-bottom: 30px;
		padding: 14px;
		right: 10px;
		left: auto;
		top: 60px;
		box-shadow: 0 0 1.14286rem 0 rgba($black, .2);
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		border: 1px solid;
		border-radius: 8px;

		.MuiIconButton-root {
			color: $black;
		}

		.notification-text {
			display: flex;
			font-size: $fontSize14;
			min-width: 260px;
		}

		.notification-icon {
			display: flex;
			justify-content: center;
			margin-right: 14px;
		}

		.toast-content-container {
			margin-top: -5px;
		}

		.toast-close-button {
			padding: 5px;
			margin-right: -3px;
			margin-top: -7px;

			.MuiSvgIcon-root {
				font-size: $fontSize16;
			}
		}

		.toast-header {
			font-family: $eyInterstateRegular;
			font-size: $fontSize14;
		}

		.toast-content {
			font-family: $eyInterstateLight;
			font-size: $fontSize14;
		}

		&.success {
			background-color: $toast-success-bg;
			border-color: $ey-green-check;

			.MuiSvgIcon-root {
				color: $ey-green-check;
			}

			.notification-icon svg {
				color: $ey-green-check;
				font-size: 18px;
			}

			.toast-header {
				color: $toast-success-text;
			}

			.toast-content {
				color: $toast-success-text;
			}
		}

		&.error {
			background-color: $light-red-0001;
			border-color: $ey-alert-red;

			.MuiSvgIcon-root {
				color: $ey-alert-red;
			}

			.notification-icon svg {
				color: $ey-alert-red;
				font-size: 16px;
			}

			.toast-header {
				color: $ey-alert-red;
			}

			.toast-content {
				color: $ey-alert-red;
			}
		}

		&.info {
			background-color: $toast-info-bg;
			border-color: $ey-link-blue;
			color: $ey-link-blue;

			.MuiSvgIcon-root {
				color: $ey-link-blue;
			}

			.notification-icon svg {
				color: $ey-link-blue;
				font-size: 16px;
			}

			.toast-header {
				color: $ey-link-blue;
			}

			.toast-content {
				color: $ey-link-blue;
			}
		}

		&.generic {
			background-color: $ey-dull-background;
			border-color: $ey-phoenix-light-gray-2;
			color: $secondary_button_grey;

			.MuiSvgIcon-root {
				color: $secondary_button_grey;
			}

			.notification-icon svg {
				color: $secondary_button_grey;
				font-size: 16px;
			}

			.toast-header {
				color: $secondary_button_grey;
			}

			.toast-content {
				color: $secondary_button_grey;
			}
		}
		&.message {
			background-color: $ey-dull-background !important;
			border-color:  $ey-phoenix-light-gray-2 !important;
			color: $breadcrumb-color-active !important;
			z-index: 1006 !important;
			.MuiSvgIcon-root {
				color: $ey-phoenix-light-gray;
				background-color: $ey-dull-background !important;
			}
			.notification-icon svg {
				color: $ey-phoenix-light-gray;
				font-size: 16px;
				background-color: $ey-dull-background !important;
			}

			.toast-header {
				color: $breadcrumb-color-active;
			}

			.toast-content {
				color: $breadcrumb-color-active;
				background-color: $ey-dull-background !important;
			}
		}
	}
}